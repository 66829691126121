import { Component, ElementRef, isDevMode, OnInit, Input, Output, ViewChild, forwardRef  } from '@angular/core';
import { FormGroup, FormControl, Validators, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { DatePipe, Location } from "@angular/common";
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/shared/service/utils.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IAttachment } from './attachement.model';
import { WizardComponent } from 'angular-archwizard';
// import * as moment from 'moment';
import { filter } from 'rxjs/operators';
import * as jsPDF from 'jspdf';
import { environment } from 'src/environments/environment';
import { from, interval } from 'rxjs';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { animate, style, transition, trigger } from '@angular/animations';
import { AccountService } from 'src/app/shared/service/account.service';
import * as moment from 'moment';
import {GenererPdfService} from "../../../shared/service/generer-pdf.service";
import { IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';


@Component({
  selector: 'app-home-vie',
  templateUrl: './home-vie.component.html',
  styleUrls: ['./home-vie.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2500)
      ]),
      transition('* => void', [
        animate(2500),
        style({ opacity: 0 }),
      ])
    ]),
    trigger("slideInOut", [
      transition('void => *', [
        style({ transform: "translateX(60%)", opacity: 0 }),
        animate(
          "1500ms ease-in",
          // style({ transform: "translateX(0%)", opacity: 1 })
        ),
      ]),
      transition('* => void', [
        animate(
          "1500ms ease-out",
          // style({ transform: "translateX(60%)", opacity: 0 })
        ),
      ]),
    ]),
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViesComponent),
      multi: true
    }
  ]
})
export class ViesComponent implements OnInit  {
  
  
  rest;
  formules = [];
  isSouscription: boolean = false;
  @Input() attachments: IAttachment[] = [];
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  invalid: boolean = false;
  step1: boolean = false;
  step2: boolean = false;
  step3: boolean = false;
  step4: boolean = false;
  durees = [];
  paymentMethod: string = "Paiement par agence";
  valeurVenal: number;
  selectsg: any;
  valeurNeuf: number;
  paymentMethods = [];
  tmpPaymentMethods = [];
  listAgences = [];
  desc: any[];
  sendingRequest: boolean = false;
  updateRequest: boolean = false;

  individuel: boolean = false;
  flotte: boolean = false;
  apiResponse: any = { title: '', text: '', type: '' };
  phoneMask: string = "00 00 000 000";
  cinMask: string = "0 000 0000 00000";
  dateTime = Math.floor(Date.now() / 1000);
  @Input() valeur: number;
  today: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate()
  };
  simulationVehiculeFlotteForm = new FormGroup({
    formulaire: new FormControl("", [Validators.required]),
  });
  personcontactForm = new FormGroup({
    first_name: new FormControl("", [Validators.required]),
    designation: new FormControl("automobile", [Validators.required]),
    last_name: new FormControl("", [Validators.required]),
    telephone: new FormControl("", [Validators.required]),
    jour: new FormControl("", [Validators.required]),
    heure: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.pattern(this.emailPattern)]),
  })
  simulationVehiculeForm = new FormGroup({
    livraison: new FormControl('domicile', [Validators.required]),
    moyenDePaiement: new FormControl("paiement_agence", [Validators.required]),
    assureur: new FormControl("", [Validators.required]),
    agence: new FormControl(""),
    isSubscriber:new FormControl(true),
    canal: new FormControl(1, [Validators.required]),
    pack: new FormControl("auto"),
    immatriculation: new FormControl("", [Validators.required]),
    marque: new FormControl("", [Validators.required]),
    modele: new FormControl("", [Validators.required]),
    categorieVehicule: new FormControl("", [Validators.required]),
    typeVehicule: new FormControl("", [Validators.required]),
    valeurNeuf: new FormControl("", [Validators.required]),
    valeurVenal: new FormControl("", [Validators.required]),
    duree: new FormControl("", [Validators.required]),
    typeMoteur: new FormControl("", [Validators.required]),
    poids: new FormControl("", [Validators.required]),
    nombrePlace: new FormControl(5, [Validators.required]),
    first_name: new FormControl("", [Validators.required]),
    last_name: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required]),
    date_mis_en_circulation: new FormControl("", [Validators.required]),
    date_effet: new FormControl(new Date(), [Validators.required]),
    email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
    address: new FormControl("", [Validators.required]),
    // carte_grise: new FormControl(null ,[Validators.required]),
    // profession: new FormControl(null ,[Validators.required]),
    puissance: new FormControl("", [Validators.required]),
    assure: new FormGroup({
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      dateNaissance: new FormControl(""),
      profession: new FormControl(""),
      numeroTelephone: new FormControl("", [Validators.required]),
      numeroCni: new FormControl(""),
      secteuractivite : new FormControl("", [Validators.required]),
      adresse: new FormControl("", [Validators.required]),
      // lateralite: new FormControl("", [Validators.required]),
      genre: new FormControl("", [Validators.required]),
      choix: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.pattern(this.emailPattern)])
    }),
    souscripteur: new FormGroup({
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      genre: new FormControl(""),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      dateNaissance: new FormControl(""),
      telephone: new FormControl("", [Validators.required]),
      numeroCni: new FormControl("")
    }),
    pointRelaisControl: new FormControl("", [Validators.required]),
    adresseLivraison: new FormControl("", [Validators.required]),
  });
  loginform1 = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });
  listJours=[{name:'Tous les jours'},{name:'Lundi'},{name:'Mardi'},{name:'Mercredi'},{name:'Jeudi'},{name:'Vendredi'}];
  listHeures=[{name:"08h-10h"},{name:"10h-12h"},{name:"12h-14h"},{name:"14h-16h"},{name:"16h-18h"}];
  typeVehicule = "";
  currentIdCat: any;
  tabElementVehicule = [{ name: "Poids", value: "poids", type: "text" }, { name: "Type de carburant", value: "typeMoteur", type: "text" }, { name: "Puissance", value: "puissance", type: "text" }, { name: "Nombre de places", value: "nombrePlace", type: "text" }, { name: "Immatriculation", value: "immatriculation", type: "text" }, { name: "Marque", value: "marque", type: "text" }, { name: "Modele", value: "modele", type: "text" }, { name: "Date de mise en circulation", value: "date_mis_en_circulation", type: "date" }, { name: "Date d'effet du contrat", value: "date_effet", type: "date" }, { name: "Durée du contrat", value: "duree", type: "text" }];
  tabElementAssure = [{ name: "Prénom", value: "prenom", type: "text" }, { name: "Nom", value: "nom", type: "text" }, { name: "Date de naissance", value: "dateNaissance", type: "date" }, { name: "Numéro téléphone", value: "numeroTelephone", type: "text" }, { name: "Numéro CNI", value: "numeroCni", type: "text" }, { name: "Email", value: "email", type: "text" }, { name: "Adresse", value: "adresse", type: "text" }];
  dateRappel = [{ name: "Lundi", value: "lundi"},{ name: "Mardi", value: "mardi"}, { name: "Mercredi", value: "mercredi"}, { name: "Jeudi", value: "jeudi"}, { name: "Vendredi", value: "vendredi"}, { name: "Samedi", value: "samedi"}, { name: "Dimanche", value: "dimanche"}];
  heureRappel = [{ name: "08h:00", id: "08:00"}, { name: "09h:00", id: "09:00"}, { name: "10h:00", id: "10:00"}, { name: "11h:00", id: "11:00"}, { name: "12h:00", id: "12:00"}, { name: "13h:00", id: "13:00"}, { name: "14h:00", id: "14:00"}, { name: "15h:00", id: "15:00"}, { name: "16h:00", id: "16:00"}, { name: "17h:00", id: "17:00"}, { name: "18h:00", id: "18:00"}, { name: "19h:00", id: "19:00"}, { name: "20h:00", id: "20:00"}, { name: "21h:00", id: "21:00"}, { name: "22h:00", id: "22:00"}, { name: "23h:00", id: "23:00"}];
  listDurees: any = [];
  listDureesFiltrer: any = [];
  currentAssureur: any = "";
  currentDom = "activeLivraison";
  currentRel = "";
  vielle: boolean = false;
  plafonne: boolean = false;
  age: number
  tierce: boolean = true;
  listDesGaranties = [];
  sendingRequestAssureur: boolean = false;
  subVehiculeCategories = [];
  products: any;
  allFormula: any = [];
  devisType: string;
  login: boolean = false;
  vehiculeCategories = [];
  fueltype = [];
  contractDuration = [3, 6, 12];
  garanties: any = [];
  currentGaranties: any = [];
  sous_garanties: any = [];
  activeGarantie: number = 0;
  selectedG = [];
  selectedGid = [];
  linkedCode = ['gtcom', 'gtcol', 'gdr'];
  resultSimulation = [];
  simulation = [];
  initPaymentForm = {
    requestId: 0,
    numeroSouscription: '',
    status: '',
    montant: '',
  }
  loginform = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });
  inscriptionForm = new FormGroup({
    tel: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.pattern(this.emailPattern)])
  });
  forgotForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),

  });
  etrerappeleForm = new FormGroup({
    date: new FormControl("", [Validators.required]),
    heure: new FormControl([], [Validators.required]),
    code: new FormControl("", [Validators.required])
  });
  paymentForm = {
    timestamp: this.dateTime,
    returnUrl: 'https://web-ma.suntelecoms.com/api/contrats/save-contrat',
    auth: '',
    amount: '',
    requestId: this.dateTime,
    // terminalNumber: 160820000101,
    terminalNumber: 423820000101,
    transactionCurrencyCode: 'XOF',
    customerLastName: '',
    customerFirstName: '',
    customerAddress: '',
    customerCountry: '',
    customerEmail: '',
    customerPhone: '',
    customerState: '',
    customerCity: '',
    customerZipCode: '',
    ville: '',
    montantSaisie: '',
  }
  tabGarParticuliers = ['gtcol', 'gtcom', 'gtp', 'gi', 'gv', 'gpt', 'bris', 'gar','gdf'];
  tabGarCommerces = ['gtcol', 'gtcom', 'gtp', 'gi', 'gv', 'gpt', 'bris', 'gar','gdf'];
  tabGarMarchandises = ['gtcom', 'gtp', 'gi', 'gv','gdf'];
  tabGarMoto = ['gar','gdf'];
  //stepWa: NavigationMode = new NavigationMode();#
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;
  @ViewChild('contentResultcontact', { static: false }) contentResultmail: any;
  @ViewChild('contentResult', { static: false }) contentResult: any;
  @ViewChild('contentResultass', { static: false }) contentResultass: any;
  @ViewChild('contentResult2', { static: false }) contentResultgrise: any;
  @ViewChild('contentResultlogin', { static: false }) modalog: any;
  @ViewChild('contentResultforgot', { static: false }) modaforgot: any;
  @ViewChild('contentResultsign', { static: false }) modasign: any;
  @ViewChild('contentSouscription', { static: false }) modalSouscription: any;
  @ViewChild('autoDownload') autoDownload: ElementRef;
  @ViewChild('autoDownload') autoDownload1: ElementRef;
  @ViewChild('updateAssures', { static: false }) updateAssures: any;
  @ViewChild('updateInfoVehicule', { static: false }) updateInfoVehicule: any;
  @ViewChild('updateGarantie', { static: false }) updateGarantie: any;
  @ViewChild('devisAEnvoye', { static: false }) devisAEnvoye: any;
  assureurAEnvoye;
  listMarques: any = [];
  // key = "676AC4CC226201685E89ADB0C9B4510D";
  key = "FC48ABF885F26C13B0BE46B54618BC";
  desc1: any;
  loadingFiles: number;
  listModeles: any = [];
  complete: boolean;
  collision: boolean;
  plafone: boolean;
  incendie: boolean;
  avance: boolean;
  vol: boolean;
  assureurs: any =[];
  listAssureurs : any = []
  moto: boolean;
  camion: boolean;
  vp: boolean;
  paiment: boolean = false;
  assureur1: any;
  loaderVisible: boolean;
  userInconnu: string;
  primerc: any;
  tot: any;
  _idAssurance: any;
  _typeAssurance: string;
  user: any;
  loadContact: boolean = false;
  tokenid: any;
  // tabToolipValue:any=[];
  currentSouscripteur = "Pour moi-même";
  @ViewChild('closeBTN') closeBTN: ElementRef;
  garantiesAll: any = [];
  date: any;
  garantieAss = [];
  primeAss = [];
  numeroSous="";
  refPro="";
  newDate=new Date();
  dateExpiration:any;
  currentCategorie="";
  selectedAss : any;
  datas: any;
  detailsPdf: any;
  message:any;
  uuId: any;
  pointRelais: any;
  pwd = 'password';
  currentId;
  assureurId;
  choixAssure;
  logoImage = localStorage.getItem('LogoImage');
  constructor(private subscriptionService: SubscriptionService,
    private location: Location, private datePipe: DatePipe,
    private route: ActivatedRoute,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    public router: Router, private accountService: AccountService, private genererPdfService: GenererPdfService) {
    this.date =this.today.day + '/' + this.today.month + '/' +  this.today.year;
    let data = (this.today.day + '/' + this.today.month + '/' + this.today.year);
    // this.simulationVehiculeForm.get('date_effet').setValue(new Date());
    this.datas = this.route.snapshot.params['data'];
    
    this.route.queryParams.subscribe(params => {
      this.datas = params.data;
      if(this.datas){
        this.genererPdfService.genererPdf(this.datas).subscribe((resp) => {
          this.detailsPdf = resp['data'];
          let id_ass = this.detailsPdf.assureur.id;
          let ass = this.detailsPdf.assureur;
          // this.getprix(id_ass, ass);
          this.processSimulationDevis()
        });
        // this.get_all_devis()
      }
    });
  }
    // Settings configuration
  mySettings: IMultiSelectSettings = {
    enableSearch: true,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-default btn-block',
    dynamicTitleMaxItems: 3,
    displayAllSelectedText: true
  };

// Text configuration
myTexts: IMultiSelectTexts = {
  checkAll: 'Selectionner Tout',
  uncheckAll: 'Décocher Tout',
  checked: 'Element Selectionné',
  checkedPlural: 'Element Selectionné',
  searchPlaceholder: 'Chercher',
  searchEmptyResult: 'Aucun elément trouvé...',
  searchNoRenderText: 'Type in search box to see results...',
  defaultTitle: 'Select',
  allSelected: 'Tout selectionné',
};

  ngOnInit(): void {
    this.devisType = this.route.snapshot.paramMap.get("type");
    this.getGaranties();
    this.user = JSON.parse(localStorage.getItem('microFinance'));
    let time = new Date().getTime();
    let pro = new Date().getTime() + 123890989;
    this.numeroSous="SN"+time+"SC";
    this.refPro="SN"+pro+"PR";
    this.getModelvoiture();
    this.getVehiculeType();
    this.getPaymentMethods();
    this.getAgences();
    this.getDurees();
    this.domicile();
    
    
  }


  get idAssurance() {
    return this._idAssurance;
  }


  @Input()
  set idAssurance(id) {
    this._idAssurance = id;
    // this.fireFunctions();
  }

  get typeAssurance() {
    return this._typeAssurance;
  }

  @Input() set typeAssurance(type: string) {
    this._typeAssurance = type;
  }
  //  get simulationVehicule(){
  //   return this.simulationVehiculeForm.controls;
  //  }
  //  validerchamp(){
  //    this.step1 != this.step1;
  //  }
  /*Simuler*/
  getDurees() {
    this.subscriptionService.getDurees().subscribe((resp) => {
      this.listDurees = resp['data'] || [];
      this.listDureesFiltrer = this.listDurees.filter( d =>
          d.duree <=12 && d.unite == 'mois'
      );
    });
  }
  getVehiculeType() {
    this.subscriptionService.getVehiculeCategories().subscribe((resp) => {
      this.vehiculeCategories = resp['data'] || [];
    });
  }
  getModelvoiture() {
    this.subscriptionService.getModel().subscribe((resp) => {
      this.listMarques = resp['data'];
    });
  }
  selectAssureur(){
    if(this.selectedAss.length>0){
     this.assureurs = this.listAssureurs.filter(el=>this.selectedAss.indexOf(el.id)!=-1);
    }
  }
  marque() {
    let dah = this.simulationVehiculeForm.get('marque').value;
    this.subscriptionService.getmodel(dah).subscribe((resp) => {
      this.listModeles = resp['data'];
    });
  }
  onBlurEvent(event: any) {
    if (this.simulationVehiculeForm.get('valeurNeuf').value && event.target.value) {
      this.valeurNeuf = Number(this.simulationVehiculeForm.get('valeurNeuf').value.replace('', ' '));
      this.valeurVenal = event.target.value.replaceAll(' ', '')
    }

  }
  individuelvoid() {
    this.individuel = true;
    this.flotte = false
  }
  flottevoid() {
    this.flotte = true;
    this.individuel = false;
  }
  retour() {
    this.flotte = false;
    this.login = true;
    this.individuel = false;
    window.scroll(500,500);
  }
  retour2() {
    this.flotte = false;
    this.login = false;
    this.individuel = false;
  }
  mail() {
    let user = this.user.infos;
    this.personcontactForm.get('last_name').setValue(user.lastName);
    this.personcontactForm.get('first_name').setValue(user.firstName);
    this.personcontactForm.get('email').setValue(user.email);
    this.personcontactForm.get('telephone').setValue(user.telephone);
    this.modalService.open(this.contentResultmail, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

        this.utilsService.showToastSuccess("Votre demande de rappel à été enregistré avec success. Vous allez recvoir un mail de confirmation", "Demande réussie")
      }
      // this.wizard.goToNextStep();
    }, (reason) => {

    });
  }
  saveContact() {
    if (this.personcontactForm.invalid) {
      this.utilsService.showToastError("Veuillez remplir tous les champs obligatoire", "Attention !!!");
    } else {
      this.loadContact = true;
      let value = this.personcontactForm.value;
      // @ts-ignore
      value['jour'] = value['jour'].join();
      // @ts-ignore
      value['heure'] = value['heure'].join();
      this.subscriptionService.saveContact(value).subscribe(resp => {
        if (resp['responseCode'] == 200) {
          this.closeBTN.nativeElement.click();
          this.loadContact = false;
          this.utilsService.showToastError("Votre demande a été enregistrer avec succès", "Demande réussi")
        } else {
          this.loadContact = false;
          this.utilsService.showToastError(resp['message'], "Erreur")
        }
      }, error => {
        this.loadContact = false;
        this.utilsService.showToastError(error.error['errors'][0]['message'], "Erreur")
      });
    }
  }
  addAttachment(e: any): void {
    this.loadingFiles = 0;
    if (e && e.target.files) {
      this.loadingFiles = e.target.files.length;
      for (let i = 0; i < this.loadingFiles; i++) {
        let file = e.target.files[i];
        let fileName = file.name;
        let attachment: IAttachment = {
          originalFilename: fileName,
          contentType: file.type,
          sizeInBytes: file.size,
          extension: this.getExtension(fileName),
          processing: true,
        };
        this.attachments.push(attachment);
        attachment.processing = false;
      }

    }
    // this.attachments.push(attachment.processing);
    e.target.value = '';
  }
  getExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.'));
  }
// Ouverture modal pour souscription
  Souscription() {
    // this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modalSouscription, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {

    });
  }


  inscrire() {
    this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modasign, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {

    });
  }

  setValeurVenale(){
    this.simulationVehiculeForm.get('valeurVenal').setValue(this.simulationVehiculeForm.get('valeurNeuf').value);
  }
  
  onBlurEventneuf(event: any) {
    if (this.simulationVehiculeForm.get('valeurVenal').value && event.target.value) {
      this.valeurVenal = Number(this.simulationVehiculeForm.get('valeurVenal').value.replace('', ' '));
      this.valeurNeuf = event.target.value.replaceAll(' ', '');
    }
  }

 


  getGaranties() {
    this.garanties = [];
    this.garantiesAll = [];
    this.subscriptionService.getTypeGaranties().subscribe((resp) => {
      if (resp['responseCode'] == 200) {
        resp['data'].forEach(element => {
          if (element.parent_id == null) {
            this.garanties.push(element);
            this.garantiesAll.push(element);
          }
        });
      }
    });
    this.getDescriptionGarantie();
  }

  selectVehiculeCategories(idV: any) {
    this.currentIdCat = idV;
    let car = this.vehiculeCategories.find((v) => v.id == idV);
    this.currentCategorie = car.libelle;
    if (idV == 2) {
      this.garanties = this.garantiesAll.filter(el => this.tabGarParticuliers.indexOf(el.code) != -1);
    }
    if (idV == 3) {
      this.garanties = this.garantiesAll.filter(el => this.tabGarMarchandises.indexOf(el.code) != -1);
    }
    if (idV == 1) {
      this.garanties = this.garantiesAll.filter(el => this.tabGarCommerces.indexOf(el.code) != -1);
    }
    if (idV == 4) {
      this.garanties = this.garantiesAll.filter(el => this.tabGarMoto.indexOf(el.code) != -1);
    }
    if (car.id == 1 || car.libelle == 3) {
      this.subVehiculeCategories = car;
      this.camion = true;
      this.moto = false;
      this.vp = false;
    }
    else if (car.id == 4) {
      this.subVehiculeCategories = car;
      this.moto = true;
      this.vp = false;
      this.camion = false;
    }
    else {
      this.camion = false;
      this.vp = true;
      this.moto = false;
    }

    this.simulationVehiculeForm.get("categorieVehicule").setValue(car.id);
    this.typeVehicule = car.libelle;
  }

  selectGarantie(g) {
    this.activeGarantie = g.id === this.activeGarantie ? 0 : g.id;
  }

  get categorieVehicule() {
    return this.simulationVehiculeForm.get("categorieVehicule").value;
  }
  assuree() {
    if (this.simulationVehiculeForm.get('assure').get('dateNaissance').invalid || this.simulationVehiculeForm.get('assure').get('numeroTelephone').invalid || this.simulationVehiculeForm.get('assure').get('prenom').invalid || this.simulationVehiculeForm.get('assure').get('nom').invalid || this.simulationVehiculeForm.get('assure').get('numeroCni').invalid || this.simulationVehiculeForm.get('assure').get('adresse').invalid || this.simulationVehiculeForm.get('assure').get('genre').invalid || this.simulationVehiculeForm.get('assure').get('email').invalid) {
      this.invalid = true
      //  this.wizard.goToNextStep();
    }
    else {
      // @ts-ignore
      this.simulationVehiculeForm.get('souscripteur').patchValue(this.simulationVehiculeForm.get('assure').value);
      this.simulationVehiculeForm.get('souscripteur').get('dateNaissance').setValue(this.simulationVehiculeForm.get('assure').get('dateNaissance').value);
      this.simulationVehiculeForm.get('souscripteur').get('telephone').setValue(this.simulationVehiculeForm.get('assure').get('numeroTelephone').value);

      // this.wizard.goToNextStep();
      this.processSimulation2(this.rest);
      window.scrollTo(500, 500);
      this.invalid = false;
    }
  }
  addGarantie(g: any, sg: any, sousGarantie?) {
    let line = {
      garanties_id: g.id,
      garanties_name: g.nom,
      garantie_categories_id: g.id,
      code: g.code
    }
    let gExist = this.selectedG.find((selec) => selec.garanties_id == g.id);


    // s'il existe des garanties

    if (gExist) {

      sg['selected'] = false;
      g['selected'] = false;


      // pour tierce collusion

      if (gExist.code == 'gtcol'){
        this.collision = false;
        for (let garantie of this.garanties) {
          if (garantie.code == "gi" || garantie.code == "gv"|| garantie.code == "gtcom" || garantie.code == "gtp" || garantie.code == "bris") {
            let newLine = {
              garanties_id: garantie.id,
              garanties_name: garantie.nom,
              garantie_categories_id: garantie.id,
              code: garantie.code
            }
            this.selectedG.splice(this.selectedG.indexOf(newLine));
            garantie['selected'] = false;
            garantie['disabled'] = false;
            this.incendie=false;
            this.vol=false;
            this.avance = false;
            this.complete=false;
            this.plafone =false;
          }
        }
              }

      if (gExist.code == 'gi')
        this.incendie = false;

      if (gExist.code == 'gv')
        this.vol = false;


        // pour tierce plafonné

      if (gExist.code == 'gtp'){
        this.plafone = false;
        for (let garantie of this.garanties) {
          if (garantie.code == "gi" || garantie.code == "gv"|| garantie.code == "gtcom" || garantie.code == "gtcol" || garantie.code == "bris") {
            let newLine = {
              garanties_id: garantie.id,
              garanties_name: garantie.nom,
              garantie_categories_id: garantie.id,
              code: garantie.code
            }
            this.selectedG.splice(this.selectedG.indexOf(newLine));
            garantie['selected'] = false;
            garantie['disabled'] = false;
            this.incendie=false;
            this.vol=false;
            this.avance = false;
            this.complete=false;
            this.collision =false;
          }
        }
      }



      // pour tierce compléte 

      if (gExist.code == 'gtcom') {   
        this.complete = false;
        for (let garantie of this.garanties) {
          if (garantie.code == "gi" || garantie.code == "gv" || garantie.code == "bris" || garantie.code == "gtcol" || garantie.code == "gtp") {
            let newLine = {
              garanties_id: garantie.id,
              garanties_name: garantie.nom,
              garantie_categories_id: garantie.id,
              code: garantie.code
            }
            this.selectedG.splice(this.selectedG.indexOf(newLine));
            garantie['selected'] = false;
            garantie['disabled'] = false;
            this.incendie=false;
            this.vol=false;
            this.avance = false;
            this.collision=false;
            this.plafone =false;
          }
          if(garantie.code =='gar'){
            garantie['disabled'] = false;
          }
        }
      }

      this.selectedG.splice(this.selectedG.indexOf(gExist));
      gExist = {};
    }

    let gExisttierceauto = this.selectedG.find((selec) => selec.code == "gtcol");
    let gExisttiercecomp = this.selectedG.find((selec) => selec.code == "gtcom");
    let gExisttiercepla = this.selectedG.find((selec) => selec.code == "gtp");
    if (!gExist && g.code != "gtp" && g.code != "gtcom" && g.code != "gtcol" && g.code != "gi" && g.code != "gv" && g.code != "gpt") {
      this.selectedG.push(line);
      sg['selected'] = true;
      g['selected'] = true;
    } 


    // pour garanties complétes

    else if (!gExisttierceauto && !gExisttiercepla && !gExist && g.code == "gtcom")
     {
        this.selectedG.push(line);
        this.complete = true;
        sg['selected'] = true;
        g['selected'] = true;
        for (let garantie of this.garanties) {
          if (garantie.code == "gi" || garantie.code == "gv" || garantie.code == "bris") 
          {
            let newLine = {
              garanties_id: garantie.id,
              garanties_name: garantie.nom,
              garantie_categories_id: garantie.id,
              code: garantie.code
            }
            this.selectedG.push(newLine);
            if(garantie.code == "gi" || garantie.code == "gv" || garantie.code =="bris"){
              garantie['selected'] = true;
            }
            this.incendie=true;
            this.vol=true;
           this.avance = true;
           this.collision=true;
            this.plafone =true;
          }
          if(garantie.code == "gtcol" || garantie.code == "gtp" || garantie.code == "gar"){
            garantie['disabled'] = true;
          }
          if(garantie.code=='gar'){
            garantie['selected'] = false;
            garantie['disabled'] = true;
          }
          if(garantie.code=='bris'){
            garantie['disabled'] = true;
          }
          
        }
        this.utilsService.showToastInfo(" Veuillez désélectionner ", "Pour sélectionner Garantie Tierce Collision ou Garantie Tierce plafonnée ");
        this.selectedG.push(line);
        let index =-1;
        for(let i=0;i< this.selectedG.length;i++){
          if(this.selectedG[i].code=='bris')
            index=i;
        }
        this.selectedG.splice(index,1);
      } 
      

      // pour garantie collusion

      else if (!gExisttiercecomp && !gExisttiercepla && !gExist && g.code == "gtcol")
       {

        this.selectedG.push(line);
        this.collision = true;
        sg['selected'] = true;
        g['selected'] = true;
        for (let garantie of this.garanties) {
          if (garantie.code == "gi" || garantie.code == "gv" || garantie.code == "bris") 
          {
            let newLine = {
              garanties_id: garantie.id,
              garanties_name: garantie.nom,
              garantie_categories_id: garantie.id,
              code: garantie.code
            }
            this.selectedG.push(newLine);
            if(garantie.code == "gi" || garantie.code == "gv" || garantie.code == "bris"){
              garantie['selected'] = true;
            }
            this.incendie=true;
            this.vol=true;
            this.avance = true;
            this.collision=true;
          }

            if (garantie.code == "gtcom"  || garantie.code == "gtp" || garantie.code == "gar"|| garantie.code == "bris") {

              // if(garantie.code=='gar'){
              //   garantie['selected'] = false;
              //   garantie['disabled'] = true;
              // }

              if(garantie.code=='bris'){
                 garantie['disabled'] = true;
               }
             
              if(garantie.code=='gtcom'){
                garantie['selected'] = false;
                garantie['disabled'] = true;
              }
              if(garantie.code=='gtp'){
                garantie['selected'] = false;
                garantie['disabled'] = true;
              }
              this.utilsService.showToastInfo(" Veuillez désélectionner ", "Pour sélectionner Garantie Tierce complète ou Garantie Tierce plafonnée ");
             
            }
        } 
        }
         else if (!gExisttiercecomp && !gExisttiercepla && !gExist && g.code == "gtcol") 
        {
          this.selectedG.push(line);
          this.collision = true;
          sg['selected'] = true;
          g['selected'] = true;
          for (let garantie of this.garanties) {
            if (garantie.code == "gtcom" ) {
              if(garantie.code=='gtcom'){
                garantie['selected'] = false;
                garantie['disabled'] = true;
              }
            
            }
          }

        }




        // pour garantie tierce plafonné

        else if (!gExisttiercecomp && !gExisttierceauto && !gExist && g.code == "gtp") 
        {
        this.selectedG.push(line);
        this.plafone = true;
        sg['selected'] = true;
        g['selected'] = true;
        for (let garantie of this.garanties) {
          if (garantie.code == "gi" || garantie.code == "gv" || garantie.code == "bris") 
          {
            let newLine = {
              garanties_id: garantie.id,
              garanties_name: garantie.nom,
              garantie_categories_id: garantie.id,
              code: garantie.code
            }
            this.selectedG.push(newLine);
            if(garantie.code == "gi" || garantie.code == "gv" || garantie.code == "bris"){
              garantie['selected'] = true;
            }
            this.incendie=true;
            this.vol=true;
            this.avance = true;
            this.plafone=true;
          }
            if (garantie.code == "gtcom"  || garantie.code == "gtcol" || garantie.code == "gar" || garantie.code == "bris") {
             
              // if(garantie.code=='gar'){
              //   garantie['selected'] = false;
              //   garantie['disabled'] = true;
              // }
              if(garantie.code=='bris'){
                garantie['disabled'] = true;
              }

              if(garantie.code=='gtcom'){
                garantie['selected'] = false;
                garantie['disabled'] = true;
              }
              if(garantie.code=='gtcol'){
                garantie['selected'] = false;
                garantie['disabled'] = true;
              }
              this.utilsService.showToastInfo(" Veuillez désélectionner ", "Pour sélectionner Garantie Tierce Collision ou Garantie Tierce complète ");
             
            }
          }
          }
          else
            if (!gExist && g.code == "gi") {
              this.selectedG.push(line);
              this.incendie = true;
              sg['selected'] = true;
              g['selected'] = true;
            }
            else
              if (!gExist && g.code == "gv") {
                this.selectedG.push(line);
                this.vol = true;
                sg['selected'] = true;
                g['selected'] = true;
              } else
                if (!gExist && g.code == "gpt") {
                  let gExistGPT = this.selectedG.find((selec) => selec.code == g.code);
                  if (gExistGPT) {
                    this.selectedG.splice(this.selectedG.indexOf(gExistGPT));
                    let tab = sousGarantie.filter(el => el.id == gExistGPT.garanties_id)
                    if (tab.length > 0)
                      tab[0]['selected'] = false;
                  }
                  this.selectedG.push(line);
                  sg['selected'] = true;
                  g['selected'] = true;
                }
                else
                  if (gExisttiercecomp && !gExist && (g.code == "gtcol" || g.code == "gtp")) {
                    this.utilsService.showToastError(" Veuillez désélectionner d'abord", "Impossible de sélectionner Garantie Tierce complète et Garantie Tierce collision et Garantie Tierce plafonnée en même temps");
                    sg['selected'] = false;
                    g['selected'] = false;
                  }
                  else
                    if (gExisttierceauto && !gExist && (g.code == "gtcom" || g.code == "gtp")) {
                      this.utilsService.showToastError(" Veuillez désélectionner d'abord", "Impossible de sélectionner Garantie Tierce complète et Garantie Tierce collision et Garantie Tierce plafonnée en même temps");
                      sg['selected'] = false;
                      g['selected'] = false;
                    }
                    else
                      if (gExisttiercepla && !gExist && (g.code == "gtcom" || g.code == "gtcol")) {
                        this.utilsService.showToastError(" Veuillez désélectionner d'abord", "Impossible de sélectionner Garantie Tierce complète et Garantie Tierce collision et Garantie Tierce plafonnée en même temps");
                        sg['selected'] = false;
                        g['selected'] = false;
                      }
  }
 
  setAssure(n) {
    let user = this.user.infos;
    if (n === '1') {
      this.simulationVehiculeForm.get('isSubscriber').setValue(true);
      this.currentSouscripteur = "Pour moi-même";
      this.simulationVehiculeForm.get('assure').get('nom').setValue(user.lastName);
      this.simulationVehiculeForm.get('assure').get('prenom').setValue(user.firstName);
      this.simulationVehiculeForm.get('assure').get('email').setValue(user.email);
      this.simulationVehiculeForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.simulationVehiculeForm.get('assure').get('adresse').setValue(user.adresse);
      this.simulationVehiculeForm.get('assure').get('profession').setValue(user.profession);
      this.simulationVehiculeForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
    }
    else if(n === '2') {
      this.simulationVehiculeForm.get('isSubscriber').setValue(false);
      this.currentSouscripteur = "Pour un tiers";
      this.simulationVehiculeForm.get('assure').get('nom').setValue('');
      this.simulationVehiculeForm.get('assure').get('prenom').setValue('');
      this.simulationVehiculeForm.get('assure').get('email').setValue('');
      this.simulationVehiculeForm.get('assure').get('numeroTelephone').setValue('');
      this.simulationVehiculeForm.get('assure').get('adresse').setValue('');
      this.simulationVehiculeForm.get('assure').get('profession').setValue('');
      this.simulationVehiculeForm.get('assure').get('secteuractivite').setValue('');
    }
    else if(n === '3') {
      this.simulationVehiculeForm.get('isSubscriber').setValue(false);
      // this.currentSouscripteur = "Pour un tiers";
      this.simulationVehiculeForm.get('assure').get('nom').setValue('');
      this.simulationVehiculeForm.get('assure').get('prenom').setValue('');
      this.simulationVehiculeForm.get('assure').get('email').setValue(user.email);
      this.simulationVehiculeForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.simulationVehiculeForm.get('assure').get('adresse').setValue(user.adresse);
      this.simulationVehiculeForm.get('assure').get('profession').setValue(user.profession);
      this.simulationVehiculeForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
    }
  }
  connecter() {
    
    this.userInconnu = "";
    if (this.loginform.valid) {
      this.loaderVisible = true;
      let data = {
        ... this.loginform.value
      }
      this.subscriptionService.login(data)
        .subscribe((res) => {
          if (res['data']) {
            const tokenObj = res['data'];
            let infos = {
              id: tokenObj.id,
              matricule: tokenObj.matricule,
              firstName: tokenObj.firstName,
              lastName: tokenObj.lastName,
              email: tokenObj.email,
              telephone: tokenObj.telephone,
              secteuractivite:tokenObj.secteuractivite,
              profession:tokenObj.profession,
              adresse:tokenObj.adresse,
              genre:tokenObj.genre
            }
            let detail = {
              id_token: tokenObj.access_token,
              refresh_token: tokenObj.refresh_token,
              // roles: tokenObj.roles,
              infos: infos,
              authorities: tokenObj.authorities
            };
            if (res['data']['access_token']) {
              localStorage.setItem('microFinance', JSON.stringify(detail));
              localStorage.setItem('microFinanceBo', JSON.stringify(detail));
              this.accountService.sendAccount(JSON.stringify(infos));
              this.user = detail;
              this.login = true;
              this.getModelvoiture();
              this.getVehiculeType();
              this.getModelvoiture();
              this.getGaranties();
              this.getAgences();
              this.getPaymentMethods();
              this.getDurees();
              // this.loaderVisible = false;
              this.utilsService.showToastSuccess("Effectuée avec succés", "Connexion");
              this.modalService.dismissAll(this.modalog);
              // Appel fonction pour ouverture modal de souscription
              // this.Souscription();
            }
            else {
              this.souscrire();
              this.userInconnu = res['message'];
              this.loaderVisible = false;
            }
          } else {
            this.loaderVisible = false;
            this.userInconnu = res['message'];
          }
          this.loaderVisible = false;
        }, (err) => {
          this.userInconnu = err['error']['message'] || err['error']['errors'][0]['message'];
          this.loaderVisible = false;
        });

    } else {
      this.checkValidity(this.loginform);
    }
  }
  valider() {

    if (this.simulationVehiculeForm.get('nombrePlace').invalid || this.simulationVehiculeForm.get('duree').invalid || this.simulationVehiculeForm.get('puissance').invalid || this.simulationVehiculeForm.get('marque').invalid || this.simulationVehiculeForm.get('modele').invalid || this.simulationVehiculeForm.get('date_mis_en_circulation').invalid) {
      this.invalid = true;
    } else {
      this.invalid = false;
      this.selectVehiculeCategories(this.currentIdCat);
      
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      let datejour = new Date();
      let startDate = this.simulationVehiculeForm.get("date_mis_en_circulation").value;

      // @ts-ignore
      let difference = (datejour.getTime() - startDate.getTime()) / (1000 * 3600 * 24);
      this.age = difference / 365;
      if (this.age > 5) {
        this.garanties = this.garanties.filter(element => element.code != 'gtcom');
      }
    }
  }
  removeGarantie(g: any, sg: any) {
    let line = {
      code: g.code,
      garanties_id: g.id,
      garantie_categories_id: sg.id
    }
    if (g.code == "gtcom") {
      this.complete = false;
    }
    else if (g.code == "gtcol") {
      this.collision = false;
    }
    else if (g.code == "gi") {
      this.incendie = false;
    }
    else if (g.code == "gpt") {
      this.collision = false;
      this.complete = false;
    }
    let index = this.selectedG.findIndex(gr => gr.garanties_id == g.id && gr.garantie_categories_id == sg.id);
    if (index >= 0) {
      this.selectedG.splice(index, 1);

      sg['selected'] = false;
      g['selected'] = false;

    }
  }

  checkSelectedG(code) {
    if (this.linkedCode.indexOf(code)) {
      let elm = this.selectedG.find((gr) => (gr.code == 'gtcom' || gr.code == 'gtcol' || gr.code == 'gdr'));
      if (elm && elm.code != code) {
        return true;
      }
    }
    return false;
  }
  get_all_devis() {

    let body = {
      'garanties': {},
      ...this.simulationVehiculeForm.value
    }

    this.subscriptionService.simulatePrime(body).subscribe((resp) => {
      if (resp['code'] == 200 && !resp['error']) {
        this.simulation = resp['data'];
      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    });

  }

  getPaymentMethods() {
    this.subscriptionService.getPaymentMethods().subscribe((resp) => {
      this.paymentMethods = resp['data'];
      

      for (let pay of this.paymentMethods) {
        if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet.jfif";
        else if (pay.code == 'OM') pay['logo'] = "assets/images/om.jpg";
        else if (pay.code == 'FM') pay['logo'] = "assets/images/fm.png";
        else if (pay.code == 'WM') pay['logo'] = "assets/images/wm.jpg";
        else if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet2.png";
      }
      this.tmpPaymentMethods = this.paymentMethods;
      this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
      this.simulationVehiculeForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
    });
  }

  getAgences() {
    this.subscriptionService.getListAgences().subscribe((resp) => {
      this.listAgences = resp['data'];
    });
  }
  // loginform(){
  //   let body={
  //     ...this.loginform1
  //   }
  //   this.subscriptionService.login().subscribe((resp) =>{
  //     if(resp['data'])
  //       localStorage.setItem('access_token_ma', resp['data']['access_token']);
  //   });
  // }
  processSimulation() {
    this.listAssureurs = [];
    this.assureurs = [];
    if (this.complete || this.collision || this.vol || this.plafone) {
      if (this.simulationVehiculeForm.get('valeurNeuf').valid || this.simulationVehiculeForm.get('valeurVenal').valid) {
        this.wizard.goToNextStep();
        window.scrollTo(500, 500);
        this.assureur();
      }
      else {
        this.utilsService.showToastError('Error', "Valeur vénal ou valeur à neuf obligatoire");
      }
    }
    else {
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      this.assureur();
    }

  }
  assureur() {
    this.assureurs=[];
    this.listAssureurs = [];
    this.sendingRequestAssureur = true;
    this.subscriptionService.assureur().subscribe((resp) => {
      if (resp['responseCode'] = 200) {
        this.sendingRequestAssureur = false;
        let lesAssureurs = resp['data'];
        for (let val of lesAssureurs) {
          val.nom = val.nom.toLowerCase();
          if (val.code == 'NSIA') val['img'] = "../../../../../assets/images/nsia.png";
          else if (val.code == 'AXA') val['img'] = "../../../../../assets/images/axa.png";
          else if (val.code == 'AMSA') val['img'] = "../../../../../assets/images/amsa.png";
          else if (val.code == 'SALAMA') val['img'] = "../../../../../assets/images/salama.png";
          else val['img'] = "../../../../../assets/images/assurance.jpg";
        }
        for (let ass of lesAssureurs) {
          let selectedGid = [];
          this.simulationVehiculeForm.get('assureur').setValue(ass.id);
          // if(this.simulationVehiculeForm.get('valeurNeuf').valid || this.simulationVehiculeForm.get('valeurVenal').valid ){
          this.selectedG.forEach(element => {
            selectedGid.push(element.garantie_categories_id)
          });
          let data = this.simulationVehiculeForm.value;
          data['assureur'] = ass.id;
          let body = {
            'garanties': selectedGid,
            ...data
          };
         
          
          this.subscriptionService.simulatePrime(body).subscribe((resp: any) => {
            if (resp) {
              let resultSimulation = resp;
              let primerc = 0;
              let tot = 0;

              resultSimulation.forEach(element => {
                if (element.key == 'Prime RC') {
                  primerc = element.value;
                  this.primeAss.push(element);
                }
                if (element.key == 'Prime Totale TTC') {
                  //tot = primerc + element.value;
                  tot = element.value;
                }
              });
              ass['prix'] = tot;
            } else ass['prix'] = 0;
            this.listAssureurs.push(ass);
            this.triArray(this.listAssureurs,'prix');
          }, error => {
            ass['prix'] = 0;
            // this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");

            // this.sendingRequest = false;
          });
        }
      } else {
        this.sendingRequestAssureur = false;
        this.assureurs = [];
      }
    }, error => {
      this.sendingRequestAssureur = false;
      this.assureurs = [];
    })
  }
  assureurUpdate() {
    this.sendingRequestAssureur = true;
    this.updateRequest = true
    this.subscriptionService.assureur().subscribe((resp) => {
      if (resp['responseCode'] = 200) {
        this.sendingRequestAssureur = false;
        let lesAssureurs = resp['data'].filter(el => el.code === this.currentAssureur.code);
        
        for (let val of lesAssureurs) {
          val.nom = val.nom.toLowerCase();
          if (val.code == 'NSIA') val['img'] = "../../../../../assets/images/nsia.png";
          else if (val.code == 'AXA') val['img'] = "../../../../../assets/images/axa.png";
          else if (val.code == 'AMSA') val['img'] = "../../../../../assets/images/amsa.png";
          else if (val.code == 'SALAMA') val['img'] = "../../../../../assets/images/salama.png";
          else val['img'] = "../../../../../assets/images/assurance.jpg";
        }
        for (let ass of lesAssureurs) {
          let selectedGid = [];
          this.simulationVehiculeForm.get('assureur').setValue(ass.id);
          // if(this.simulationVehiculeForm.get('valeurNeuf').valid || this.simulationVehiculeForm.get('valeurVenal').valid ){
          this.selectedG.forEach(element => {
            selectedGid.push(element.garantie_categories_id)
          });
          let data = this.simulationVehiculeForm.value;
          data['assureur'] = ass.id;
          let body = {
            'garanties': selectedGid,
            ...data
          };
          this.subscriptionService.simulatePrime(body).subscribe((resp: any) => {
            if (resp) {
              let resultSimulation = resp;
              let primerc = 0;
              let tot = 0;

              resultSimulation.forEach(element => {
                if (element.key == 'Prime RC') {
                  primerc = element.value;
                  this.primeAss.push(element);
                }
                if (element.key == 'Prime Totale TTC') {
                  //tot = primerc + element.value;
                  tot = element.value;
                }
              });
              ass['prix'] = tot;
            } else ass['prix'] = 0;
            // this.listAssureurs.push(ass);
            // this.currentAssureur = ass;
            this.updateGaran(ass);
            // this.triArray(this.listAssureurs,'prix');
          }, error => {
            ass['prix'] = 0;
            // this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");

            // this.sendingRequest = false;
          });
        }
      } else {
        this.sendingRequestAssureur = false;
        // this.assureurs = [];
      }
    }, error => {
      this.sendingRequestAssureur = false;
      // this.assureurs = [];
    })
  }
  triArray(data,type){
    data.sort(function(a, b) {
      return a[type] - b[type];
    });
  }
  onDateSelect3(event, groupName, controlName) {
    if (event)
      this.simulationVehiculeForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
  }
  getprix(id_ass) {
  }
  lanceforgot() {
    this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modaforgot, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {

    });
  }
  forgot() {
    let data = {
      "email": this.inscriptionForm.get('email').value,
    }
    this.loaderVisible = true;
    this.subscriptionService.reset(data).subscribe((resp) => {
      if (resp["responseCode"] = 200) {
        this.utilsService.showToastSuccess("Un mail vous a été envoyé pour réinitialiser votre mot de passe", "");
        this.modalService.dismissAll(this.modasign);
      }
      else {
        this.utilsService.showToastError(resp['message'], "Echec");
      }
      this.loaderVisible = false;
    }, error => {
      let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
      if (error && error.error) {
        message = error.error.message;
      }
      this.utilsService.showToastError(message, "Echec");

      this.loaderVisible = false;
    })
  }
  processSimulation2(ass, sous?) {
    
    if (sous)
      this.isSouscription = false;
    else this.isSouscription = true;
    this.assureur1 = ass.id;
    this.currentAssureur = ass;
    this.selectedGid = [];
    this.garantieAss = [];
    this.primeAss = [];
    window.scroll(0,0);
    this.simulationVehiculeForm.get('assureur').setValue(ass.id);
    // if(this.simulationVehiculeForm.get('valeurNeuf').valid || this.simulationVehiculeForm.get('valeurVenal').valid ){
    this.selectedG.forEach(element => {
      this.selectedGid.push(element.garantie_categories_id)
    });
    let body = {
      'garanties': this.selectedGid,

      ...this.simulationVehiculeForm.value
    };
    this.sendingRequest = true;
    this.subscriptionService.simulatePrime(body).subscribe((resp) => {
      if (resp && !resp['error']) {
        
        let response = JSON.stringify(resp);
        this.resultSimulation = JSON.parse(response);
        this.resultSimulation.forEach(element => {

        });
        window.scrollTo(0,0);
        this.openModalResult();

        this.resultSimulation.forEach(element => {
          if (element.key == 'Prime RC') {
            this.primerc = element.value;
            this.primeAss.push(element);
          }
          if (element.key == 'Prime Totale TTC') {
           // this.tot = this.primerc + element.value;
            this.tot = element.value;
            element.value = this.tot;
            this.paymentForm.amount = this.tot;
          }
          if(element.key!='Prime nette' && element.key!='Cout de police' && element.key!='Taxe' && element.key!='FGA'&& element.key!='Prime Totale TTC' )
            this.garantieAss.push(element);
          else this.primeAss.push(element);  
        
        });

      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    }, error => {

      this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");

      this.sendingRequest = false;
    });

  }
  updateGaran(ass) {
    this.isSouscription = true;
    this.assureur1 = ass.id;
    this.currentAssureur = ass;
    this.selectedGid = [];
    this.garantieAss = [];
    this.primeAss = [];
    // window.scroll(0,0);
    this.simulationVehiculeForm.get('assureur').setValue(ass.id);
    // if(this.simulationVehiculeForm.get('valeurNeuf').valid || this.simulationVehiculeForm.get('valeurVenal').valid ){
    this.selectedG.forEach(element => {
      this.selectedGid.push(element.garantie_categories_id)
    });
    let body = {
      'garanties': this.selectedGid,

      ...this.simulationVehiculeForm.value
    };
    this.updateRequest = true;
    this.subscriptionService.simulatePrime(body).subscribe((resp) => {
      if (resp && !resp['error']) {
        
        let response = JSON.stringify(resp);
        this.resultSimulation = JSON.parse(response);
        this.resultSimulation.forEach(element => {

        });
        // window.scrollTo(0,0);
        // this.openModalResult();

        this.resultSimulation.forEach(element => {
          if (element.key == 'Prime RC') {
            this.primerc = element.value;
            this.primeAss.push(element);
          }
          if (element.key == 'Prime Totale TTC') {
           // this.tot = this.primerc + element.value;
            this.tot = element.value;
            element.value = this.tot;
            this.paymentForm.amount = this.tot;
          }
          if(element.key!='Prime nette' && element.key!='Cout de police' && element.key!='Taxe' && element.key!='FGA'&& element.key!='Prime Totale TTC' )
            this.garantieAss.push(element);
          else this.primeAss.push(element);  
        
        });

      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.updateRequest = false;
      this.modalService.dismissAll(this.updateAssures);
    }, error => {

      this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");

      this.updateRequest = false;
    });

  }
  convertStringToNumber(val){
    return parseFloat(val);
  }
  inscrireutil() {
    if (this.inscriptionForm.valid) {
      let data = {
        "telephone": this.inscriptionForm.get('tel').value,
        "email": this.inscriptionForm.get('email').value,
        "type": 5 + ""
      }
      this.loaderVisible = true;
      this.subscriptionService.sign(data).subscribe((resp) => {
        if (resp["responseCode"] == 200) {
          this.utilsService.showToastSuccess("Les informations d'activation de votre compte vous ont été envoyées par email et/ou SMS.", "Inscription  réussie");
          this.modalService.dismissAll(this.modasign);
          this.loaderVisible = false;
          this.inscriptionForm.reset();
        }
        else {
          this.loaderVisible = false;
          this.utilsService.showToastError(resp['message'], "Echec");
        }
        this.loaderVisible = false;
      }, error => {
        this.utilsService.showToastError(error.error.errors[0].message, "Echec");
        this.loaderVisible = false;
      });

    } else {
      this.checkValidity(this.inscriptionForm);
    }
  }

  checkValidity(g: FormGroup) {
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsDirty();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsTouched();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).updateValueAndValidity();
    });
  }
  goTo(step){
    window.scroll(500,500);
    this.wizard.goToStep(step);
  }
  openModalResult() {
    window.scrollTo(0,0);
    this.modalService.open(this.contentResultass, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe')
        //this.stepWa.goToStep(this.wizard, 4)
        this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      let user = this.user.infos;
      this.currentSouscripteur = "Pour moi-même";
      this.simulationVehiculeForm.get('assure').get('nom').setValue(user.lastName);
      this.simulationVehiculeForm.get('assure').get('prenom').setValue(user.firstName);
      this.simulationVehiculeForm.get('assure').get('email').setValue(user.email);
      this.simulationVehiculeForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.simulationVehiculeForm.get('assure').get('adresse').setValue(user.adresse);
      this.simulationVehiculeForm.get('assure').get('profession').setValue(user.profession);
      this.simulationVehiculeForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
      this.simulationVehiculeForm.get('assure').get('genre').setValue(user.genre);
    }, (reason) => {

    });
    if (this.datas) {
      if (this.modalService.hasOpenModals()) {
        setTimeout(() => {
          this.captureScreen();
        }, 100);
        //
      }
    }

  }

  verifymodal() {
    this.modalService.open(this.contentResultgrise, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {
        this.wizard.goToNextStep();
        window.scrollTo(500, 500);
      }
      else {

      }
    }, (reason) => {

    });
  }
  selectPayment(pay) {
    this.simulationVehiculeForm.get('moyenDePaiement').setValue(pay.code == this.simulationVehiculeForm.get('moyenDePaiement').value ? "" : pay.code);
    this.paymentMethod = pay.nom.toLowerCase();
  }
  saveData() {
    let data
    if(this.assureurId == this.assureur1){
      data = {
        ...this.simulationVehiculeForm.value,
        "assureur": this.assureur1,
        "garanties": this.selectedGid,
        "id":this.currentId
      };
    }else {
      data = {
        ...this.simulationVehiculeForm.value,
        "assureur": this.assureur1,
        "garanties": this.selectedGid
      };

    }
    
    
    this.sendingRequest = true;
    let d = this.resultSimulation.map((res) => {
      if (res.key.toLowerCase() == 'prime totale ttc') {
        data['prix'] = res.value;
        this.paymentForm.amount = res.value;
      }
      return res;
    });
    data.prix = data.prix.toFixed();
    this.subscriptionService.saveSouscription(data).subscribe((resp) => {
      this.sendingRequest = true;
      if (resp['responseCode'] == 200) {
        if(this.simulationVehiculeForm.get('isSubscriber').value==true){  
          this.user.infos['secteuractivite'] = this.simulationVehiculeForm.get('assure').get('secteuractivite').value;
          this.user.infos['adresse'] = this.simulationVehiculeForm.get('assure').get('adresse').value;
          this.user.infos['profession'] = this.simulationVehiculeForm.get('assure').get('profession').value;
          localStorage.setItem('microFinance',JSON.stringify(this.user));
        }
        let type = '&meansType=CARTE_OTHER';
        this.paymentForm.amount = Number(this.paymentForm.amount).toFixed()
        this.initPaymentForm.montant = this.paymentForm.amount;
        this.initPaymentForm.requestId = this.dateTime;
        this.initPaymentForm.numeroSouscription = resp['data'].numeroSouscription;
        this.initPaymentForm.status = "PENDING";
        this.subscriptionService.initPaiementBack(this.initPaymentForm).subscribe((rep) => {
          if (rep['responseCode'] != 200) {
            this.utilsService.showToastError(resp['message'], "Echec");
          }
          this.sendingRequest = false;

        }, error => {
          this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
          this.sendingRequest = false;
          window.scrollTo(500, 500);
        });
        this.uuId = resp['data'].id;
        let email = this.user.infos.email;
        this.message ="Bonjour Madame/Monsieur " + " , je vous prie de trouver ci-joint le lien de votre devis de votre souscription "+
            " " +environment.frontWebUrl+"generer-pdfAuto/"+this.uuId;
        let detailsEnvoi = {
          'email': email,
          'message': this.message
        }
        this.subscriptionService.sendMail(detailsEnvoi).subscribe((resp) => {
          this.sendingRequest = true;
          if (resp['responseCode'] == 200) {

          }
        });
        if (this.paymentMethod == 'wallet') type = '&meansType=WALLET';
        // let hash = this.paymentForm.timestamp + this.paymentForm.amount + this.paymentForm.requestId + 'XOF' + this.key;
        let hash = this.paymentForm.timestamp +''+this.paymentForm.amount +''+this.paymentForm.requestId + 'XOF' + this.key;
        this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);
        this.paymentForm.customerFirstName = this.simulationVehiculeForm.get('souscripteur').get('prenom').value;
        this.paymentForm.customerLastName = this.simulationVehiculeForm.get('souscripteur').get('nom').value;
        this.paymentForm.customerPhone = this.simulationVehiculeForm.get('souscripteur').get('telephone').value;
        this.paymentForm.customerCountry = 'SN';
        this.paymentForm.customerCity = 'DAKAR';
        this.paymentForm.customerState = 'DAKAR';
        this.paymentForm.customerZipCode = '25455';
        this.paymentForm.customerAddress = 'DAKAR';
        this.paymentForm.customerEmail = this.simulationVehiculeForm.get('souscripteur').get('email').value;
        this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {

          if (respApg['code'] == '0000') {
            this.utilsService.showToastSuccess("Votre souscription a été enregistrée avec succès. Vous allez être redirigé vers la page de paiement", "Souscription réussie")
            setTimeout(() => {
              this.wizard.goToStep(0);
              this.simulationVehiculeForm.reset();
              window.open(respApg['paymentUrl'] + type, '_blank');
            }, 2000);
          } else {
            this.sendingRequest = false;
            this.utilsService.showToastError(respApg['message'] || "Impossible de contacter le serveur", "Echec");
            // this.simulationVehiculeForm.reset;
            this.wizard.goToStep(1);
            window.scrollTo(500, 500);
          }
        }, error => {
          this.sendingRequest = false;
          this.utilsService.showToastError("Impossible de contacter le serveur", "Echec");
          // this.simulationVehiculeForm.reset;
          this.wizard.goToStep(1);
          window.scrollTo(500, 500);
        });
      } else {
        this.sendingRequest = false;
        window.scrollTo(500, 500);
        this.utilsService.showToastError(resp['message'], resp['message']);
        // this.simulationVehiculeForm.reset;
      }
    }, (error) => {
      this.sendingRequest = false;
      window.scrollTo(500, 500);
      this.utilsService.showToastError(error.error.errors[0].message, "Echec")
    });


  }
  checkSubscription(numberSouscription) {
    const source = interval(5000)
    const check = source.subscribe(x => {
      fetch(environment.baseApiUrl + 'souscriptions/check-subscription?numeroSouscription=' + numberSouscription)
        .then(response => {
          response.json()
            .then(data => {
              if (data['responseCode'] == 200) {
                this.router.navigateByUrl("success-payment");
                check.unsubscribe();
              }
            });
        });
    });
  }
  souscrire() {
    this.modalService.dismissAll(this.modasign);
    let token = JSON.parse(localStorage.getItem('microFinance'));
    if (token) {
      this.login = true;

    } else {
      this.modalService.open(this.modalog, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
        if (result == 'subscribe') {

        }
      }, (reason) => {

      });
    }
  }

  domicile() {
    this.pointRelais = null;
    this.currentDom = "activeLivraison";
    this.currentRel = "";
    this.paiment = true;
    this.simulationVehiculeForm.get('livraison').setValue('Domicile');
    this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
    
    this.simulationVehiculeForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
  }
  relais() {
    this.currentRel = "activeLivraison";
    this.currentDom = "";
    this.simulationVehiculeForm.get('livraison').setValue('Relais');
    this.paiment = true;
    this.paymentMethods = this.tmpPaymentMethods;
    this.subscriptionService.getPointRelais(this.currentAssureur['id']).subscribe((resp) => {
      this.pointRelais = resp['data'];
    });
    this.simulationVehiculeForm.get('moyenDePaiement').setValue(this.paymentMethods[0].code);
  }

  onDateSelect(event, controlName) {
    if (event)
      this.simulationVehiculeForm.get('' + controlName).setValue(new Date(event.year + "/" + event.month + "/" + event.day));
  }

  onDateSelectRappel(event, controlName) {
    if (event)
      this.etrerappeleForm.get('' + controlName).setValue(new Date(event.year + "/" + event.month + "/" + event.day));
  }
  onDateSelect2(event, controlName) {
    if (event)
      this.simulationVehiculeForm.get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));

    }
    checkDuree(){
      // @ts-ignore
      if(this.simulationVehiculeForm.get('date_effet').value!=''){
        this.dateExpiration = moment(this.simulationVehiculeForm.get('date_effet').value).add(Number(this.simulationVehiculeForm.get('duree').value),'month');
        
      }
    }
  openPDF(): void {
    let data = document.getElementById('autoDownload');
    var pdf = new jsPDF();
    // @ts-ignore
    document.body.style.zoom = "70%";
    var options = {
      pagesplit: false,
      x: 0,
      y: 770,
      quality: 4,
      scale: 2,
    };
    pdf.setFont('Calibri');
    pdf.text(50, 50, 'Now this is Calibri');
    pdf.addHTML(data, options, function () {
      pdf.save("Devis_Automobile.pdf");
    }
    );
    // @ts-ignore
    document.body.style.zoom = "100%";
  }

 captureScreen(): void {
    var data = document.getElementById('autoDownload');
    html2canvas(data, { backgroundColor: 'white', removeContainer: true, scale:1, scrollX: 0, scrollY: 0 }).then(canvas => {
      var imgWidth = 210;
      var pageHeight = 470;
      const contentDataURL = canvas.toDataURL('application/pdf')
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF('p', 'mm', 'a4');
      
      var position = 0;

      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('Devis_Automobile.pdf');
      if (this.datas) {
        this.modalService.dismissAll();
      }
    });
 }

  processSimulationDevis() {
    if (this.datas){
      this.assureur1 = this.detailsPdf.assureur.id;
      this.currentAssureur = this.detailsPdf.assureur;
      this.selectedGid = [];
      this.garantieAss = [];
      this.primeAss = [];
      window.scroll(0,0);
      this.simulationVehiculeForm.get('assureur').setValue( this.detailsPdf.assureur.id);
          let garanties = this.detailsPdf.garanties.map((el) => {
            return el.type.id;
          });
        // }
     
      this.simulationVehiculeForm.get('assureur').setValue( this.detailsPdf.assureur.id);
      this.simulationVehiculeForm.get('valeurVenal').setValue( this.detailsPdf.valeurVenal);
      this.simulationVehiculeForm.get('valeurNeuf').setValue( this.detailsPdf.valeurNeuf);
      this.simulationVehiculeForm.get('nombrePlace').setValue( this.detailsPdf.nombrePlace);
      this.simulationVehiculeForm.get('duree').setValue( this.detailsPdf.duree.id);
      this.simulationVehiculeForm.get('puissance').setValue( this.detailsPdf.puissance);
      this.simulationVehiculeForm.get('categorieVehicule').setValue( this.detailsPdf.categorieVehicule.id);
      this.simulationVehiculeForm.get('typeVehicule').setValue( this.detailsPdf.categorieVehicule.libelle);
      this.simulationVehiculeForm.get('marque').setValue(this.detailsPdf.vehicule.marque)
      this.simulationVehiculeForm.get('modele').setValue(this.detailsPdf.vehicule.model)
      this.simulationVehiculeForm.get('immatriculation').setValue(this.detailsPdf.immatriculation)
      this.simulationVehiculeForm.get('date_mis_en_circulation').setValue(this.detailsPdf.dateMisEnCirculation)
      let body = {
        'garanties': garanties,

        ...this.simulationVehiculeForm.value
      };
      this.sendingRequest = true;
      this.subscriptionService.simulatePrime(body).subscribe((resp) => {
        if (resp && !resp['error']) {
          let response = JSON.stringify(resp);
          this.resultSimulation = JSON.parse(response);
          this.resultSimulation.forEach(element => {
          });
          window.scrollTo(0,0);
          this.openModalResult();
          this.resultSimulation.forEach(element => {
            if (element.key == 'Prime RC') {
              this.primerc = element.value;
              this.primeAss.push(element);
            }
            if (element.key == 'Prime Totale TTC') {
             // this.tot = this.primerc + element.value;
              this.tot = element.value;
              this.paymentForm.amount = this.tot;
            }
            if(element.key!='Prime nette' && element.key!='Cout de police' && element.key!='Taxe' && element.key!='FGA'&& element.key!='Prime Totale TTC' )
              this.garantieAss.push(element);
            else this.primeAss.push(element);
          });
        } else {
          this.utilsService.showToastError(resp['message'], "Données invalides");
        }
        this.sendingRequest = false;
      }, error => {
        this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
        this.sendingRequest = false;
      });

    }
  }

  getDescriptionGarantie(){
    if(this.garanties){
      for (let garantie of this.garanties) {
        if (garantie.code == "gtcom") {
          garantie.description = "véhicule."
        }
      }
    }
  }
  openModal(){
    this.modalService.open(this.updateAssures, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }
  openModalIV(){
    this.modalService.open(this.updateInfoVehicule, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }
  openModalGaran(){
    this.modalService.open(this.updateGarantie, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }

// Modal pour devis etre envoye
  openModalEnvoyeDevis(type){
    this.assureurAEnvoye = type;
    this.modalService.open(this.devisAEnvoye, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {
      }
    }, (reason) => {
    });
  }
  etreRappele() {
    this.sendingRequestAssureur = true;
    this.updateRequest = true;
    this.etrerappeleForm.get('code').setValue(this.assureurAEnvoye.code);
    this.subscriptionService.etreRappele(this.etrerappeleForm.value).subscribe((resp) => {
      if (resp['responseCode'] = 200) {
        this.utilsService.showToastSuccess("Votre demande de rappel a été enregistré avec succès", "Demande de rappel")
        this.sendingRequestAssureur = false;
        this.updateRequest = false
        this.modalService.dismissAll(this.devisAEnvoye);
      } else {
        this.updateRequest = false
        this.sendingRequestAssureur = false;
      }
    }, error => {
      this.updateRequest = false
      this.sendingRequestAssureur = false;
      this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
    });
  }

  devisParMail(type) {
    this.assureurId = type.id;
    this.selectedG.forEach(element => {
      this.selectedGid.push(element.garantie_categories_id)
    });
    this.sendingRequestAssureur = true;
    this.updateRequest = true;
    let body = {
      ...this.simulationVehiculeForm.value,
      "assureur": type.id,
      "garanties": this.selectedGid
    };

    // Recuperation du prix
    this.subscriptionService.simulatePrime(body).subscribe((resp:any) => {
      if (resp) {
        let prix = resp.find(el=>{
         return el.key =='Prime Totale TTC'
        });
        let data = {
          ...this.simulationVehiculeForm.value,
          "assureur": type.id,
          "garanties": this.selectedGid,
          "type": "mail",
          "prix": prix.value
        };

        // Envoi devis avec prix
        this.subscriptionService.envoiDevisMail(data).subscribe((resp) => {
          if (resp['responseCode'] = 200) {
            this.currentId = resp['data'].id;
            this.utilsService.showToastSuccess(resp['message'], "Devis envoyé avec succés");
            
            this.sendingRequestAssureur = false;
          } else {
            this.sendingRequestAssureur = false;
          }
        }, error => {
          this.sendingRequestAssureur = false;
          this.utilsService.showToastError(resp['message'], "erreur sur le server");
        })

        
      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    });
  }
  devisParWatshap(type) {
    this.assureurId = type.id;
    this.selectedG.forEach(element => {
      this.selectedGid.push(element.garantie_categories_id)
    });
    this.sendingRequestAssureur = true;
    this.updateRequest = true;
    let body = {
      ...this.simulationVehiculeForm.value,
      "assureur": type.id,
      "garanties": this.selectedGid
    };

    // Recuperation du prix
    this.subscriptionService.simulatePrime(body).subscribe((resp:any) => {
      if (resp) {
        let prix = resp.find(el=>{
         return el.key =='Prime Totale TTC'
        });
        let data = {
          ...this.simulationVehiculeForm.value,
          "assureur": type.id,
          "garanties": this.selectedGid,
          "type": "watshap",
          "prix": prix.value
        };

        // Envoi devis avec prix
        this.subscriptionService.envoiDevisMail(data).subscribe((resp) => {
          if (resp['responseCode'] = 200) {
            this.currentId = resp['data'].id;
            this.utilsService.showToastSuccess(resp['message'], "Devis envoyé avec succés");
            
            this.sendingRequestAssureur = false;
          } else {
            this.sendingRequestAssureur = false;
          }
        }, error => {
          this.sendingRequestAssureur = false;
          this.utilsService.showToastError(resp['message'], "erreur sur le server");
        })

        
      } else {
        this.utilsService.showToastError(resp['message'], "Données invalides");
      }
      this.sendingRequest = false;
    });
  }
  updateDevise(formControl: string){

    this.assureurUpdate();
    // this.assureur();
  }


  goToNext(res){
    this.rest = res;
    this.wizard.goToNextStep();
  }
}
