import { filter } from 'rxjs/operators';
import { Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubscriptionService } from 'src/app/shared/service/subscription.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
// import * as jsPDF from 'jspdf'
import 'jspdf-autotable'
import {DatePipe, Location} from "@angular/common";

import { ActivatedRoute, Router } from '@angular/router';
import { apiUrls } from 'src/app/shared/api-urls';
import { WizardComponent } from 'angular-archwizard';
import { UtilsService } from 'src/app/shared/service/utils.service';
import { interval, range } from 'rxjs';
import { IAttachment } from './attachement.model';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { animate, style, transition, trigger } from '@angular/animations';
import { response } from 'express';
import { AccountService } from 'src/app/shared/service/account.service';
import * as moment from 'moment';
import html2canvas from "html2canvas";
import {GenererPdfService} from "../../../shared/service/generer-pdf.service";
import { timeStamp } from 'console';
import { ExportService } from 'src/app/shared/service/export.service';
declare let jsPDF


@Component({
  selector: 'app-home-sante',
  templateUrl: './home-sante.component.html',
  styleUrls: ['./home-sante.component.scss'],
  providers: [ExportService, DatePipe],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(2500)
      ]),
      transition('* => void', [
        animate(2500),
        style({ opacity: 0 }),
      ])
    ]),
    trigger("slideInOut", [
      transition('void => *', [
        style({ transform: "translateX(60%)", opacity: 0 }),
        animate(
          "1500ms ease-in",
          // style({ transform: "translateX(0%)", opacity: 1 })
        ),
      ]),
      transition('* => void', [
        animate(
          "1500ms ease-out",
          // style({ transform: "translateX(60%)", opacity: 0 })
        ),
      ]),
    ]),
  ]
})
export class SantesComponent implements OnInit  {
  @Input() attachments: IAttachment[] = [];
  @Output() test = new EventEmitter<boolean>();
  moyenP:any;
  beneficiaryChoosen: boolean = false;
  conjointChoosen: boolean = false;
  enfantChoosen: boolean = false;
  visiblepack: boolean = false;
  beneficiaryChoosens: boolean = false;
  champsObligatoire: boolean = false;
  paymentMethod: string = "Paiement par agence";
  loadingFiles: number;
  beneficiaries = [];
  formules = [];
  durees = [];
  avalaibleDuration = [];
  paymentMethods = [];
  tmpPaymentMethods = [];
  sendingRequest: boolean = false;
  assure: number = 1;
  choix: boolean = false;
  prestations: any = [];
  prestataires: any = [];
  phoneMask: string = "00 00 000 000";
  cinMask: string = "0 000 0000 00000";
  dateTime = Math.floor(Date.now() / 1000);
  // key = "676AC4CC226201685E89ADB0C9B4510D";
  key = "FC48ABF885F26C13B0BE46B54618BC";
  paymentForm = {
    timestamp: this.dateTime,
    returnUrl: 'https://web-ma.suntelecoms.com/api/contrats/save-contrat',
    auth: '',
    amount: '',
    requestId: this.dateTime,
    // terminalNumber: 160820000101,
    terminalNumber: 423820000101,
    transactionCurrencyCode: 'XOF',
    customerLastName: '',
    customerFirstName: '',
    customerAddress: '',
    customerCountry: '',
    customerEmail: '',
    customerPhone: '',
    customerState: '',
    customerCity: '',
    customerZipCode: '',
    ville: '',
    montantSaisie: '',
  }
  today: any = {
    year: new Date().getFullYear(),
    month: new Date().getMonth()+1,
    day: new Date().getDate()
  };
  paiment: boolean = false;
  beneficiares: any = [];
  conjoits: any = [];
  enfants: any = [];
  beneficiaresEnfant: any = [];
  emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  souscriptionForm = new FormGroup({
    livraison: new FormControl('Domicile', [Validators.required]),
    duree: new FormControl("", [Validators.required]),
    datedebut: new FormControl("", [Validators.required]),
    formule: new FormControl("", [Validators.required]),
    canal: new FormControl(1, [Validators.required]),
    moyenDePaiement: new FormControl("paiement_agence", [Validators.required]),
    assureur: new FormControl("", [Validators.required]),
    produit: new FormControl("", [Validators.required]),
    montant: new FormControl(""),
    pack: new FormControl("sante"),
    isSubscriber: new FormControl(true),
    agence: new FormControl(""),
    nombreAssure: new FormGroup({
      nombreEnfant: new FormControl("", []),
      nombreAdulte: new FormControl("", []),
      typeFamille: new FormControl("", [Validators.required])

    }),
    souscripteur: new FormGroup({
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      genre: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
      telephone: new FormControl("", [Validators.required]),
      profession: new FormControl("", [Validators.required]),
      dateNaissance: new FormControl("", [Validators.required]),
      secteuractivite: new FormControl(""),
      adresse: new FormControl("")
    }),
    contrat: new FormGroup({
      debut: new FormControl("", [Validators.required]),
    }),
    assure: new FormGroup({
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      numeroTelephone: new FormControl("", [Validators.required]),
      profession: new FormControl("", [Validators.required]),
      adresse: new FormControl("", [Validators.required]),
      genre: new FormControl("", [Validators.required]),
      dateNaissance: new FormControl("", [Validators.required]),
      secteuractivite: new FormControl("", [Validators.required]),
      questionnaire: new FormControl("", []),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
    }),
    beneficiaire: new FormGroup({
      type: new FormControl("", [Validators.required]),
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      numeroTelephone: new FormControl("", [Validators.required]),
      profession: new FormControl("", [Validators.required]),
      genre: new FormControl("", [Validators.required]),
      secteuractivite: new FormControl("", [Validators.required]),
      adresse: new FormControl("", [Validators.required]),
      dateNaissance: new FormControl("", [Validators.required]),
      email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),
    }),
    AxASimulateur: new FormGroup({
      age: new FormControl("", [Validators.required]),
      isOld: new FormControl("", []),
      typeFormule: new FormControl("1", [Validators.required]),
      formule: new FormControl("", [Validators.required]),
      nombreEnfant: new FormControl("", []),
      // Validators.required,Validators.min(0)
      nombreAdulte: new FormControl("", [])
    }),
    beneficiaireEnfantAxa: new FormGroup({
      type: new FormControl("", []),
      prenom: new FormControl("", [Validators.required]),
      nom: new FormControl("", [Validators.required]),
      numeroTelephone: new FormControl("", [Validators.required]),
      profession: new FormControl("", []),
      genre: new FormControl("", [Validators.required]),
      secteuractivite: new FormControl("", []),
      adresse: new FormControl("", [Validators.required]),
      dateNaissance: new FormControl("", [Validators.required]),
    }),
    pointRelaisControl: new FormControl("", [Validators.required]),
    adresseLivraison: new FormControl("", [Validators.required]),
  });
  loginform = new FormGroup({
    username: new FormControl("", [Validators.required]),
    password: new FormControl("", [Validators.required]),
  });
  inscriptionForm = new FormGroup({
    tel: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.pattern(this.emailPattern)]),

  });
  forgotForm = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.pattern(this.emailPattern)]),

  });
//start plainte
  plainteForm = new FormGroup({
    nom: new FormControl("", [Validators.required]),
    prenom: new FormControl("", [Validators.required]),
    email: new FormControl("", [Validators.required]),
    telephone: new FormControl("", [Validators.required]),

    prestation: new FormControl("", [Validators.required]),
    nomEntreprise: new FormControl("", [Validators.required]),
    typePlainte: new FormControl("", [Validators.required]),
    naturePlainte: new FormControl(""),
    description: new FormControl(""),
  });
   sendingRequestPlainte: boolean = false;
   heureDisponibiliteList = [{ name: "08H-10H", value: "08H-10H",filtre:'Matin'},{ name: "10H-12H", value: "10H-12H",filtre:'Matin'},{ name: "12H-14H", value: "12H-14H",filtre:'Matin'},{ name: "15H-16H", value: "15H-16H",filtre:'Soir'},{ name: "16H-17H", value: "16H-17H",filtre:'Soir'},{ name: "17H-18H", value: "17H-18H",filtre:'Soir'}];
   heureDisponibiliteListTemoin = []
   disponibiteList = [{ name: "Matin", value: "Matin"},{ name: "Soir", value: "Soir"},{ name: "Toute la journée", value: "Toute la journee"}];
   typePlainteList = [{ name: "Mauvais accueil", value: "Mauvais accueil"},{ name: "Ne travaille plus avec l'assureur", value: "Ne travaille plus avec l'assureur"},{ name: "Problème Informatique", value: "Problème Informatique"},{ name: "Retard Prise en charge", value: "Retard Prise en charge"},{ name: "Autres", value: "Autres"}]
//end plainte

  products: any;
  date: string;
  numeroSous = "";
  refPro = "";
  devisType: string;
  dateExpiration: any;
  assureurs: any = [];
  _idAssurance: string;
  _typeAssurance: string;
  age = 0;
  initPaymentForm = {
    requestId: 0,
    numeroSouscription: '',
    status: '',
    montant: '',
  }
  pwd = 'password';
  listImages = ['../../../../../assets/images/a1.jpg', '../../../../../assets/images/a2.jpg', '../../../../../assets/images/a3.jpg', '../../../../../assets/images/a4.jpg', '../../../../../assets/images/a5.jpg', '../../../../../assets/images/a1.jpg', '../../../../../assets/images/a2.jpg', '../../../../../assets/images/a3.jpg', '../../../../../assets/images/a4.jpg', '../../../../../assets/images/a5.jpg'];
  currentSouscripteur = "Pour moi-même";
  currentPack: any = [];
  currentAssureur:any = "";
  currentCodeAs;
  currentTitle = "";
  enfant=0;
  conjoint=0;
  checkEnfant = 0;
  checkAdulte:any = 0;
  @ViewChild('wizard', { static: false }) wizard: WizardComponent;
  @ViewChild('contentResultass', { static: false }) contentResultass: any;
  @ViewChild('simulateurDevis', { static: false }) simulateurDevis: any;
  @ViewChild('contentResultlogin', { static: false }) modalog: any;
  @ViewChild('prestation', { static: false }) prestation: any;
  @ViewChild('prestataire', { static: false }) prestataire: any;
  @ViewChild('contentResultsign', { static: false }) modasign: any;
  @ViewChild('addAssures', { static: false }) modalAssure: any;
  @ViewChild('questionnaireSante', { static: false }) modalQuestionnaireSante: any;
  @ViewChild('contentResultforgot', { static: false }) modaforgot: any;
  @ViewChild('santeSimulateurDownload') santeSimulateurDownload: ElementRef;
  @ViewChild('santeDownload') santeDownload: ElementRef;
  @ViewChild('forMe') forMe: ElementRef;
  @ViewChild('login', { static: false }) loginmodal: any;
  @ViewChild('plainte', { static: false }) plainte: any;
  login: boolean = false;
  invalid: boolean = false;
  loaderVisible: boolean;
  userInconnu: string;
  gomrh: boolean;
  user: any;
  tokenid: any;
  produitsbis: any;
  continuer: any;
  loader: boolean;
  newDate = new Date();
  file: any;
  datas:any;
  detailsPdf:any;
  noatt: boolean = false;
  currentStepeur='assure';
  // test: boolean;
  currentDom = "activeLivraison";
  currentRel = "";
  listAgences: any = [];
  message:any;
  uuId: any;
  pointRelais: any;
  tabElementSouscripteur = [{ name: "Prénom", value: "prenom", type: "text" }, { name: "Nom", value: "nom", type: "text" }, { name: "Numéro téléphone", value: "telephone", type: "text" }, { name: "Numéro CNI", value: "profession", type: "text" }, { name: "Email", value: "email", type: "text" }, { name: "Secteur d'activité", value: "secteuractivite", type: "text" }];
  tabElementAssure = [{ name: "Prénom", value: "prenom", type: "text" }, { name: "Nom", value: "nom", type: "text" }, { name: "Numéro téléphone", value: "numeroTelephone", type: "text" }, { name: "Numéro CNI", value: "profession", type: "text" }, { name: "Email", value: "email", type: "text" }, { name: "Adresse", value: "adresse", type: "text" }];
  enteteQuestionnaireSante:any = [{name: "Nature"}, {name: "Adhérent"}, {name: "Conjoint"}, {name: "1er Enfant"}, {name: "2eme Enfant"}, {name: "3eme Enfant"}, {name: "4eme Enfant"}];
  enteteQuestionnaireSanteAs:any = [{name: "Nature"}, {name: "Réponse"}];
  tabQuestionnaireSante = [
    {question: "Avez-vous été malade au cours des 2 derniéres années ? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous de perte de poids, fiévres répétées, fatigue chronique? Précisez :"},
    {question: "Avez-vous des ganglions, des furoncies, des abcés ou des maladies de peau? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous du coeur, infections chroniques, pneumopathies, tuberculose ? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous de l'appareil digestif, foie, intestin, et anus(hémorroides, ulcére, hépatite) ? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous des glandes endocrines , de la nutrition , diabéte, goitre ? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous du sang(anémie, drépanocytose...) ? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous des reins(coliques néphrétiques, dialyses, troubles urinaires, calcul rénal...) ? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous de l'appareil génital(prostate, fibrome, kyste...) ? Précisez :"},
    {question: "Etes vous enceinte ? si oui, de combien de mois ?"},
    {question: "Avez-vous souffert ou souffrez-vous des os ou des articulations(arthrose, rhumatisme...) ? Précisez :"},
    {question: "Avez-vous souffert ou souffrez-vous des yeux, de l'ouie, du nez ? Précisez :"},
    {question: "portez-vous des lunettes ?"},
    {question: "Avez-vous déja été hospitaliser, victime d'un accident grave(fracture, traumatisme cranien, brulures, AVC) ? si oui, Précisez le motif"},
    {question: "Suivez-vous actuellement un traitement ? si oui précisez : Avez-vous des informations complémentaires sur votre état de santé susceptible de renseigner l'assureur ? Précisez :"}
  ];
  tableauQuestionnaire : any={'entete':this.enteteQuestionnaireSante};
  tableauQuestionnaireAs : any={'entete':this.enteteQuestionnaireSanteAs};

  tabRelation = ["Adulte","Enfant"];
  nbreEnfants: boolean = true;
  nbreAdultes: boolean = true;
  choose:boolean = false;
  getDevisLoader: boolean = false;
  typeFormuleAssure: any[] = [];
  formuleSante: any[] = [];
  listAssureurs: any[] = [];
  idAssureur: number = 0;
  conjSupplementaire: boolean = false;
  enfantSupplementaire: boolean = false;
  dataSimulateur: any = {
    formule: '',
    typeFormule: '',
    age: '',
    service: '',
    primeTtc: '',
    primeNette: '',
    taxe: '',
    coutPiece: '',
  };
  solo: boolean = true;
  index: number;
  updateConj: boolean = false;
  userAdule: any = {};
  nombreEnfant: number = 0;
  nombreAdulte: number = 1;
  ageFamilly = [];
  showDevice: boolean = false;
  dateNaissance: string = '';
  assureurSelected: any;
  currentAssure;
  tabQuestions=[];
  act:boolean;
  exporter: boolean = false;
  displayedColumnsFile: string[] = ['Nom', 'Téléphone', 'Adresse','Lieu', 'Spécialité'];
  tabFileBody: string[] = ['nom', 'telephone','adresse','localite','specialite'];
  enteteWithout: any[];
  enteteWithoutAs: any[];
  donnee;
  logoImage = localStorage.getItem('LogoImage');
  constructor(private subscriptionService: SubscriptionService,
    public router: Router,
    private modalService: NgbModal,
    private utilsService: UtilsService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private genererPdfService: GenererPdfService, private exportService: ExportService,private datePipe: DatePipe) {

    let time = new Date().getTime();
    let pro = new Date().getTime() + 123890989;
    this.numeroSous = "SN" + time + "SC";
    this.refPro = "SN" + pro + "PR";

    this.datas = this.route.snapshot.params['data'];
    this.route.queryParams.subscribe(params => {
      this.datas = params.data;
      this.datas = 7;
    });
    this.getTypeFormuleAssure();
    this.getFormuleSante();
  }

  ngOnInit(): void {
    //this.test();
    this.fireFunctions();
    this.getPaymentMethods();
    this.getProducts();
    this.getAssureur();
    this.getAgences();
    this.user = JSON.parse(localStorage.getItem('microFinance'));
    
    this.genererPdfService.genererPdf(this.datas).subscribe((resp) => {
      this.detailsPdf = resp['data'];
      let id_ass = this.detailsPdf.assureur.id;
      let ass = this.detailsPdf.assureur;
     // this.getprix(id_ass, ass);
      this.getSouscriptions();
    });
    this.getQuestionnaire();
    this.getQuestionnaireAs();
  }

  scroll() {
    document.querySelector('#contact').scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
  getTypeFamille(typefamille){
    
    if(typefamille == 1){
      this.act = true;
    }else{
      this.act = false;
    }
  }
  get idAssurance() {
    return this._idAssurance;
  }

  @Input()
  set idAssurance(id) {
    this._idAssurance = id;
    this.fireFunctions();
  }

  get typeAssurance() {
    return this._typeAssurance;
  }

  @Input() set typeAssurance(type: string) {
    this._typeAssurance = type;
  }
  choixmrh(type?: string) {
    this.scroll();
    this.modalService.dismissAll(this.modasign);
    let token = JSON.parse(localStorage.getItem('microFinance'));
    if (token) {
      this.login = true;
      if(type === 'pack'){
        this.choose = false;
      }else if(type === 'simulation'){
        this.choose = true;
      }
      window.scrollTo(500, 500);
    } else {
      this.modalService.open(this.modalog, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
        if (result == 'subscribe') {

        }
      }, (reason) => {
      });
    }
  }

  domicile() {
    this.pointRelais = null;
    this.currentDom = "activeLivraison";
    this.currentRel = "";
    this.paiment = true;
    this.souscriptionForm.get('livraison').setValue('Domicile');
    this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
    this.souscriptionForm.get('moyenDePaiement').setValue(this.paymentMethods[0]);
  }
  relais() {
    this.currentRel = "activeLivraison";
    this.currentDom = "";
    this.souscriptionForm.get('livraison').setValue('Relais');
    this.paiment = true;
    this.paymentMethods = this.tmpPaymentMethods;
    this.souscriptionForm.get('moyenDePaiement').setValue(this.paymentMethods[0]);
    this.subscriptionService.getPointRelais(this.currentAssureur['id']).subscribe((resp) => {
      this.pointRelais = resp['data'];
    });
  }

  inscrire() {
    this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modasign, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {
    });
  }
  convertStringToNumber(val) {
    return parseFloat(val);
  }
  inscrireutil() {
    if (this.inscriptionForm.valid) {
      let data = {
        "telephone": this.inscriptionForm.get('tel').value,
        "email": this.inscriptionForm.get('email').value,
        "type": 5 + ""
      }
      this.loaderVisible = true;
      this.subscriptionService.sign(data).subscribe((resp) => {
        if (resp["responseCode"] == 200) {
          this.utilsService.showToastSuccess("Les informations d'activation de votre compte vous ont été envoyées par email et/ou SMS.", "Inscription  réussie");
          this.modalService.dismissAll(this.modasign);
          this.loaderVisible = false;
          this.inscriptionForm.reset();
        }
        else {
          this.loaderVisible = false;
          this.utilsService.showToastError(resp['message'], "Echec");
        }
        this.loaderVisible = false;
      }, error => {
        this.utilsService.showToastError(error.error.errors[0].message, "Echec");
        this.loaderVisible = false;
      });

    } else {
      this.checkValidity(this.inscriptionForm);
    }
  }

  checkValidity(g: FormGroup) {
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsDirty();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).markAsTouched();
    });
    Object.keys(g.controls).forEach(key => {
      g.get(key).updateValueAndValidity();
    });
  }


  lanceforgot() {
    this.modalService.dismissAll(this.modalog);
    this.modalService.open(this.modaforgot, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {
    });
  }
  forgot() {
    if (this.forgotForm.valid) {
      let data = {
        "email": this.forgotForm.get('email').value,
      }
      this.loaderVisible = true;
      this.subscriptionService.reset(data).subscribe((resp) => {
        if (resp["responseCode"] = 200) {
          this.utilsService.showToastSuccess("Un mail vous a été envoyé pour réinitialiser votre mot de passe", "");
          this.modalService.dismissAll(this.modasign);
        }
        else {
          this.utilsService.showToastError(resp['message'], "Echec");
        }
        this.loaderVisible = false;
      }, error => {
        this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
        this.loaderVisible = false;
      })
    } else {
      this.checkValidity(this.forgotForm);
    }
  }
  connecter() {
    this.userInconnu = "";
    if (this.loginform.valid) {
      this.loaderVisible = true;
      let data = {
        ... this.loginform.value
      }
      this.subscriptionService.login(data)
        .subscribe((res) => {
          if (res['data']) {
            const tokenObj = res['data'];
            //.log('tokenObj', tokenObj);
            let infos = {
              id: tokenObj.id,
              matricule: tokenObj.matricule,
              firstName: tokenObj.firstName,
              lastName: tokenObj.lastName,
              email: tokenObj.email,
              telephone: tokenObj.telephone,
              secteuractivite: tokenObj.secteuractivite,
              profession: tokenObj.profession,
              adresse: tokenObj.adresse,
              genre: tokenObj.genre,
              dateNaissance: tokenObj.dateNaissance
            }
            if (res['data']['access_token']) {
              let detail = {
                id_token: tokenObj.access_token,
                refresh_token: tokenObj.refresh_token,
                // roles: tokenObj.roles,
                infos: infos,
                authorities: tokenObj.authorities
              };
              localStorage.setItem('microFinance', JSON.stringify(detail));
              localStorage.setItem('microFinanceBo', JSON.stringify(detail));
              this.accountService.sendAccount(JSON.stringify(infos));
              this.user = detail;
              this.login = true;
              this.fireFunctions();
              this.getPaymentMethods();
              this.getProducts();
              this.getAssureur();
              this.getAgences();
              this.utilsService.showToastSuccess("Effectuée avec succés", "Connexion");
              this.scroll();
              this.modalService.dismissAll(this.modalog);
            }
            else {
              this.choixmrh();
              this.userInconnu = res['message'];
              this.loaderVisible = false;
            }
          } else {
            this.loaderVisible = false;
            this.userInconnu = res['message'];
          }
          this.loaderVisible = false;
        }, (err) => {
          this.userInconnu = err['error']['message'];
          this.loaderVisible = false;
        })
    } else {
      this.checkValidity(this.loginform);
    }

  }

  addbenef() {
    var nombreEnfant:any = this.souscriptionForm.get('nombreAssure').get("nombreEnfant").value;
    var nombreAdulte = this.souscriptionForm.get('nombreAssure').get("nombreAdulte").value;
    var type = this.souscriptionForm.get('beneficiaire').get("type").value;
    if (this.souscriptionForm.get('beneficiaire').valid) {
      if(type=='Enfant') {
        this.checkEnfant++;
      }else {
        this.checkAdulte++;
      }
      if(this.checkEnfant >= nombreEnfant ) {
        this.nbreEnfants = false;
        let i = this.tabRelation.indexOf('Enfant');
        this.tabRelation.splice(i,1);
      }
      if(this.checkAdulte >= nombreAdulte ) {
        this.nbreAdultes = false;
        let i = this.tabRelation.indexOf('Adulte');
        this.tabRelation.splice(i,1);
      }
      this.invalid = false;
      let body = {
        ...this.souscriptionForm.get('beneficiaire').value
      }
      this.beneficiares.push(body);
      // this.beneficiaresEnfant.push(body);
      this.souscriptionForm.get('beneficiaire').reset();
      this.beneficiaryChoosen = false;
    } else {
      this.invalid = true;
      let body = {
        ...this.souscriptionForm.get('beneficiaire').value
      }
    }
  }
  updateConjoint(listAssures){
    let body = {
      ...this.souscriptionForm.get('beneficiaire').value
    }
    listAssures.splice(this.index, 1, body);
    this.souscriptionForm.get('beneficiaire').reset();
    this.updateConj = false;
    this.conjointChoosen = false;
    // 
    this.modalService.dismissAll(this.modalAssure);
    this.dateNaissance = '';
  }
  addPackAssure(forControlName: string) {
    this.souscriptionForm.get('assure').get('questionnaire').setValue(JSON.stringify(this.tableauQuestionnaire['body']));    
    this.nombreEnfant = Number(this.souscriptionForm.get('nombreAssure').get("nombreEnfant").value);
    this.nombreAdulte = Number(this.souscriptionForm.get('nombreAssure').get("nombreAdulte").value);

    // var type = this.souscriptionForm.get('beneficiaire').get("type").value;
    if (this.souscriptionForm.get(forControlName).valid) {
      this.invalid = false;
      let body = this.souscriptionForm.get(forControlName).value
      forControlName === 'assure' ? body.type = 'adulte': '';
      body.type === 'adulte' ? this.checkAdulte ++ : this.checkEnfant ++;
      this.beneficiares.push(body);
      // this.ageFamilly.push(body.age);
      if(forControlName !== 'assure'){
        this.souscriptionForm.get(forControlName).reset();
      }
      this.conjointChoosen = false;
      this.modalService.dismissAll(this.modalAssure);
    } else {
      this.invalid = true;
      let body = {
        ...this.souscriptionForm.get(forControlName).value
      }
    }
  }
  addConjoint(forControlName: string) {
     if(!this.solo){
       this.nombreEnfant = Number(this.souscriptionForm.get('AxASimulateur').get("nombreEnfant").value);
       this.nombreAdulte = Number(this.souscriptionForm.get('AxASimulateur').get("nombreAdulte").value);
     }
    // var type = this.souscriptionForm.get('beneficiaire').get("type").value;
    if (this.souscriptionForm.get(forControlName).valid) {
      this.invalid = false;
      let body = {
        ...this.souscriptionForm.get(forControlName).value
      }
      forControlName === 'assure' ? body.type = 'adulte': '';
      body.type === 'adulte' ? this.checkAdulte ++ : this.checkEnfant ++;
      this.conjoits.push(body);
      this.ageFamilly.push(body.age);
      if(forControlName !== 'assure'){
        this.souscriptionForm.get(forControlName).reset();
      }
      this.conjointChoosen = false;
      this.modalService.dismissAll(this.modalAssure);
    } else {
      this.invalid = true;
      let body = {
        ...this.souscriptionForm.get(forControlName).value
      }
    }
  }
  updateEnfantSimul() {
    if (this.souscriptionForm.get('beneficiaireEnfantAxa').valid) {
      this.invalid = false;
      let body = {
        ...this.souscriptionForm.get('beneficiaireEnfantAxa').value
      }
      this.enfants.splice(this.index, 1, body);
      this.souscriptionForm.get('beneficiaireEnfantAxa').reset();
      this.enfantChoosen = false;
      this.updateConj = false;

    } else {
      this.invalid = true;
      let body = {
        ...this.souscriptionForm.get('beneficiaireEnfantAxa').value
      }
    }
  }
  addEnfantSimul() {
    if (this.souscriptionForm.get('beneficiaireEnfantAxa').valid) {
      this.invalid = false;
      let body = {
        ...this.souscriptionForm.get('beneficiaireEnfantAxa').value
      }
      body.type = 'enfant';
      this.enfants.push(body);
      // this.beneficiaresEnfant.push(body);
      this.souscriptionForm.get('beneficiaireEnfantAxa').reset();
      this.enfantChoosen = false;
      if(this.enfants.length === 3 && !this.conjSupplementaire){

      }
    } else {
      this.invalid = true;
      let body = {
        ...this.souscriptionForm.get('beneficiaireEnfantAxa').value
      }
    }
  }
  addAttachment(e: any): void {
    
    let typeFile = [".PDF", ".pdf", ".DOC", ".docm", ".doc", ".DOCX", ".docx"];
    let index = e.target.files[0].name.lastIndexOf(".");
    let strsubstring = e.target.files[0].name.substring(index, e.target.files[0].name.length);
    if (typeFile.indexOf(strsubstring) == -1) {
      this.utilsService.showToastError("Veuillez charger un fichier au format PDF ou DOC", "Erreur format");
      return;
    }
    this.loadingFiles = 0;
    if (e && e.target.files) {
      this.file = e.target.files[0];
      this.loadingFiles = e.target.files.length;
      for (let i = 0; i < this.loadingFiles; i++) {
        let file = e.target.files[i];
        let fileName = file.name;
        let attachment: IAttachment = {
          originalFilename: fileName,
          contentType: file.type,
          sizeInBytes: file.size,
          extension: this.getExtension(fileName),
          processing: true,
        };
        this.noatt = false;
        this.attachments.push(attachment);
        attachment.processing = false;
      }

    } else {
      this.noatt = true;
    }
    e.target.value = '';
  }
  getExtension(fileName: string): string {
    return fileName.substring(fileName.lastIndexOf('.'));
  }
  fireFunctions() {
    this.user = JSON.parse(localStorage.getItem('microFinance'));
    if (this.user) {
      this.tokenid = this.user.id_token;
      this.paymentForm.returnUrl = environment.baseApiUrl + 'contrats/save-contrat';
    }

  }

  retour() {
    this.login = true;
    window.scroll(500, 500);
  }
  retourDevice() {
    this.showDevice= false;
    window.scroll(500, 500);
  }
  getAssureur() {
    this.subscriptionService.reqPost(apiUrls.assureurByProduit, { 'typeProduit': this.datas }).subscribe((resp) => {
      this.assureurs = resp['data'];
      
      
      
      this.currentAssure = this.assureurs[0].assureur.id;
      let index = 0;
      for (let ass of this.assureurs) {
        for (let form of ass.assureur.devis) {
          form.formule.nom = form.formule.nom.toLowerCase();
          form.formule['image'] = this.listImages[index];
          index++;
        }
        if (ass.assureur.code == 'AXA')
          ass.assureur['img'] = "../../../../../assets/images/axa.png";
      }
    });

  }
  devis() {
    this.enfant=0;
    this.conjoint=0;
    if (this.attachments.length > 0) {
      for(let ben of this.beneficiares){
        if(ben.type=='Enfant')
          this.enfant++;
        else this.conjoint++;  
      }
      this.modalService.open(this.contentResultass, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
        if (result == 'subscribe')
          this.wizard.goToNextStep();
        window.scrollTo(500, 500);
      }, (reason) => {
      }
      );
    } else {
      window.scrollTo(500, 500);
      this.noatt = true;
    }
  }
  devisNextStep(){
    this.showDevice = false;
    this.wizard.goToNextStep();
    window.scrollTo(500, 500);
  }
  devisSimulateur() {
    this.modalService.open(this.simulateurDevis, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe')
        this.wizard.goToNextStep();
        window.scrollTo(500, 500);
    }, (reason) => {
    }
    );
  }
  convertStringToArray(data) {
    let value = data.split('->');
    let values = [];
    for (let val of value) {
      if (val != "") {
        val.replace('\n', '');
        values.push({ nom: val });
      }
    }
    return values;
  }
  getPrestation(data, type) {
    if (type == 'avantage') {
      let value = data.split('->');
      let values = [];
      for (let val of value) {
        if (val != "") {
          val.replace('\n', '');
          values.push({ nom: val });
        }
      }
      this.prestations = values;
      this.currentTitle = "Liste des avantages";
      

    }
    else {
      this.currentTitle = "Liste des prestations";
      this.prestations = data;
    };
      this.modalService.open(this.prestation, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
        if (result == 'subscribe') {
  
        }
      }, (reason) => {
      }
      );
  }
  getPrestataire(data) {
    for (let val of data) {
      this.prestataires.push(val.prestataire);
    }
    this.modalService.open(this.prestataire, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {
    }
    );
  }

  setRequiredField() {
    this.souscripteur.get('profession').setValidators(Validators.required);
  }

  get souscripteur() {
    return this.souscriptionForm.get('souscripteur')
  }

  saveDataSimulation() {
    this.sendingRequest = true;
    let cont;
    cont = {
      ...this.souscriptionForm.get('contrat').value
    };

    cont = JSON.stringify(cont);
    // this.conjoits.push(this.userAdule);
    // let assures = JSON.stringify([...this.conjoits,...this.enfants])
    let assures = JSON.stringify(this.conjoits);
    let dataFiles = new FormData();
    this.loader = true;
    // if(this.solo){

    // }
    // dataFiles.append('fichierAssure', this.file);
    // moment().diff(this.simulationVoyageForm.get('dateNaissance').value, 'years');
    let age;
    if(this.solo){
      // @ts-ignore
      age = new Date().getFullYear() - this.souscriptionForm.get('AxASimulateur').get('age').value.getFullYear();
    }else{
      age = 50;
    }
    dataFiles.append('livraison', this.souscriptionForm.get('livraison').value);
    dataFiles.append('formule', this.souscriptionForm.get('AxASimulateur').value.formule);
    dataFiles.append('typeFormule', this.souscriptionForm.get('AxASimulateur').value.typeFormule);
    dataFiles.append('age', ''+age);
    // @ts-ignore
    dataFiles.append('canal', this.souscriptionForm.get('canal').value);
    dataFiles.append('moyenDePaiement', this.souscriptionForm.get('moyenDePaiement').value);
    dataFiles.append('assureur', ''+this.idAssureur);
    dataFiles.append('montant', this.dataSimulateur.primeTtc);
    dataFiles.append('assure', assures);
    dataFiles.append('duree', '1');
    // dataFiles.append('nombreEnfant', this.souscriptionForm.get('AxASimulateur').get('nombreEnfant').value);
    // dataFiles.append('nombreAdulte', this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').value);
    dataFiles.append('nombreEnfant', ''+this.nombreEnfant);
    dataFiles.append('nombreAdulte', ''+this.nombreAdulte);
    dataFiles.append('pack', 'simulateur');
    dataFiles.append('pointRelais', this.souscriptionForm.get('pointRelaisControl').value);
    dataFiles.append('adresseLivraison', this.souscriptionForm.get('adresseLivraison').value);
    dataFiles.append('date_effet', this.datePipe.transform(new Date(),"dd-MM-yyyy"));
    dataFiles.append('prime', JSON.stringify(this.assureurSelected));
    
    
    this.subscriptionService.saveSouscription(dataFiles).subscribe((resp) => {
      this.sendingRequest = false;
      if (resp['responseCode'] == 200) {
        let type = '&meansType=CARTE_OTHER';
        // this.initPaymentForm.montant = this.paymentForm.amount;
        this.initPaymentForm.montant = this.dataSimulateur.primeTtc;
        this.initPaymentForm.requestId = this.dateTime;
        this.initPaymentForm.numeroSouscription = resp['data'].numeroSouscription;
        this.initPaymentForm.status = "PENDING";
        this.subscriptionService.initPaiementBack(this.initPaymentForm).subscribe((rep) => {
          if (rep['responseCode'] != 200) {
            this.utilsService.showToastError(resp['message'], "Echec");
          }
        }, error => {
          this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
          this.sendingRequest = false;
        });
        this.uuId = resp['data'].id;
        let email = this.user.infos.email;
        this.message ="Bonjour Madame/Monsieur " + " , je vous prie de trouver ci-joint le lien de votre devis de votre souscription "+
            " " +environment.frontWebUrl+"generer-pdfSante/"+this.uuId;
        let detailsEnvoi = {
          'email': email,
          'message': this.message
        }
        this.subscriptionService.sendMail(detailsEnvoi).subscribe((resp) => {
          this.sendingRequest = true;
          if (resp['responseCode'] == 200) {

          }
        });
        if (this.paymentMethod == 'wallet') type = '&meansType=WALLET';
        let hash = this.paymentForm.timestamp + '' + this.paymentForm.amount + '' + this.paymentForm.requestId + 'XOF' + this.key;
        this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);
        // souscripteur
        this.paymentForm.customerFirstName = this.souscriptionForm.get('assure').get('prenom').value;
        this.paymentForm.customerLastName = this.souscriptionForm.get('assure').get('nom').value;
        this.paymentForm.customerPhone = this.souscriptionForm.get('assure').get('numeroTelephone').value;
        // this.paymentForm.customerCountry = 'SENEGAL';
        this.paymentForm.customerCountry = 'SN';
        this.paymentForm.customerCity = 'DAKAR';
        this.paymentForm.customerState = 'DAKAR';
        this.paymentForm.customerZipCode = '25455';
        this.paymentForm.customerAddress = 'DAKAR';
        this.paymentForm.customerEmail = this.souscriptionForm.get('assure').get('email').value;
        this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {
          if (respApg['code'] == '0000') {
            this.sendingRequest = false;
            this.utilsService.showToastSuccess("Votre souscription est en cours de traitement. Vous allez être redirigé vers la page de paiement", "Souscription en cours...")
            setTimeout(() => {
              window.open(respApg['paymentUrl'] + type, '_blank');
              this.wizard.goToStep(0);
              this.conjoits = [];
              this.souscriptionForm.reset();
            }, 2000);
          } else {
            this.utilsService.showToastError(respApg['message'], "Echec");
            this.sendingRequest = false;
            // this.wizard.goToStep(0);
            // window.scrollTo(500, 500);
          }
        }, error => {
          this.utilsService.showToastError('Impossible de contacter le serveur', "Echec");
          this.sendingRequest = false;
          this.wizard.goToStep(1);
          window.scrollTo(500, 500);
        });
      } else {
        this.utilsService.showToastError(resp['message'], resp['message']);
        this.sendingRequest = false;
        this.wizard.goToStep(1);
        window.scrollTo(500, 500);
      }
    }, error => {
      let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
      if (error && error.error) {
        message = error.error.errors[0].message;
      }
      this.utilsService.showToastError(message, "Echec");
      window.scrollTo(500, 500);
      this.sendingRequest = false;
    });
  }
  saveData() {
    this.sendingRequest = true;
    let cont;
    cont = {
      ...this.souscriptionForm.get('contrat').value
    };
    cont = JSON.stringify(cont);

    let ben = JSON.stringify(this.beneficiares)
    let dataFiles = new FormData();
    this.loader = true;



    let data = {
      ...this.souscriptionForm.get('assure').value,
      ...this.souscriptionForm.get('souscripteur').value,
      "pack": 'sante',
      "assure": this.souscriptionForm.get('assure').value,
      "souscripteur": this.souscriptionForm.get('souscripteur').value,
      "assureur": this.souscriptionForm.get('assureur').value,
      "canal": this.souscriptionForm.get('canal').value,
      "moyenDePaiement": this.souscriptionForm.get('moyenDePaiement').value,
      // "prix":this.souscriptionForm.get('montant').value,
      // "garanties": this.garantie,
      "pointRelais": this.souscriptionForm.get('pointRelaisControl').value,
      "adresseLivraison": this.souscriptionForm.get('adresseLivraison').value,
      "date_effet": this.souscriptionForm.get('contrat').get('debut').value,
      "livraison":this.souscriptionForm.get('livraison').value,
      // "isSubscriber":this.souscriptionForm.get('isSubscriber').value,
      "formule": this.souscriptionForm.get('formule').value,
      "produit":this.souscriptionForm.get('produit').value,
      "montant":this.souscriptionForm.get('montant').value,
      "duree": this.souscriptionForm.get('duree').value,
      "nombreEnfant":0,
      "nombreAdulte":0,



  }

   // dataFiles.append('fichierAssure', this.file);
    dataFiles.append('livraison', this.souscriptionForm.get('livraison').value);
    dataFiles.append('formule', this.souscriptionForm.get('formule').value);
    // @ts-ignore
    dataFiles.append('canal', this.souscriptionForm.get('canal').value);
    this.souscriptionForm.get('moyenDePaiement').setValue('OM');
    dataFiles.append('moyenDePaiement', this.souscriptionForm.get('moyenDePaiement').value);
    dataFiles.append('assureur', this.souscriptionForm.get('assureur').value);
    dataFiles.append('produit', this.souscriptionForm.get('produit').value);
    dataFiles.append('montant', this.souscriptionForm.get('montant').value);
    dataFiles.append('assure', ben);
    dataFiles.append('duree', this.souscriptionForm.get('duree').value);
    dataFiles.append('pointRelais', this.souscriptionForm.get('pointRelaisControl').value);
    dataFiles.append('adresseLivraison', this.souscriptionForm.get('adresseLivraison').value);
    dataFiles.append('contrat', cont);
    dataFiles.append('nombreEnfant', '0');
    dataFiles.append('nombreAdulte', '0');
    dataFiles.append('date_effet', this.datePipe.transform(this.souscriptionForm.get('contrat').get('debut').value,"yyyy-MM-dd"));

    
    this.subscriptionService.saveSouscription(dataFiles).subscribe((resp) => {
      this.sendingRequest = false;
      if (resp['responseCode'] == 200) {
       this.utilsService.showToastSuccess("Souscription effectuée avec succés, le devis sera provisoire sous réserve de l'étude du dossier", "Success");
        this.souscriptionForm.reset();
        this.wizard.goToStep(0);

        let type = '&meansType=CARTE_OTHER';
        this.initPaymentForm.montant = this.paymentForm.amount;
        this.initPaymentForm.requestId = this.dateTime;
        this.initPaymentForm.numeroSouscription = resp['data'].numeroSouscription;
        this.initPaymentForm.status = "PENDING"
      
     
        this.subscriptionService.initPaiementBack(this.initPaymentForm).subscribe((rep) => {
          if (rep['responseCode'] != 200) {
            this.utilsService.showToastError(resp['message'], "Echec");
          }
        }, error => {
          this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
          this.sendingRequest = false;
        });
        this.uuId = resp['data'].id;
        let email = this.user.infos.email;
        this.message ="Bonjour Madame/Monsieur " + " , je vous prie de trouver ci-joint le lien de votre devis de votre souscription "+
            " " +environment.frontWebUrl+"generer-pdfSante/"+this.uuId;
        this.message ="Bonjour Madame/Monsieur " + " , votre souscription a été bien prise en compte. Votre devis sera provisoire sous réserve de l'étude du dossier";
        let detailsEnvoi = {
          'email': email,
          'message': this.message
        }
        this.subscriptionService.sendMail(detailsEnvoi).subscribe((resp) => {
          this.sendingRequest = true;
          if (resp['responseCode'] == 200) {
           
            
          }
        });
        if (this.paymentMethod == 'wallet') type = '&meansType=WALLET';
        let hash = this.paymentForm.timestamp + '' + this.paymentForm.amount + '' + this.paymentForm.requestId + 'XOF' + this.key;
        this.paymentForm.auth = CryptoJS.MD5(hash).toString(CryptoJS.enc.Hex);

        this.paymentForm.customerFirstName = this.souscriptionForm.get('souscripteur').get('prenom').value;
        this.paymentForm.customerLastName = this.souscriptionForm.get('souscripteur').get('nom').value;
        this.paymentForm.customerPhone = this.souscriptionForm.get('souscripteur').get('telephone').value;
        // this.paymentForm.customerCountry = 'SENEGAL';
        this.paymentForm.customerCountry = 'SN';
        this.paymentForm.customerCity = 'DAKAR';
        this.paymentForm.customerState = 'DAKAR';
        this.paymentForm.customerZipCode = '25455';
        this.paymentForm.customerAddress = 'DAKAR';
        this.paymentForm.customerEmail = this.souscriptionForm.get('souscripteur').get('email').value;
        this.subscriptionService.initiateOnlinePayment(this.paymentForm).subscribe((respApg) => {
          if (respApg['code'] == '0000') {
            this.sendingRequest = false;
            this.utilsService.showToastSuccess("Votre souscription est en cours de traitement. Vous allez être redirigé vers la page de paiement", "Souscription en cours...")
            setTimeout(() => {
              window.open(respApg['paymentUrl'] + type, '_blank');
              this.wizard.goToStep(1);
              // this.checkAdulte = 0;
              // this.checkEnfant = 0;
              // this.nombreEnfant = 0;
              // this.nombreAdulte = 1;
              // this.conjoits = [];
            }, 2000);
          } else {
            this.utilsService.showToastError(respApg['message'], "Echec");
            this.sendingRequest = false;
            this.wizard.goToStep(1);
            window.scrollTo(500, 500);
          }
        }, error => {
          this.utilsService.showToastError('Impossible de contacter le serveur', "Echec");
          this.sendingRequest = false;
          this.wizard.goToStep(1);
          window.scrollTo(500, 500);
        });
      } else {
        this.utilsService.showToastError(resp['message'], resp['message']);
        this.sendingRequest = false;
        this.wizard.goToStep(1);
        window.scrollTo(500, 500);
      }
    }, error => {
      let message = "Echec souscription. Veuillez vérifier vos informations et recommencer SVP";
      if (error && error.error) {
        message = error.error.errors[0].message;
      }
      this.utilsService.showToastError(message, "Echec");
      window.scrollTo(500, 500);
      this.sendingRequest = false;
    });
  }
  delete(index) {
    if(this.beneficiares[index] === 'Adulte'){
      this.checkAdulte --;
      !this.tabRelation.includes('Adulte') ? this.tabRelation.unshift('Adulte') : '';
      // this.tabRelation.unshift('Adulte');
    }else{
      this.checkEnfant --;
      !this.tabRelation.includes('Enfant') ? this.tabRelation.push('Enfant') : '';
      // this.tabRelation.push('Enfant');
    }
    this.beneficiares.splice(index, 1);
    this.beneficiaryChoosen = false;
  }
  onDateSelect(event, groupName, controlName) {
    // {year: 2021, month: 12, day: 15}
    
    if (event){
      this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
      
    }
  }

  onDateSelect3(event, groupName, controlName) {
    if (event) {
      this.souscriptionForm.get('' + groupName).get('' + controlName).setValue(new Date(event.year + '/' + event.month + '/' + event.day));
      if (groupName == 'assure') {
        let age = moment().diff(this.souscriptionForm.get('assure').get('dateNaissance').value, 'years');
        if (age == 0)
          age = 1;
        this.age = age;
      }
    }

  }
  getPaymentMethods() {
    this.subscriptionService.getPaymentMethods().subscribe((resp) => {
      
      this.paymentMethods = resp['data'];
      this.route.queryParams.subscribe(params => {
        this.donnee = params.donnee;
        
        if(this.donnee){
         this.souscrire();
        }
      }); 
      for (let pay of this.paymentMethods) {
        if (pay.code == 'paiement_agence'){
          // this.paymentMethods.splice(this.paymentMethods.indexOf(pay),1);
          pay['logo'] = "assets/images/payment.jpg";
        } 
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/card.jpg";
        else if (pay.code == 'OM') pay['logo'] = "assets/images/om.jpg";
        else if (pay.code == 'FM') pay['logo'] = "assets/images/fm.png";
        else if (pay.code == 'WM') pay['logo'] = "assets/images/wm1.jpg";
        else if (pay.code == 'paiement_agence') pay['logo'] = "assets/images/payment.jpg";
        else if (pay.code == 'paiement_carte') pay['logo'] = "assets/images/wallet2.png";
      }
      this.tmpPaymentMethods = this.paymentMethods;
      this.paymentMethods = this.tmpPaymentMethods.filter(el=> el.code !== 'paiement_agence');
      this.souscriptionForm.get('moyenDePaiement').setValue(this.paymentMethods[0]);
    });
  }

  getProducts() {
    this.subscriptionService.getProducts().subscribe((resp) => {

      let produits = resp['data'];
      produits.forEach(element => {
      });
    })
    this.subscriptionService.getProduct().subscribe((resp) => {
      this.produitsbis = resp['data'];

    });
  }

  selectPayment(pay) {
    this.souscriptionForm.get('moyenDePaiement').setValue(pay.code == this.souscriptionForm.get('moyenDePaiement').value ? null : pay.code);
    this.paymentMethod = pay.nom.toLowerCase();
  }

  getAgences() {
    this.subscriptionService.getListAgences().subscribe((resp) => {
      this.listAgences = resp['data'];
    });
  }

  selectFormule(ass, pack) {
    this.currentAssureur = ass.assureur;
    this.currentAssureur['duree'] = pack.duree.duree;
    this.currentAssureur['dureeDisplay'] = pack.duree.displayMe;
    this.souscriptionForm.get('assureur').setValue(ass.assureur.id);
    this.souscriptionForm.get('formule').setValue(pack.formule.id);
    this.souscriptionForm.get('duree').setValue(pack.duree.id);
    this.souscriptionForm.get('montant').setValue(pack.prix);
    this.souscriptionForm.get('produit').setValue(pack.produit.id);
    this.paymentForm.amount = pack.prix;
    this.currentPack = {
      "assureur": ass.assureur.nom,
      "duree": pack.duree.displayMe,
      "prix": pack.prix,
      "produit": pack.produit.nom,
      "formule": pack.formule.nom,
      "prestations": pack.produit.prestations,
      "avantages": this.convertStringToArray(pack.formule.description)
    };
    let user = this.user.infos;
    this.userAdule = user;
    // this.forMe.nativeElement.click();
    this.currentSouscripteur = "Pour moi-même";
    this.souscriptionForm.get('souscripteur').get('nom').setValue(user.lastName);
    this.souscriptionForm.get('souscripteur').get('prenom').setValue(user.firstName);
    this.souscriptionForm.get('souscripteur').get('email').setValue(user.email);
    this.souscriptionForm.get('souscripteur').get('telephone').setValue(user.telephone);
    this.souscriptionForm.get('souscripteur').get('secteuractivite').setValue(user.secteuractivite);
    this.souscriptionForm.get('souscripteur').get('profession').setValue(user.profession);
    this.souscriptionForm.get('souscripteur').get('adresse').setValue(user.adresse);
    this.souscriptionForm.get('souscripteur').get('genre').setValue(user.genre);
    window.scrollTo(500, 500);
    this.wizard.goToNextStep();
  }

  enterStep(event) {
    if (this.assure == 1) {
      // @ts-ignore
      this.souscriptionForm.get('assure').patchValue(this.souscriptionForm.get('souscripteur').value);
      this.souscriptionForm.get('assure').get('numeroTelephone').setValue(this.souscriptionForm.get('souscripteur').get('telephone').value);
      let body = {
        ...this.souscriptionForm.get('assure').value
      }

      if (this.beneficiares.indexOf(body) == -1)
        this.beneficiares.push(body);
    } 
    // else this.souscriptionForm.get('assure').reset();
  }

  setAssureSante(type,forControlName: string){
    if(this.souscriptionForm.get(forControlName).invalid){
      this.invalid = true;
      let body = {
        ...this.souscriptionForm.get(forControlName).value
    }
  }else{
       this.currentStepeur='questionnaire';
  }

  }
  setAssure(event) {
    
    this.assure = event.target.value;
    let user = this.user.infos;
    if (event.target.value == 'enfant'){
      this.champsObligatoire = true
      this.souscriptionForm.get('beneficiaire').get("email").clearValidators();
      this.souscriptionForm.get('beneficiaire').get("email").updateValueAndValidity();
      this.souscriptionForm.get('beneficiaire').get("profession").clearValidators();
      this.souscriptionForm.get('beneficiaire').get("profession").updateValueAndValidity();
      this.souscriptionForm.get('beneficiaire').get("secteuractivite").clearValidators();
      this.souscriptionForm.get('beneficiaire').get("secteuractivite").updateValueAndValidity();
      this.souscriptionForm.get('beneficiaire').get("numeroTelephone").clearValidators();
      this.souscriptionForm.get('beneficiaire').get("numeroTelephone").updateValueAndValidity();
    }
    if (event.target.value == 1) {
      this.souscriptionForm.get('isSubscriber').setValue(true);
      this.currentSouscripteur = "Pour moi-même";
      this.souscriptionForm.get('assure').get('nom').setValue(user.lastName);
      this.souscriptionForm.get('assure').get('prenom').setValue(user.firstName);
      this.souscriptionForm.get('assure').get('email').setValue(user.email);
      this.souscriptionForm.get('assure').get('numeroTelephone').setValue(user.telephone);
      this.souscriptionForm.get('assure').get('adresse').setValue(user.adresse);
      this.souscriptionForm.get('assure').get('genre').setValue(user.genre);
      this.souscriptionForm.get('assure').get('profession').setValue(user.profession);
      this.souscriptionForm.get('assure').get('secteuractivite').setValue(user.secteuractivite);
      this.souscriptionForm.get('assure').get('dateNaissance').setValue(
       this.solo ? this.souscriptionForm.get('AxASimulateur').get('age').value : user.dateNaissance);
      this.dateNaissance = new Date(user?.dateNaissance).getDate()+'/'
                            + (new Date(user?.dateNaissance).getMonth() + 1)+'/'
                            + new Date(user?.dateNaissance).getFullYear();
      // this.conjoits.push(user);
    }
    else if(event.target.value == 2){
      this.souscriptionForm.get('isSubscriber').setValue(false);
      this.currentSouscripteur = "Pour un tiers";
      this.souscriptionForm.get('assure').get('nom').setValue('');
      this.souscriptionForm.get('assure').get('prenom').setValue('');
      this.souscriptionForm.get('assure').get('email').setValue('');
      this.souscriptionForm.get('assure').get('numeroTelephone').setValue('');
      this.souscriptionForm.get('assure').get('adresse').setValue('');
      this.souscriptionForm.get('assure').get('genre').setValue('');
      this.souscriptionForm.get('assure').get('profession').setValue('');
      this.souscriptionForm.get('assure').get('secteuractivite').setValue('');
      this.souscriptionForm.get('assure').get('dateNaissance').setValue('');
      this.dateNaissance = '';
    }
    // this.enterAssure()
  }
  // setAssure(n) {
  //   this.assure = n;
  //   let user = this.user.infos;
  //   if (n == 1) {
  //     this.souscriptionForm.get('isSubscriber').setValue(true);
  //     this.currentSouscripteur = "Pour moi-même";
  //     this.souscriptionForm.get('souscripteur').get('nom').setValue(user.lastName);
  //     this.souscriptionForm.get('souscripteur').get('prenom').setValue(user.firstName);
  //     this.souscriptionForm.get('souscripteur').get('email').setValue(user.email);
  //     this.souscriptionForm.get('souscripteur').get('telephone').setValue(user.telephone);
  //     this.souscriptionForm.get('souscripteur').get('adresse').setValue(user.adresse);
  //     this.souscriptionForm.get('souscripteur').get('genre').setValue(user.genre);
  //     this.souscriptionForm.get('souscripteur').get('profession').setValue(user.profession);
  //     this.souscriptionForm.get('souscripteur').get('secteuractivite').setValue(user.secteuractivite);
  //     this.souscriptionForm.get('souscripteur').get('dateNaissance').setValue(user.dateNaissance);
  //     // this.conjoits.push(user);
      
  //   }
  //   else {
  //     this.souscriptionForm.get('isSubscriber').setValue(false);
  //     this.currentSouscripteur = "Pour un tiers";
  //     this.souscriptionForm.get('souscripteur').get('nom').setValue('');
  //     this.souscriptionForm.get('souscripteur').get('prenom').setValue('');
  //     this.souscriptionForm.get('souscripteur').get('email').setValue('');
  //     this.souscriptionForm.get('souscripteur').get('telephone').setValue('');
  //   }
  //   this.enterAssure()
  // }
  addUser(n) {

  }
  validersous(){
    if(this.souscriptionForm.get('nombreAssure').get('typeFamille').value === '2'){
      this.conjChoosen();
    }
    this.validercont();
    if (this.souscriptionForm.get('nombreAssure').invalid) {
      this.invalid = true;
    }
    else {

      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      this.invalid = false
    }
  }
  goTo(step){
    window.scroll(500,500);
    this.wizard.goToStep(step);
  }
  getF(id,code){
    this.currentCodeAs = code;
    setTimeout(() => {
      this.getCurrentAssure(id,code);
    }, 1500);
  }
  getCurrentAssure(id,code){
    this.visiblepack = true;
    this.currentAssure = id;
  }
  retourInit(){
    this.visiblepack = false;
  }
  validerSiulateuradulte(){
    if(this.solo){
      this.addConjoint('assure');
    }
    if(this.attachments?.length === 0){
      this.noatt = true;
    }
    let val = this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').value;
    // if(val === this.conjoits.length){
    if(this.conjoits.length !== 0 && this.checkAdulte === this.nombreAdulte && this.checkEnfant === this.nombreEnfant){
      // window.scrollTo(500, 500);
      // this.wizard.goToNextStep();
      // this.setAssure(1);
      this.getDevisLoader = true;
      let value = this.souscriptionForm.get('AxASimulateur').value;
      value.nombreAdulte = this.nombreAdulte+'';
      value.nombreEnfant = this.nombreEnfant+'';
      this.dataSimulateur.formule = this.formuleSante.filter(el => el.id === Number(value.typeFormule))[0].nom;
      this.dataSimulateur.typeFormule = this.typeFormuleAssure.filter(el => el.id === Number(value.formule))[0].nom;
      if(this.solo){
        // delete value.isOld;

        this.souscriptionForm.get('AxASimulateur').get('nombreEnfant').enable();
        this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').enable();
        // @ts-ignore
        value.age = new Date().getFullYear() - this.souscriptionForm.get('AxASimulateur').get('age').value.getFullYear();
        // @ts-ignore
        if(value.age > 59){
          value.isOld = '1';
        }else{
          value.isOld = '0';
        }
        this.dataSimulateur.age = value.age+'';
      }else{
        // delete value.age;
        // value.age = new Date().getFullYear() - new Date(this.souscriptionForm.get('assure').get(''));
        // @ts-ignore
        value.age = 50;
        this.dataSimulateur.age = value.age+'';
        // @ts-ignore
        value.ageFamille = [];
        for(let i=0; i<this.conjoits.length; i++){
          // @ts-ignore
          value.ageFamille[i] = new Date().getFullYear() - new Date(this.conjoits[i].dateNaissance).getFullYear();
        }
      }
      this.subscriptionService.getDevis(value)
        .subscribe((resp: any) =>{
          window.scrollTo(500, 500);
          this.wizard.goToNextStep();
          // this.setAssure(1);
          this.getDevisLoader = false;
          resp.data
          this.dataSimulateur.ageMajor = 0;
          if(!this.solo)
          // @ts-ignore
            for(let ag of value.ageFamille){
              if(ag > 59){
                this.dataSimulateur.ageMajor += 1;
              }
            }
          
          for (let resul of resp.data) {
            if (resul.assureur.code == 'NSIA') resul['img'] = "../../../../../assets/images/nsia.png";
            else if (resul.assureur.code == 'AXA') resul['img'] = "../../../../../assets/images/axa.png";
            else if (resul.assureur.code == 'AMSA') resul['img'] = "../../../../../assets/images/amsa.png";
            else if (resul.assureur.code == 'SALAMA') resul['img'] = "../../../../../assets/images/salama.png";
                // else if(resul.code == 'SALAMA') resul['img']="../../../../../assets/images/salama.png";
            // else if(resul.code == 'SALAMA') resul['img']="../../../../../assets/images/salama.png";
            else resul['img'] = "../../../../../assets/images/assurance.jpg";
            let valTotal = resul.assureur.majoration;
            for(let val of resul.assureur.parametrageSante){
              valTotal += val.primeTtc;
            }
            resul.assureur.parametrageSante[0].primeTtc = valTotal;
          }
          this.listAssureurs = resp.data;
        },err=>{
          this.getDevisLoader = false;
        });
      this.invalid = false;
    }else{
      this.utilsService.showToastInfo('Vous devez renseigner tous les assurés', "Info");
    }
  }
  validerSiulateur1(){
    if(this.idAssureur !== 0){
      window.scrollTo(500, 500);
      this.wizard.goToNextStep();
      // this.setAssure(1);
    }
  }

  validerSiulateur(){
    if (this.souscriptionForm.get('AxASimulateur').valid) {
      // ici
      this.getDevisLoader = true;
      if(this.solo){
        this.souscriptionForm.get('AxASimulateur').get('nombreEnfant').enable();
        this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').enable();
      }
      let value = this.souscriptionForm.get('AxASimulateur').value;
      value.nombreAdulte = value.nombreAdulte+'';
      value.nombreEnfant = value.nombreEnfant+'';
      this.dataSimulateur.formule = this.formuleSante.filter(el => el.id === Number(value.typeFormule))[0].nom;
      this.dataSimulateur.typeFormule = this.typeFormuleAssure.filter(el => el.id === Number(value.formule))[0].nom;
      // @ts-ignore
      value.age = new Date().getFullYear() - this.souscriptionForm.get('AxASimulateur').get('age').value.getFullYear();
      this.dataSimulateur.age = value.age+'';
      this.subscriptionService.getDevis(value)
        .subscribe((resp: any) =>{
          window.scrollTo(500, 500);
          this.wizard.goToNextStep();
          this.getDevisLoader = false;
          for (let resul of resp.data) {
            if (resul.code == 'NSIA') resul['img'] = "../../../../../assets/images/nsia.png";
            else if (resul.code == 'AXA') resul['img'] = "../../../../../assets/images/axa.png";
            else if (resul.code == 'AMSA') resul['img'] = "../../../../../assets/images/amsa.png";
            else if (resul.code == 'SALAMA') resul['img'] = "../../../../../assets/images/salama.png";
                // else if(resul.code == 'SALAMA') resul['img']="../../../../../assets/images/salama.png";
            // else if(resul.code == 'SALAMA') resul['img']="../../../../../assets/images/salama.png";
            else resul['img'] = "../../../../../assets/images/assurance.jpg";
            let eSTTC = 0;
            let cSTTC = 0;
            for(let val of resul.assureur.parametrageSante){
              if(val.forlmule === 'Enfants/supplémentaire'){
                eSTTC += val.primeTtc;
              }else if(val.forlmule === 'Conjoints/supplémentaire'){
                cSTTC += val.primeTtc;
              }
            }
            resul.assureur.parametrageSante[0].primeTtc += eSTTC + cSTTC;  
          }
          this.listAssureurs = resp.data;
        },err=>{
          this.getDevisLoader = false;
        });
      this.invalid = false;
      // la
    }else{
      this.invalid = true;
    }
  }
  validerInitial(){
    if (this.souscriptionForm.get('AxASimulateur').valid) {
          if(this.solo){

          }
          window.scrollTo(500, 500);
          this.wizard.goToNextStep();
    }else{
      this.invalid = true;
    }
  }
  getTypeFormuleAssure(){
    this.subscriptionService.getTypeFormuleAssure()
    .subscribe((resp: any) => {
      if(resp.responseCode === 200){
        this.formuleSante = resp.data;
        this.formuleSante = this.formuleSante.filter(el => el.code === 'famille' || el.code === 'solo');
      }
    },err=>{
    })
  }
  getFormuleSante(){
    this.subscriptionService.getFormuleSante()
    .subscribe((resp: any) => {
      if(resp.responseCode === 200){
        this.typeFormuleAssure = resp.data;
      }
    },err=>{
    })
  }
  validercont() {
    if (this.souscriptionForm.get('contrat').invalid) {
      this.invalid = true;
    }
    else {
      this.dateExpiration = moment(this.souscriptionForm.get('contrat').get('debut').value).add(this.currentAssureur['duree'], 'month');
      
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      this.invalid = false
    }
  }
  validerassure() {
    if (this.souscriptionForm.get('assure').invalid) {
      this.invalid = true;
      this.conjoits[0] = this.souscriptionForm.get('assure').value;
    }
    else {
      this.wizard.goToNextStep();
      window.scrollTo(500, 500);
      this.invalid = false;
      this.userAdule = this.souscriptionForm.get('assure').value;
    }
  }
  checkSubscription(numberSouscription) {
    const source = interval(5000)
    const check = source.subscribe(x => {
      fetch(environment.baseApiUrl + 'souscriptions/check-subscription?numeroSouscription=' + numberSouscription)
        .then(response => {
          response.json()
            .then(data => {
              if (data['responseCode'] == 200) {
                this.router.navigateByUrl("success-payment");
                check.unsubscribe();
              }
            });
        });
    });
  }
  openPDF(): void {
    let data = document.getElementById('santeDownload');

    // html2canvas(data).then(canvas => {
    //   const contentDataURL = canvas.toDataURL('image/png')  
    //   // let pdf =  new jsPDF('l', 'cm', 'a4'); //Generates PDF in landscape mode
    //   var imgWidth = 210;
    //   var pageHeight = 380;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight; 
    //   let pdf = new jsPDF('p', 'cm', 'a4'); 
    //   // Generates PDF in portrait mode
    //   pdf.addImage(contentDataURL, 'PNG', 0, 0, imgWidth, imgHeight);  
    //   pdf.save('Filename.pdf');   
    // }); 
    // let doc= new jsPDF('p', 'mm', 'a4');
    // doc.html(data, {
    //    callback: (doc) => {
    //      doc.setFontSize(12);
    //      doc.setDisplayMode("original");
    //      doc.save("devis.pdf");
    //    }
    // });

    // html2canvas(data).then(canvas => {
    //   // Few necessary setting options  
    //   document.body.style.zoom = "10%";
    //   var imgWidth = 210;
    //   var pageHeight = 380;
    //   var imgHeight = canvas.height * imgWidth / canvas.width;
    //   var heightLeft = imgHeight; 
    //   const contentDataURL = canvas.toDataURL('image/png', 1.0);
    //   const contentDataURL1 = canvas.toDataURL('image/png', 1.0);
    //   // let l={
    //   //   orientation: 'p',
    //   //                    unit: 'pt',
    //   //                    format: 'a4',
    //   //                    compress: true,
    //   //                    fontSize: 8,
    //   //                    lineHeight: 1,

    //   //                    printHeaders: true
    //   // }
    //   let pdf = new jsPDF('p', 'mm', 'a4',true,false); // A4 size page of PDF  
    //   var position = 0;
    //   // pdf.addPage();
    //   pdf.addImage(contentDataURL,'PNG', 0,-150, imgWidth, imgHeight);
    //   pdf.addPage();
    //   pdf.addImage(contentDataURL1,'PNG', 0, -267.7, imgWidth, imgHeight+39,'SLOW');
    //   pdf.save('DetailsDevis.pdf'); // Generated PDF 
    //   document.body.style.zoom = "100%";
    // });
    // html2canvas(data).then(canvas => {
    //   // this.canvas.nativeElement.src = canvas.toDataURL();
    //    let dah=canvas.toDataURL('image/png');
    //     let doc= new jsPDF('p', 'mm', 'a4',false,true);
    //     doc.html(dah, {
    //       x:0,
    //       y:0,
    //       callback: (doc) => {
    //         doc.save("devis.pdf");
    //       },

    //     });
    // });

    var pdf = new jsPDF();
    // document.body.style.zoom = "70%";
    var options = {
      pagesplit: false,
      x: 0,
      y: 370,
      quality: 4,
      scale: 2,
    };
    pdf.setFont('Calibri');
    pdf.text(50, 50, 'Now this is Calibri');
    pdf.addHTML(data, options, function () {
      pdf.save("Devis_Santé.pdf");
    }
    );
    // document.body.style.zoom = "100%";

    //   html2canvas(data).then(canvas => {
    //   var imgWidth = 210; 
    //   var pageHeight = 295;  
    //   var imgHeight = canvas.height * imgWidth / canvas.width ;
    //   var heightLeft = imgHeight;
    //   const contentDataURL = canvas.toDataURL('image/png', 1.0);
    //   var doc = new jsPDF('p', 'mm');
    //   var position = 0;

    //   doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;

    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     doc.addPage();
    //     doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }
    //   doc.save( 'file.pdf');
    // });
  }

  enterAssure() {
    if (this.assure == 1) {
      // @ts-ignore
      this.souscriptionForm.get('assure').patchValue(this.souscriptionForm.get('souscripteur').value);
      this.souscriptionForm.get('assure').get('numeroTelephone').setValue(this.souscriptionForm.get('souscripteur').get('telephone').value);
      let body = {
        ...this.souscriptionForm.get('assure').value
      }

      if (this.beneficiares.indexOf(body) == -1)
        this.beneficiares.push(body);
    } 
    // else this.souscriptionForm.get('assure').reset();
  }

  captureScreen(): void {
    var data = document.getElementById('santeDownload');
    html2canvas(data, { backgroundColor: 'white', removeContainer: true, scrollX: 0, scrollY: 0}).then(canvas => {
      var imgWidth = 210;
      var pageHeight = 395;
      const contentDataURL = canvas.toDataURL('application/pdf')
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      // var doc = new jsPDF('p', 'mm', 'a4');
      let doc = new jsPDF('p', 'mm', 'a4');
      var position = 0;
      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save('Devis_Santé.pdf');
      if (this.datas) {
        this.modalService.dismissAll();
      }
    });
  }

  checkType(e){
    let nombreEnfant = this.souscriptionForm.get('nombreAssure').get("nombreEnfant").value;
    let nombreAdulte = this.souscriptionForm.get('nombreAssure').get("nombreAdulte").value;
    
    // if( e.target.value == 'Enfant' && this.checkEnfant >= nombreEnfant ) {
    //   this.utilsService.showToastError("Le nombre d'enfant est dépassé", "Erreur");
    // }
    // if( e.target.value == 'Adulte' && this.checkAdulte >= nombreAdulte ) {
    //   this.utilsService.showToastError("Le nombre d'adulte est dépassé", "Erreur");
    // }
  }
  benChoosen(){
    let nombreEnfant = this.souscriptionForm.get('nombreAssure').get("nombreEnfant").value;
    let nombreAdulte = this.souscriptionForm.get('nombreAssure').get("nombreAdulte").value;
    // @ts-ignore
    if( nombreEnfant <= 0 ) {
      this.tabRelation.indexOf('Enfant')
      this.tabRelation.splice(this.tabRelation.indexOf('Enfant'),1)
    }
    // @ts-ignore
    if( nombreAdulte <= 0 ) {
      this.tabRelation.splice(this.tabRelation.indexOf('Adulte'),1)
    }
    if(this.tabRelation.length === 0){
      this.utilsService.showToastInfo("Le nombre d'assuré est déjà atteint !!!", "Avertissement");
    }else{
      this.beneficiaryChoosen = true;
    }
  }

  conjChoosen(){
    this.conjointChoosen = true;
    // this.souscriptionForm.get('beneficiaire').get('type').validator = <any>Validators.compose([]); 
    // this.souscriptionForm.get('beneficiaire').get('type').updateValueAndValidity();
    this.souscriptionForm.get('beneficiaire').reset();
    // this.beneficiaryChoosen = true;
    this.updateConj = false;
    this.modalService.open(this.modalAssure, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {
    });
  }

  openQuestionnaireSante(){
    this.modalService.open(this.modalQuestionnaireSante, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      if (result == 'subscribe') {

      }
    }, (reason) => {
    });
  }
  getQuestionnaire(){
    let table = [];
    this.enteteWithout=[...this.enteteQuestionnaireSante].slice(1);
    for(let c of this.tabQuestionnaireSante){
      let line:any = {...c};
      for (let e of this.enteteWithout) {
        line[e.name]=['oui' , 'non'];
      }
      table.push(line);
    }
    this.tableauQuestionnaire['body']=table;
    
  }

  selectQuestion(question, event,rep){
    
    question.reponse = rep;
  }

  reponses = ['oui','non'];
  getQuestionnaireAs(){
    let tableAs = [];
    this.enteteWithoutAs=[...this.enteteQuestionnaireSanteAs].slice(1);
    this.tabQuestionnaireSante = this.tabQuestionnaireSante.map(el=>{
      el['reponse'] = '';
      return el;
    });
    // for(let c of this.tabQuestionnaireSante){
    //   let line:any = {...c};
    //   for (let e of this.enteteWithoutAs) {
    //     line[e.name]=['oui' , 'non'];
    //   }
    //   tableAs.push(line);
    // }
    this.tableauQuestionnaire['body']=this.tabQuestionnaireSante;
  }
  myRange(){
    return [0,1,2,3,4,5];
  }

  enfChoosen(){
    this.enfantChoosen = true;
    this.souscriptionForm.get('beneficiaireEnfantAxa').reset();
    this.updateConj = false;
  }

  getSouscriptions(){
    if(this.datas) {
      this.souscriptionForm.get('souscripteur').setValue(this.detailsPdf.souscripteur);
      this.souscriptionForm.get('assure').setValue(this.detailsPdf.assure);
      this.souscriptionForm.get('formule').setValue(this.detailsPdf.formule.id);
      this.souscriptionForm.get('produit').setValue(this.detailsPdf.produit.id);
      this.currentAssureur = this.detailsPdf.assureur;
      this.currentAssureur['duree'] = this.detailsPdf.duree.duree;
      this.currentAssureur['dureeDisplay'] =this.detailsPdf.duree.displayMe;
      this.souscriptionForm.get('assureur').setValue(this.detailsPdf.assureur.id);
      this.souscriptionForm.get('duree').setValue(this.detailsPdf.duree.id);
      this.souscriptionForm.get('montant').setValue(this.detailsPdf.prix);
      this.currentPack = {
        "assureur": this.detailsPdf.assureur.nom,
        "duree": this.detailsPdf.duree.displayMe,
        "prix": this.detailsPdf.prix,
        "produit": this.detailsPdf.produit.nom,
        "formule": this.detailsPdf.formule.nom,
        "prestations": this.detailsPdf.produit.prestations,
        "avantages": this.convertStringToArray(this.detailsPdf.formule.description)
      };
      this.getImageAssureur();
      let infos = {
        matricule: this.detailsPdf.souscripteur.matricule,
        firstName: this.detailsPdf.souscripteur.nom,
        lastName: this.detailsPdf.souscripteur.prenom,
        email: this.detailsPdf.souscripteur.email,
        telephone: this.detailsPdf.souscripteur.telephone,
        secteuractivite: this.detailsPdf.souscripteur.secteuractivite,
        profession: this.detailsPdf.souscripteur.profession,
        adresse: this.detailsPdf.souscripteur.adresse
      };
      this.user = [];
      this.user.infos = infos;
      this.getDevis();
    }
  }
  getDevis() {
    this.enfant=0;
    this.conjoint=0;
      for(let ben of this.beneficiares){
        if(ben.type=='Enfant')
          this.enfant++;
        else this.conjoint++;
      }
      this.modalService.open(this.contentResultass, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
            if (result == 'subscribe')
              this.wizard.goToNextStep();
            window.scrollTo(500, 500);
          }, (reason) => {
          }
      );
    if (this.datas) {
      if (this.modalService.hasOpenModals()) {
        setTimeout(() => {
          this.captureScreen();
        }, 100);
        //
      }
    }
    }
  getImageAssureur() {
    if (this.currentAssureur['code'] == 'NSIA') this.currentAssureur['img'] = "../../../../../assets/images/nsia.png";
    else if (this.currentAssureur['code'] == 'AXA') this.currentAssureur['img'] = "../../../../../assets/images/axa.png";
    else if (this.currentAssureur['code'] == 'AMSA') this.currentAssureur['img'] = "../../../../../assets/images/amsa.png";
    else if (this.currentAssureur['code'] == 'SALAMA') this.currentAssureur['img'] = "../../../../../assets/images/salama.png";
        // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
    // else if(val.code == 'SALAMA') val['img']="../../../../../assets/images/salama.png";
    else this.currentAssureur['img'] = "../../../../../assets/images/assurance.jpg";
  }
  selectAssureur(id,res){
    this.assureurSelected = res;
    this.idAssureur = res.assureur.id;
    this.dataSimulateur.service = res.assureur.nom; 
    this.dataSimulateur.primeTtc = res.assureur.parametrageSante[0].primeTtc;
    this.dataSimulateur.primeNette = res.assureur.parametrageSante[0].primeNette; 
    this.dataSimulateur.taxe = res.assureur.parametrageSante[0].taxe; 
    this.dataSimulateur.coutPiece = res.assureur.parametrageSante[0].coutPiece;
    this.dataSimulateur.enfantSupp = 0;
    this.dataSimulateur.enfantSuppValTTc = 0;
    this.dataSimulateur.adulteSupp = 0;
    this.dataSimulateur.adulteSuppValTTc = 0;
    this.dataSimulateur.majoration = res.assureur.majoration;
    for(let r of res.assureur.parametrageSante) {
      if(r.formule.nom === "Enfants/supplémentaire"){
        this.dataSimulateur.enfantSupp += 1;
        this.dataSimulateur.enfantSuppValTTc += r.primeTtc;
      }else if(r.formule.nom === "Conjoints/supplémentaire"){
        this.dataSimulateur.adulteSupp += 1;
        this.dataSimulateur.adulteSuppValTTc += r.primeTtc;
      }
    }   
    this.modalService.open(this.simulateurDevis, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe')
        this.wizard.goToNextStep();
        window.scrollTo(500, 500);
    }, (reason) => {
    }
    );
    
  }
  souscrire() {
    this.scroll();
    this.modalService.dismissAll(this.modasign);
    let token = JSON.parse(localStorage.getItem('microFinance'));
    if (token) {
      this.login = true;

    } else {
      this.modalService.open(this.modalog, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
        if (result == 'subscribe') {

        }
      }, (reason) => {

      });
    }
  }
  addSuppConj(event,type){
    if(type === 'conjoints'){
      this.conjSupplementaire = event.target.checked;
    }else{
      this.enfantSupplementaire = event.target.checked;
    }
  }
  deleteSimulateurAssurer(el: any,index){
    if(el.type === 'enfant'){
      // this.enfants.splice(index, 1);
      this.checkEnfant --;
    }else if(el.type === 'adulte'){
      this.checkAdulte --;
    }
    this.conjoits.splice(index, 1);
  }
  deletePackAssurer(el: any,index){
    if(el.type === 'enfant'){
      // this.enfants.splice(index, 1);
      this.checkEnfant --;
    }else if(el.type === 'adulte'){
      this.checkAdulte --;
    }
    this.beneficiares.splice(index, 1);
  }
  selectFormuleSimul(event){
    if(event.target.value === '1'){
      this.solo = true;
      this.nombreAdulte = 1;
      this.nombreEnfant = 0;
      this.souscriptionForm.get('AxASimulateur').get('nombreEnfant').validator = <any>Validators.compose([]); 
      this.souscriptionForm.get('AxASimulateur').get('nombreEnfant').updateValueAndValidity();
      this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').validator = <any>Validators.compose([]); 
      this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').updateValueAndValidity();
      this.souscriptionForm.get('AxASimulateur').get('age').validator = <any>Validators.compose([Validators.required]); 
      this.souscriptionForm.get('AxASimulateur').get('age').updateValueAndValidity();
      this.souscriptionForm.get('AxASimulateur').get('isOld').validator = <any>Validators.compose([]); 
      this.souscriptionForm.get('AxASimulateur').get('isOld').updateValueAndValidity();
    }else{
      this.solo = false;
      this.nombreAdulte = 0;
      this.nombreEnfant = 0;
      this.souscriptionForm.get('AxASimulateur').get('nombreEnfant').validator = <any>Validators.compose([Validators.required,Validators.min(0)]); 
      this.souscriptionForm.get('AxASimulateur').get('nombreEnfant').updateValueAndValidity();
      this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').validator = <any>Validators.compose([Validators.required,Validators.min(0)]); 
      this.souscriptionForm.get('AxASimulateur').get('nombreAdulte').updateValueAndValidity();
      this.souscriptionForm.get('AxASimulateur').get('age').validator = <any>Validators.compose([]); 
      this.souscriptionForm.get('AxASimulateur').get('age').updateValueAndValidity();
      this.souscriptionForm.get('AxASimulateur').get('isOld').validator = <any>Validators.compose([Validators.required]); 
      this.souscriptionForm.get('AxASimulateur').get('isOld').updateValueAndValidity();
    }
  }
  edit(value: any,index){
    this.index = index;
    let event = {
      year: new Date(value.dateNaissance).getFullYear(),
      month: new Date(value.dateNaissance).getMonth() + 1,
      day: new Date(value.dateNaissance).getDate()
    }
    // value.dateNaissance = new Date(event.year+'/'+event.month+'/'+event.day);
    // this.souscriptionForm.get('beneficiaireEnfantAxa').patchValue({});
    this.souscriptionForm.get('beneficiaire').patchValue(value);
    this.dateNaissance = event.day+'/'+event.month+'/'+event.year;
    // this.souscriptionForm.get('beneficiaire').get('dateNaissance').setValue(new Date(event.year+'/'+event.month+'/'+event.day));
    // this.onDateSelect(event, 'beneficiaire', 'dateNaissance');
    this.conjointChoosen = true;
    this.updateConj = true;
    this.modalService.open(this.modalAssure, { ariaLabelledBy: 'modal-basic-title', size: <any>'lg' }).result.then((result) => {
      if (result == 'subscribe') {      
      }
    }, (reason) => {
    });
  }
  editEnfant(value: any,index){
    this.index = index
    // this.souscriptionForm.get('beneficiaireEnfantAxa').patchValue({});
    this.souscriptionForm.get('beneficiaireEnfantAxa').patchValue(value);
    this.enfantChoosen = true;
    this.updateConj = true;
  }

    //cette fonction permet d'exporter la liste sous format excel ou pdf
    exportAs() {
      let nom = "Liste des prestataires";
      let value = [];
      this.exporter = true;
        value = this.prestataires;
        if (value.length != 0) {
            let donne = this.exempleGenPdfHeaderFooter(nom);
            var doc = donne.doc;
            var col = this.displayedColumnsFile;
            var rows = [];
            var itemCurrent;
            for (var item of value) {
              itemCurrent = item;
              let tabField = [];
              let elementKeys = Object.keys(item);
              let i = 0;
              for (let field of this.tabFileBody) {
                for (let element of elementKeys) {
                  if (field.toString() == element.toString()) {
                    if (field == 'createdAt' || field == 'date')
                      tabField.push(moment(itemCurrent[field]).format('DD/MM/YYYY') || '');
                    else {
                      if (typeof itemCurrent[field] == 'object' && itemCurrent[field])
                        tabField.push(itemCurrent[field]['nom'] || '');
                      else tabField.push(itemCurrent[field] || '');
                    }
                  }
                }
                i++;
              }
              rows.push(tabField);
            }
            doc.autoTable(col, rows);
            doc.save(nom + '.pdf');
            // this.snackBar.open("Téléchargement réussi", 'OK', {
            //   verticalPosition: 'bottom',
            //   duration: 5000,
            //   panelClass: ['mycssSnackbarGreen']
            // });
            this.exporter = false;
        } else {
          // this.snackBar.open("La liste est vide!!!", 'OK', {
          //   verticalPosition: 'bottom',
          //   duration: 5000,
          //   panelClass: ['mycssSnackbarRed']
          // });
          this.exporter=false;
        }
    }
    exempleGenPdfHeaderFooter(fileName) {
      let toDay = new Date();
      let marginX = 0;
      var doc = new jsPDF();
      var totalPagesExp = '{total_pages_count_string}';
      doc.setFillColor(0, 0, 255);
      doc.printingHeaderRow = true;
      var columns = [fileName, "Créé par " + this.user.infos.firstName +" "+this.user.infos.lastName + " le :" + this.datePipe.transform(toDay, 'dd/MM/yyyy')];
      var rows = [];
      doc.autoTable({
        columns: columns,
        body: rows,
        theme: 'grid',
        margin: {
          top: 10
        },
        didDrawCell: function (data) {
          if (
            (data.row.section === 'head') &&
            data.column.index === 1
          ) {
            data.cell.styles.textColor = [51, 122, 183];
            data.cell.styles.textSize = "12";
            data.cell.styles.valign = 'middle';
            data.cell.styles.fillColor = [216, 78, 75];
          }
          if (
            (data.row.section === 'head') &&
            data.column.index === 0
          ) {
            //doc.addImage('JPG', data.cell.x + 2, data.cell.y + 2, 30, 15);
          }
        },
        didDrawPage: function (data) {
          marginX = data.settings.margin.left;
          // Header
          doc.setFontSize(12);
          doc.setTextColor(255);
          doc.setFontStyle('normal');
        },
        styles: {
          lineColor: [0, 0, 0],
          lineWidth: 0.3,
          TextColor: [51, 122, 183],
        },
        headStyles: {
          fillColor: [255, 255, 255],
          fontSize: 10,
          fontStyle: 'normal',
          valign: 'middle',
          textColor: 0,
          minCellHeight: 20,
        },
        willDrawCell: function (data) {
          if (data.row.section === 'head') {
            doc.setTextColor(51, 122, 183);
            doc.setFontStyle('normal');
          }
          if (data.row.section === 'head' && data.column.index === 1) {
            doc.setFontStyle('bold');
            doc.setFontSize(12);
          }
        },
      });
      return { doc: doc, marginX: marginX, totalPagesExp: totalPagesExp };
    }


    //start plainte
    sendPlainte(){
      this.modalService.open(this.plainte, { ariaLabelledBy: 'modal-basic-title', size: <any>'xl' }).result.then((result) => {
        if (result == 'subscribe') {
        }
      }, (reason) => {
      });
    }

   
 checkTypePlainte(){
  let val = this.plainteForm.get('typePlainte').value;
  // if(val != ''){
  //   if(val=='Matin'){
  //     this.heureDisponibiliteListTemoin = this.heureDisponibiliteList.filter(el => el.filtre == 'Matin')
  //   }
  //   else{
  //     this.heureDisponibiliteListTemoin = this.heureDisponibiliteList.filter(el => el.filtre == 'Soir')
  //   }
  // }    
}


remonterPlainte() {
this.sendingRequestPlainte = true;
if(this.plainteForm.valid){
  this.subscriptionService.remonterPlainte(this.plainteForm.value).subscribe((resp) => {
    if (resp['responseCode'] = 200) {
      this.utilsService.showToastSuccess("Votre demande de plainte a été enregistré avec succès", "Demande de rappel")
      this.sendingRequestPlainte = false;
      this.modalService.dismissAll(this.plainte);
      this.plainteForm.reset();
    } else {
      this.sendingRequestPlainte = false;
    }
  }, error => {
    this.sendingRequestPlainte = false;
    this.utilsService.showToastError(error['error']['errors'][0]['message'], "Echec");
  });
}
}

    //end plainte
}
