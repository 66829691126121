<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact</h2>
                    <ul>
                        <li><a routerLink="/accueil" class="contact_link">Accueil</a></li>
                        <li class="contact_link">Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span class="sub-title">Contactez-nous</span> -->
            <h2 class="envoyer_titre">Envoyez-nous un message pour toute requête</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="text" name="name" class="form-control" placeholder="Prénom & Nom">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input type="email" name="email" class="form-control" placeholder="Email">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input type="text" name="phone-number" class="form-control" placeholder="Téléphone">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Votre message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">Envoyer le message<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div id="map">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d96573.31103687892!2d-73.91097358959159!3d40.85176866820105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c28b553a697cb1%3A0x556e43a78ff15c77!2sThe%20Bronx%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1589312138561!5m2!1sen!2sbd"></iframe>
                </div>
            </div>
        </div>

        <!-- <div class="contact-info">
            <div class="contact-info-content">
                <h3>Contactez-nous par numéro de téléphone ou adresse e-mail</h3>
                <h2>
                    <a href="tel:+225 27 22 23 68 83">+225 27 22 23 68 83</a>
                    <span>OU</span>
                    <a href="contact@optima.world">infos@abidjanaise-assurances.net</a>
                </h2>
                <ul class="social">
                    <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                </ul>
            </div>
        </div> -->
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>

<section class="find-agent-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2 class="avantage">L’ABIDJANAISE D’ASSURANCES</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="find-agent-content">
                    <div class="row">
                        <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon icon_height">
                                    <i class="flaticon-home-insurance"></i>
                                </div>

                                <h3 class="titre_card">Assurances</h3>
                                <p>L’ABIDJANAISE D’ASSURANCES possède plusieurs agences d’assurance Vie et Non Vie réparties dans 12 pays de l’Afrique Centrale et Occidentale.</p>
                            </div>
                        </div>

                        <!-- <div class="col-lg-12 col-md-6 col-sm-6">
                            <div class="box">
                                <div class="icon">
                                    <i class="flaticon-briefcase"></i>
                                </div>

                                <h3>Banque & Finance</h3>
                                <p>Les banques du Groupe INSURTECH sont des banques commerciales de proximité. Elles
                                    offrent sur leurs marchés respectifs des solutions adaptées au grand public.</p>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="find-agent-contact-info">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class="flaticon-call"></i>
                            </div>

                            <span class="contact_titre">Parlez à notre agent</span>
                            <a href="tel:1518285679" class="contact_value">+225 27 22 23 68 83</a>
                        </li>

                        <li>
                            <cite>Ou</cite>
                        </li>

                        <li>
                            <div class="icon">
                                <i class="flaticon-email"></i>
                            </div>

                            <span class="contact_titre">Envoyez-nous un e-mail</span>
                            <a href="mailto:hello@truz.com" class="contact_value">infos@abidjanaise-assurances.net</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>