<!-- <app-header-four></app-header-four> -->
<app-header-one></app-header-one>



<div class="main-banner-section item-bg6 height_image">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-content">
                    <h1>ASSURANCE AUTOMOBILE</h1>
                    <!-- <p>Automobiles Votre véhicule vous est indispensable ! Choisissez la formule la plus adaptée grâce à l’assistance de votre conseiller.
                         Nous proposons les formules suivantes :<br> – Tous risques<br> – Tierce collision<br> – Tiers simple
                        ou complète.</p> -->
                    <p>Automobiles Votre véhicule vous est indispensable ! Choisissez la formule la plus adaptée grâce à l’assistance de votre conseiller.</p>
                    <div class="btn-box">
                        <a (click)="souscrire()" class="default-btn btnO">Obtenir un devis<span></span></a>
                        <a class="optional-btn rappel" (click)="rappeler()">Me faire rappeler<span></span></a>
                    </div>
                    <!-- <div class="btn-box" *ngIf="login && donnee != 'auto'">
                        
                        <a (click)="souscrire()" class="default-btn btnO">Obtenir un devis<span></span></a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>

    <div class="shape1"><img src="assets/img/car.png" alt="image"></div>
    <div class="shape2"><img src="assets/img/umbrella-white.png" alt="image"></div>
    <div class="shape3"><img src="assets/img/home.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/heart.png" alt="image"></div>
</div>


<div class="row desc container-row justify-content-center" id="contact" #contact>
    <div class="col-12 droite" *ngIf="login">
        <form [formGroup]="simulationVehiculeForm" class="auto">
            <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">
                <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Vehicule">
                    <div class="form-container">
                        <div class="row">
                            <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                                        <option value="" disabled selected>Catégorie de voiture</option>
                                        <option *ngFor="let v of vehiculeCategories" [value]="v.id">{{v?.libelle}}
                                        </option>
                                    </select>
                                    <label for="cars" class="form__label">Catégorie de voiture<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <!-- type de véhicule -->
                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && transportP">
                                <div class="form" placement="top" ngbPopover="Type de véhicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="typeVehiculePublic" formControlName="typeVehiculePublic" name="typeVehiculePublic" #typeVehiculePublic>
                                        <option value="" disabled selected>Type de véhicule</option>
                                        <option *ngFor="let typeV of transportPublicList" [value]="typeV.id">{{typeV?.libelle}}
                                        </option>
                                    </select>
                                    <label for="cars" class="form__label">Type de véhicule <span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehiculePublic').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <!-- type de véhicule -->
                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">
                                <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-balance-scale form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" formControlName="poids" id="poidsVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="poidsVehicule" class="form__label">Poids du véhicule<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && moto">
                                <div class="form" ngbPopover="Cylindrée" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-balance-scale form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" formControlName="cylindree" id="cylindree" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="cylindree" class="form__label">Cylindrée<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('cylindree').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">

                                <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-gas-pump form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="tcarb" class="form__select" formControlName="typeMoteur">
                                        <option value="" disabled selected="selected">Type de carburant</option>
                                        <option *ngFor="let typeCarbu of carburantList" [value]="typeCarbu?.value">{{typeCarbu?.name}}</option>
                                    </select>
                                    <label for="cars" class="form__label">Type de carburant<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">

                                <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-balance-scale form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                                    <input type="number" formControlName="nombrePlace" id="place" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                                    <label for="place" class="form__label">Nombre de places<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">
                                <div class="form" [ngbPopover]="showCharges ? 'Charge Utile' : 'Puissance'" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-bolt form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="number" min="1" formControlName="puissance" id="puissanceAuto" class="form__input" autocomplete="off" placeholder=" " />
                                    <label *ngIf="!showCharges" for="puissanceAuto" class="form__label">Puissance<span class="text-red">
                                            *</span></label>
                                    <label *ngIf="showCharges" for="puissanceAuto" class="form__label">Charge Utile<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                                <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="immatriculation" id="immatriculationV" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="immatriculationV" class="form__label">Immatriculation du véhicule<span
                                            class="text-red"></span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                                <div class="form" ngbPopover="Numéro de châssis du véhicule" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="numeroChassis" id="numeroChassis" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="numeroChassis" class="form__label">Numéro de châssis du véhicule<span
                                            class="text-red"></span></label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && shoModel">
                                <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                                        <option value="" disabled selected="selected"> Marque du vehicule</option>
                                        <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}
                                        </option>
                                    </select>
                                    <label for="mySelect" class="form__label">Marque du vehicule<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && simulationVehiculeForm.get('marque').valid && shoModel && !autreMarqueSelected">
                                <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select placeholder=" " class="form__select" id="cars" formControlName="modele" (change)="modele()">
                                        <option value="" disabled selected="selected"> Modèle du vehicule</option>
                                        <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}
                                        </option>
                                    </select>
                                    <label for="cars" class="form__label">Modèle du vehicule<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!--start new field -->
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && (!shoModel || autreMarqueSelected)">
                                <div class="form" ngbPopover="Autre marque du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="autreMarque" id="marqueVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="marqueVehicule" class="form__label">Autre marque du véhicule<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('autreMarque').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && (!shoModel || autreMarqueSelected || autreModelSelected)">
                                <div class="form" ngbPopover="Autre modèle du vehicule" triggers="mouseenter:mouseleave">
                                    <i class="fas fa-car form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="autreModele" id="modeleVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="modeleVehicule" class="form__label">Autre modèle du véhicule<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('autreModele').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!--end new field -->


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">

                                <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="dateCirculation" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker"
                                        (click)="debut.toggle()" />
                                    <label for="dateCirculation" class="form__label">Date de mise en circulation<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                                    Ce champ est obligatoire
                                </div>

                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                                        (click)="dateEffet.toggle()" />
                                    <label for="effet" class="form__label">Date d'effet du contrat<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <div>
                                    <div class="form" placement="top" ngbPopover="Durée du contrat" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-hourglass-2 form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select placeholder=" " class="form__select" id="cars" (change)="checkDuree()" name="contract-duration" formControlName="duree">
                                            <option value="" disabled selected>Durée du contrat</option>
                                            <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">
                                                {{d.displayMe}}</option>
                                        </select>
                                        <label for="cars" class="form__label">Durée du contrat<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('duree').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                <div class="form" placement="top" ngbPopover="Date fin du contrat" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-calendar form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" id="effet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'dateEcheance')" ngbDatepicker #dateEcheance="ngbDatepicker" [value]="this.simulationVehiculeForm.get('dateEcheance').value | date: 'dd/MM/yyyy'"
                                        (click)="dateEcheance.toggle()" />
                                    <label for="effet" class="form__label">Date fin du contrat<span
                                            class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('dateEcheance').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="valider()">Suivant <i class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Garanties" *ngIf="garantieShow">
                    <div class="form-container c-form-container">
                        <div class="row">
                            <div class="col-12 text-center p-2 mrhgarantie">
                                <i class="fa fa-check-circle mr-2 text-success"></i>
                                <b class="c-respon">Responsabilité civile des conducteurs (Garantie par défaut)</b>
                            </div>
                        </div>
                        <div *ngIf="garanties?.length > 0" class="row">
                            <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                                <div class="value" *ngIf="g.sousGarantie.length==0">

                                    <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                    <label for="{{g.code}}"> {{g?.nom}} </label>
                                    <span class="garantDescription" placement="right" triggers="click" [autoClose]="'outside'" [ngbTooltip]="g.description"> ?</span>
                                    <span class="garantDescription" *ngIf="g.code === 'bris' && (complete || collision || plafone)" placement="right"> (Garantie gratuite)</span>
                                </div>
                                <div class="value" *ngIf="g.sousGarantie.length>0">
                                    <label for="" class="label-SG"> {{g?.nom}}</label>
                                    <div *ngFor="let sg of g.sousGarantie" class="sgGarantie" triggers="click" [autoClose]="'outside'" [ngbTooltip]="sg.description">
                                        <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                        <label for="{{g.code}}"> {{sg?.nom}}</label>
                                        <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> <i
                                                class="fa fa-hand-o-right form__icon_option"></i>
                                                 <label for="{{g.description}}">{{sg?.description}}</label>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                            Chargemet en cours ...
                        </div>
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete || collision || plafone">
                                <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-credit-card-alt form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (blur)="setValeurVenale()" (keyup)="onBlurEventneuf($event)" />
                                    <label for="neuf" class="form__label">Valeur à neuf<span class="text-red">
                                            *</span></label>
                                </div>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                Ce champ est obligatoire
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                    <label for="venal" class="form__label">Valeur vénale<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                    Ce champ est obligatoire
                                </div>
                                <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                    La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="popValue">
                        <ng-template #popValeur>
                            <div class="card" *ngIf="incendie || collision || vol || plafone || complete">
                                <div class="card-header">
                                    Veuillez renseigner le(s) champs suivant(s)
                                </div>
                                <div class="card-body">
                                    <div *ngIf="complete">
                                        <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-credit-card-alt form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                            <label for="neuf" class="form__label">Valeur à neuf<span class="text-red">
                                                    *</span></label>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                    </div>
                                    <div *ngIf="incendie || collision || vol || plafone">
                                        <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-money form__icon c-icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                            <label for="venal" class="form__label">Valeur vénale<span class="text-red">
                                                    *</span></label>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                        <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                            La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn " awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="processSimulation()"><i class="fa fa-spinner fa-pulse"
                                    *ngIf="sendingRequest"></i> Suivant <i class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur">
                    <!-- assureurs all -->
                    <div class="" *ngIf="!assureurOtherShow">
                        <div class="container justify-content-center">
                            <div class="noAssureur" *ngIf="sendingRequestAssureur && resultSimulationNew.length == 0">
                                <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                <p class="text-center text-ass">Chargement en cours...</p>
                            </div>
                            <div class="noAssureur" *ngIf="!sendingRequestAssureur && resultSimulationNew.length == 0">
                                <p class="text-center text-ass c-aucune">Aucune proposition</p>
                            </div>
                            <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.</div>
                            <ng-select *ngIf="false" class="select-ass" multiple="true" [(ngModel)]="selectedAss" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" (change)="selectAssureur()" [dropdownPosition]="'bottom'" [ngModelOptions]="{standalone: true}">
                                <ng-option *ngFor="let ass of listAssureurs" [value]="ass.id">
                                    <img [src]="ass.logo" class="img-assureur" [alt]="ass.nom">
                                    <span class="text-assureur">{{ass.nom}}</span> </ng-option>
                            </ng-select>
                            <!-- <div *ngFor="let ass of listAssureurs">
                                <img [src]="ass.logo" class="img-assureur" [alt]="ass.nom">
                            </div> -->

                            <div class="justify-content-center">
                                <!-- <div class="row"> -->
                                <section class="pricing-area ptb-70 pb-70">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let res of resultSimulationNew">
                                                <div class="single-pricing-box">
                                                    <div class="pricing-header bg-{{res?.assureur?.code}}">
                                                        <img class="img-fluid" [src]="res?.assureur?.logo" [alt]="res?.assureur?.nom" height="177px" />
                                                        <!-- <h3>{{res?.assureur?.nom}}</h3> -->
                                                    </div>
                                                    <h3 class="titre_assureur">{{res?.assureur?.nom}}</h3>
                                                    <div class="c-price" *ngFor="let it of res?.simulation">
                                                        <sub *ngIf="it.key=='Prime Totale TTC'">{{it?.value |number:'1.0-0':'fr'}} &nbsp;
                                                              FCFA</sub>
                                                    </div>

                                                    <div class="book-now-btn" (click)="goToNext(res)">
                                                        <span class="default-btn">Souscrire en ligne</span>
                                                    </div>

                                                    <ul class="pricing-features-list">
                                                        <li class="tehgr" (click)="rappeler()">Etre rappelé</li>
                                                        <!-- <li class="tehgr" (click)="devisParMail(res)">Devis par E-mail</li> -->
                                                        <!-- <li class="tehgr" (click)="devisParWatshap(res)">Devis par watsapp</li> -->

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="book-now-btn etudeComparatif" (click)="showEtudeComparative()" *ngIf="resultSimulationNew.length !== 0">
                                        <span class="default-btn">Etude Comparative</span>
                                    </div>
                                </section>
                                <div class="noAssureur" *ngIf="sendingRequest">
                                    <i class="fa fa-spinner fa-pulse"></i><br>
                                    <p class="text-center">Chargement devis en cours...</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                        </div>
                    </div>
                    <!-- assureurs all -->

                    <!-- assureur transport urbain -->
                    <div class="" *ngIf="assureurOtherShow">
                        <div class="container justify-content-center">
                            <div class="noAssureur" *ngIf="sendingRequestAssureur && tarificationList.length == 0">
                                <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                <p class="text-center text-ass">Chargement en cours...</p>
                            </div>
                            <div class="noAssureur" *ngIf="!sendingRequestAssureur && tarificationList.length == 0">
                                <p class="text-center text-ass c-aucune">Aucune proposition</p>
                            </div>
                            <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.</div>
                            <div class="justify-content-center">
                                <section class="pricing-area ptb-70 pb-70">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let res of tarificationList">
                                                <div class="single-pricing-box">
                                                    <div class="pricing-header bg-{{res?.assureur?.nom}}">
                                                        <img class="img-fluid" [src]="res?.assureur?.uuid" [alt]="res?.assureur?.nom" height="177px" />
                                                    </div>
                                                    <h3 class="titre_assureur">{{res?.assureur?.nom}}</h3>
                                                    <div class="c-price">
                                                        <sub>{{res?.primeTTC | number:'1.0-0':'fr'}} &nbsp;
                                                          FCFA</sub>
                                                    </div>

                                                    <div class="book-now-btn" (click)="goToNext(res)">
                                                        <span class="default-btn">Souscrire en ligne</span>
                                                    </div>

                                                    <ul class="pricing-features-list">
                                                        <li class="tehgr" (click)="rappeler()">Etre rappelé</li>
                                                        <!-- <li class="tehgr" (click)="processSimulation2(res)">Télécharger</li> -->
                                                        <!-- <li class="tehgr" (click)="devisParMail(res)">Devis par E-mail</li> -->
                                                        <!-- <li class="tehgr" (click)="devisParWatshap(res)">Devis par watsapp</li> -->

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="book-now-btn etudeComparatif" (click)="showEtudeComparative()" *ngIf="listAssureurs.length !== 0">
                                    <span class="default-btn">Etude Comparative</span>
                                </div> -->
                                </section>


                                <div class="noAssureur" *ngIf="sendingRequest">
                                    <i class="fa fa-spinner fa-pulse"></i><br>
                                    <p class="text-center">Chargement devis en cours...</p>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                            </div>
                        </div>
                    </div>
                    <!-- assureur transport urbain -->

                    <!-- proposition -->
                    <div class="recap-form-container" *ngIf="false" #autoDownload id="autoDownload">
                        <div class="mesImage">
                            <div class="imgLogo">
                                <img [src]="currentAssureur.img" alt="">
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title"> Proposition automobile</div>
                                <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références du souscripteur1</span>
                                        <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.email}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.telephone}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.adresse}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.profession}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.secteuractivte}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Intermédiare</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{currentAssureur.nom}} </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span> Références Proposition</span>
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{user.infos.adresse}} </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm.get('date_effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                            </div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{dateExpiration | date:'dd/MM/yyyy'}} -->
                                                {{simulationVehiculeForm.get('dateEcheance')?.value | date:'dd/MM/yyyy'}}
                                            </div>

                                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div> -->

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{currentCategorie}} </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        Identification du véhicule
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('immatriculation').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">{{simulationVehiculeForm.get('marque').value}} </div>
                                                    <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreMarque').value != '' ">{{simulationVehiculeForm.get('autreMarque').value}} </div> -->
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('puissance').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">Valeur à neuf
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                        {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                    </div>
                                                    <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        <span class="fa fa-check"></span>
                                                    </div> -->
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                        {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('poids').value}} </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('modele').value}}
                                                    </div>
                                                    <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreModele').value !=''">
                                                        {{simulationVehiculeForm.get('autreModele').value}}
                                                    </div> -->
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                        Valeur vénale
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                        {{simulationVehiculeForm.get('valeurVenal').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="proposition2">
                                <div class="pro-title"> Garanties souscrites et Primes d’assurance (en FCFA)</div>
                            </div>
                            <div class="row c-row">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        Nature des garanties
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                {{garantie.key}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Primes d’assurance
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                    <div class="title-div">
                                        Franchises par sinistre
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                <span class="c-col-val-neant">Néant</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="description-ass">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                                    {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.key}}</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.key}}</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                                                <div class="val" *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.value |
                                                        number:'1.0-0':'fr'}} FCFA</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}
                                                        FCFA</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="disposition">
                                <div class="title-disp">
                                    Dispositions complémentaires
                                </div>
                                <div class="desc-disp">
                                    La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                                    <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                    <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.
                                    </div>
                                    <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur. </div>
                                </div>
                            </div>
                        </div>
                        <div class="footer-devis row">
                            <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                <div class="fist-line">
                                    <b class="name-devis">{{currentAssureur.nom}}</b> est une
                                    <b>{{currentAssureur.typeSociete}}</b> avec conseil d'administration au capital de
                                    <b>{{convertStringToNumber(currentAssureur.capital) |
                                        number:"1.0-0":'fr'}}</b>. Elle a été créée en
                                    <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                                    <b>{{currentAssureur.ninea}}</b>-RC: <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à : <b>Mermoz
                                        Pyrotechnie Lot 3</b>.
                                </div>
                                <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                                    <b>{{currentAssureur.email}}</b>
                                </div>
                            </div>
                            <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                <img class="footer-img" [src]="currentAssureur.img" alt="">
                            </div>
                        </div>

                        <div *ngIf="isSouscription">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn">Retour
                                <i class="fa fa-chevron-left"></i></button>
                            <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger 
                                <i class="fa fa-download"></i></button>
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn">
                              Souscrire <i class="fa fa-angle-double-right"></i>
                            </button>
                        </div>
                        <!-- </div> -->
                    </div>
                    <!-- proposition -->

                </aw-wizard-step>

                <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré">
                    <div class="form-container" formGroupName="assure">
                        <div class="row assurance-for justify-content-right">

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" placement="top" popoverTitle="Description" [ngbPopover]="mypop" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-intersex form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" formControlName="choix" placeholder=" " class="form__select" name="radioFruit" (change)="setAssure(simulationVehiculeForm.get('assure').get('choix').value)">
                                        <option selected value="1">Pour moi-même</option>
                                        <option value="2">Pour le compte d'une personne physique</option>
                                        <!-- <option value="3">Pour le compte d'une personne morale</option> -->
                                    </select>
                                    <label for="cars" class="form__label">Veuillez choisir<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('choix').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                        </div>
                        <div class="row no-margin">
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">
                                <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="prenom" id="prenom" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="prenom" class="form__label">Prénom<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">
                                <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="nom" id="nom" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="nom" class="form__label">Nom<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <!-- personne moral -->
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value == '3'">
                                <div class="form" ngbPopover="Raison Sociale" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="raisonSociale" id="raisonSociale" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="raisonSociale" class="form__label">Raison Sociale<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('raisonSociale').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value == '3'">
                                <div class="form" ngbPopover="Régistre de commerce" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="registreCommerce" id="registreCommerce" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="registreCommerce" class="form__label">Régistre de commerce<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('registreCommerce').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value == '3'">
                                <div class="form" ngbPopover="Ninea" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="ninea" id="ninea" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="ninea" class="form__label">Ninea<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('ninea').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>


                            <!-- personne moral -->


                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-phone form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" id="telephone" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="telephone" class="form__label">Numéro téléphone<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-envelope form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="email" id="email" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                                    Ce champ est obligatoire ou invalide
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">

                                <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-user form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="profession" id="profession" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="profession" class="form__label">Profession<span class="text-red">
                                            *</span></label>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                    <i class="fa fa-home form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="adresse" id="adresse" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="adresse" class="form__label">Adresse<span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                    <i class="fa fa-briefcase form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <input type="text" formControlName="secteuractivite" id="secteur" class="form__input" autocomplete="off" placeholder=" " />
                                    <label for="secteur" class="form__label">Secteur activité<span class="text-red">
                                            *</span></label>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="simulationVehiculeForm.get('assure').get('choix').value != '3'">
                                <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                    <i class="fa-solid fa-venus form__icon"></i>
                                    <div class="form__separator">
                                    </div>
                                    <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="genre">
                                        <option value="" selected disabled>Sexe</option>
                                        <option value="M">Masculin</option>
                                        <option value="F">Féminin</option>
                                    </select>
                                    <label for="cars" class="form__label">Sexe <span class="text-red">
                                            *</span></label>
                                </div>
                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                    Ce champ est obligatoire
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="assuree()">
                                Suivant <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>
                            </button>
                        </div>
                    </div>
                </aw-wizard-step>

                <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
                    <section class="case-study-area ptb-70 pb-70 bg-fafafa">
                        <div class="row mb-4 justify-content-center">
                            <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                        </div>
                        <div class="container">
                            <div class="row c-cards">
                                <div class="col-lg-4 col-md-6 col-sm-6" (click)="domicile()">
                                    <div class="single-case-study-box1 badge-content" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                        <div class="case-study-image bg1"></div>
                                        <div class="case-study-img-hover bg1"></div>
                                        <div class="case-study-info">
                                            <span class="category">Domicile <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Domicile'"></i></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" (click)="relais()">
                                    <div class="single-case-study-box1 badge-content" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                        <div class="case-study-image bg2"></div>
                                        <div class="case-study-img-hover bg2"></div>
                                        <div class="case-study-info">
                                            <span class="category">Point relais  <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Relais'"></i></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                            <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="mySelect" placeholder=" " class="form__select" formControlName="pointRelaisControl">
                                <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                            </select>
                                <label for="mySelect" class="form__label">Point de relais<span class="text-red">
                                    *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                            <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="adresseLivraison" class="form__label">Adresse de livraison<span
                                    class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </section>
                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                        <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                            <div class="badge">
                                Domicile
                            </div>
                            <img width="135px" src="assets/images/domicile.jpg" alt="">
                        </div>
                        <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                            <img width="135px" src="assets/images/relais.jfif" alt="">
                            <div class="badge">
                                Point relais
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                        <div class="form-group">
                            <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="adresseLivraison" class="form__label">Adresse<span class="text-red">
                                        *</span></label>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                        <div class="form-group">
                            <div class="input-group relais" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                <div class="input-group-append">
                                    <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                </div>
                                <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                                    <option value="" disabled selected="selected">Sélectionner un point de relais
                                    </option>
                                    <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                            Ce champ est obligatoire
                        </div>
                    </div>
                    <div class="row mb-4 justify-content-center">
                        <h6 class="col-12 text-center colorChoisir">Choisir un moyen de paiement</h6>
                        <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                            <div class="badge">
                                {{pay?.nom}}
                            </div>
                            <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step> -->

                <!-- <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement">
                    <section class="team-area ptb-50 pb-50">
                        <div class="row mb-2 justify-content-center">
                            <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                        </div>
                        <div class="container mt-5">
                            <div class="row c-cards">
                                <div class="col-md-3 cardB" (click)="domicile()">
                                    <div class="image-container">
                                        <div class="first">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Domicile'"></i>
                                            </div>
                                        </div> <img src="assets/images/domicile.jfif" class="img-fluid rounded thumbnail-image tailImgB">
                                    </div>
                                    <div class="product-detail-container p-2">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <h5 class="dress-name">Domicile</h5>
                                            <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 cardB" (click)="relais()">
                                    <div class="image-container">
                                        <div class="first">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('livraison').value == 'Relais'"></i>
                                            </div>
                                        </div> <img src="assets/images/relais.jfif" class="img-fluid rounded thumbnail-image tailImgB">
                                    </div>
                                    <div class="product-detail-container p-2">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <h5 class="dress-name">Point relais</h5>
                                            <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                            <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="mySelect" placeholder=" " class="form__select" formControlName="pointRelaisControl">
                                <option value="" disabled selected="selected">Sélectionner un point de relais</option>
                                <option *ngFor="let item of pointRelais" [value]="item?.id">{{item?.displayMe}}</option>
                            </select>
                                <label for="mySelect" class="form__label">Point de relais<span class="text-red">
                                    *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                            <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                                <i class="fa fa-home form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                <label for="adresseLivraison" class="form__label">Adresse de livraison<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </section>

                    <div class="row mb-2 justify-content-center">
                        <h6 class="col-12 text-center colorChoisir">Choisir un moyen de paiement</h6>
                    </div>
                    <section class="team-area ptb-50 pb-50">
                        <div class="container mt-5">
                            <div class="row">
                                <div class="col-md-3 cardB" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)">
                                    <div class="image-container">
                                        <div class="first">
                                            <div class="d-flex justify-content-between align-items-center">
                                                <i class="fa fa-check-circle mr-2 text-success sixCheck" *ngIf="simulationVehiculeForm.get('moyenDePaiement').value == pay.code"></i>
                                            </div>
                                        </div> <img [src]="pay.logo" class="img-fluid rounded thumbnail-image tailImgB">
                                    </div>
                                    <div class="product-detail-container p-2">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <h5 class="dress-name">{{pay?.nom}}</h5>
                                            <div class="d-flex flex-column mb-2"> <span class="new-price"></span> <small class="old-price text-right"></small> </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div class="row justify-content-center">
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-5 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button>
                        </div>
                    </div>
                </aw-wizard-step> -->


                <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Récapitulatif">
                    <div class="recap-form-container">
                        <div class="mesImage">
                            <div class="imgLogo" *ngIf="!assureurOtherShow">
                                <img [src]="rest?.assureur?.logo" [alt]="rest?.assureur?.nom">
                            </div>
                            <div class="imgLogo" *ngIf="assureurOtherShow">
                                <img [src]="rest?.assureur?.uuid" [alt]="rest?.assureur?.nom">
                            </div>
                            <div class="imgLogo">
                                <img [src]="logoImage" alt="Logo Abidjanaise">
                            </div>
                        </div>
                        <div class="info-devis">
                            <div class="proposition">
                                <div class="pro-title">Devis automobile</div>
                                <div class="sub-title">{{numeroSous}}</div>
                            </div>
                            <div class="row ">
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Souscripteur</span>
                                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="goToStepValue(3)"></span> -->
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm?.get('assure')?.get('nom')?.value}} -->
                                                {{user.infos.firstName}} {{user.infos.lastName}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{simulationVehiculeForm?.get('assure')?.get('email')?.value}} -->
                                                {{user.infos.email}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}} -->
                                                {{user.infos.telephone}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} -->
                                                {{user.infos.adresse}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{simulationVehiculeForm?.get('assure')?.get('profession')?.value}} -->
                                                {{user.infos.profession}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{simulationVehiculeForm?.get('assure')?.get('secteuractivite')?.value}} -->
                                                {{user.infos.secteuractivite}}
                                            </div>
                                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{currentAssureur?.nom}} </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                    <div class="title-div">
                                        <span>Références Proposition</span>
                                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModalIV()"></span> -->
                                    </div>
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>
                                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                            </div> -->
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('assure')?.get('choix')?.value != 3">
                                                {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm.get('assure').get('nom').value}}
                                            </div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('assure')?.get('choix')?.value == 3">
                                                {{simulationVehiculeForm?.get('assure')?.get('raisonSociale')?.value}}
                                            </div>
                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('email')?.value}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}}
                                            </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy'}} </div>

                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                            </div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                <!-- {{dateExpiration | date:'dd/MM/yyyy'}} -->
                                                {{simulationVehiculeForm.get('dateEcheance')?.value | date:'dd/MM/yyyy'}}
                                            </div>
                                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                    <div class="title-div">
                                        <span>Caractéristiques du véhicule </span>
                                        <!-- <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos du véhicule" triggers="mouseenter:mouseleave" (click)="goToStepValue(0)"></span> -->
                                    </div>
                                    <div class="contenu-div" *ngIf="!assureurOtherShow">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('immatriculation').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('marque').value !='' ">
                                                        {{simulationVehiculeForm.get('marque').value}}
                                                    </div>
                                                    <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreMarque').value !='' ">
                                                        {{simulationVehiculeForm.get('autreMarque').value}}
                                                    </div> -->
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{currentCategorie}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('puissance').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                        Valeur à neuf
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                        {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Conducteur Habituel
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        <span class="fa fa-check"></span>
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                        {{simulationVehiculeForm.get('typeMoteur').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('nombrePlace').value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('poids').value"> Charge utile (Kg)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('poids').value">
                                                        {{simulationVehiculeForm.get('poids').value}} </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('modele').value !=''">
                                                        {{simulationVehiculeForm.get('modele').value}}
                                                    </div>
                                                    <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreModele').value !=''">
                                                        {{simulationVehiculeForm.get('autreModele').value}}
                                                    </div> -->
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance réelle (cm3)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                        Valeur vénale
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                        {{simulationVehiculeForm.get('valeurVenal').value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="contenu-div" *ngIf="assureurOtherShow">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm?.get('immatriculation')?.value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm?.get('autreMarque')?.value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm?.get('puissance')?.value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                        {{simulationVehiculeForm?.get('typeMoteur')?.value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm?.get('nombrePlace')?.value}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm?.get('poids')?.value}} </div>
                                                </div>
                                            </div>

                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div class="row">
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                                    </div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value |date:'dd/MM/yyyy'}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('autreModele')?.value}}
                                                    </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value | date:'dd/MM/yyyy'}} </div>
                                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="proposition2" *ngIf="!assureurOtherShow">
                                <div class="pro-title"> Garanties souscrites et Primes d’assurance (en FCFA)</div>
                            </div>
                            <!-- new new new new new new new new new new  -->
                            <div class="row" *ngIf="!assureurOtherShow">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div class="title-div-gar">
                                                    Nature des garanties
                                                </div>
                                                <div *ngFor="let garantie of garantieAss">
                                                    {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div class="title-div-gar1">
                                                    Primes d’assurance
                                                </div>
                                                <div class="val" *ngFor="let garantie of garantieAss">
                                                    <span>{{garantie?.value |number:'1.0-0':'fr'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="description-ass" *ngIf="!assureurOtherShow">
                                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                            </div>
                            <div class="row" *ngIf="!assureurOtherShow">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key == 'Prime RC'
                                                        ? 'Responsabilité Civile' : item?.key}} (FCFA)</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime
                                                        TTC (FCFA)</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div class="val" *ngFor="let item of primeAss">
                                                    <span *ngIf="item.key!='Prime Totale TTC'">{{item.value |
                                                        number:'1.0-0':'fr'}}</span>
                                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value | number:'1.0-0':'fr'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="assureurOtherShow">
                                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">
                                    <div class="contenu-div">
                                        <div class="row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                <div>
                                                    <span class="valPrime">Prime TTC (FCFA)</span>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                <div class="val">
                                                    <span class="valPrime">{{rest?.primeTTC | number:'1.0-0':'fr'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="!assureurOtherShow">
                                <div class="title-dispo-footerL col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                    Assureur : {{currentAssureur?.nom}}
                                </div>
                                <div class="title-dispo-footerR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                    Date emission : {{newDate |date:'dd/MM/yyyy' }} E-MAIL : infos@abidjanaise-assurances.net
                                </div>
                            </div>
                            <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="assureurOtherShow">
                                <div class="title-dispo-footerL col-md-6 col-lg-5 col-sm-12 col-xs-12">
                                    Assureur : {{rest?.assureur?.nom}}
                                </div>
                                <div class="title-dispo-footerR col-md-6 col-lg-7 col-sm-12 col-xs-12">
                                    Date emission : {{newDate |date:'dd/MM/yyyy' }} E-MAIL : infos@abidjanaise-assurances.net
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-4 c-btnWidth">
                            <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                        </div>
                        <div class="col-4 c-btnWidthV2" style>
                            <button style="width: 180px !important;" type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="telechergerDevis()">Télécharger
                            <i class="fa fa-download"></i></button>
                        </div>
                        <div class="col-4 c-btnWidth">
                            <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"> <i
                            class="fa fa-spinner fa-pulse" *ngIf="sendingRequest"></i>Terminer</button>
                        </div>
                    </div>
                </aw-wizard-step>
            </aw-wizard>
        </form>
    </div>
</div>


<section class="about-area bg-f8f8f8 paddingGarantie">
    <div class="container">
        <div class="about-content">
            <h2 class="text-center c-garantie">Nos Garanties</h2>
        </div>
        <div class="about-inner-area">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let garantie of garanties">
                    <div class="about-text-box c-y">
                        <h3>{{garantie?.nom}}</h3>
                        <p>{{garantie?.description}}.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Modal pour la page login -->
<ng-template #contentResultlogin let-modal>
    <div class="login">
        <form [formGroup]="loginform">
            <div class="container px-4 py-5 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-5">
                                    <div class="row justify-content-center px-3 mb-3">
                                        <h3 class="monCompte"> Mon compte !</h3>
                                        <p class="compteText"> Connectez vous à votre compte SVP.</p>
                                    </div>
                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-user form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" id="nomUser" class="form__input" autocomplete="off" placeholder=" " formControlName="username" />
                                        <label for="nomUser" class="form__label">Nom d'utilisateur<span
                                                class="text-red"></span></label>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('username')?.errors?.required && (loginform.get('username')?.touched || loginform.get('username')?.dirty)">
                                        Le nom d'utilisateur est requis
                                    </div>

                                    <div class="form" ngbPopover="Nom d'utilisateur" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-lock form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input [type]="pwd" id="psw" class="form__input" autocomplete="off" placeholder=" " formControlName="password" />
                                        <label for="psw" class="form__label">Mot de passe<span
                                                class="text-red"></span></label>
                                        <i class="fas fa-eye form__icon-psw" *ngIf="pwd === 'password'" (click)="pwd = 'text'"></i>
                                        <i class="fas fa-eye-slash form__icon-psw" *ngIf="pwd === 'text'" (click)="pwd = 'password'"></i>
                                    </div>
                                    <div class="text-red" *ngIf="loginform.get('password')?.errors?.required && (loginform.get('password')?.touched || loginform.get('password')?.dirty)">
                                        Le mot de passe est requis
                                    </div>
                                    <div class="forgetMdp">
                                        <div class="resterConnecter">
                                            <input type="checkbox">
                                            <span> Restez connecter</span>
                                        </div>
                                        <div class="updateMdp">Mot de passe oublié?</div>
                                    </div>
                                    <div class="text-danger text-center mt-1" *ngIf="userInconnu!=''"> {{userInconnu}}
                                    </div>
                                    <div class="text-center"> <button class="seConnecter" (click)="connecter()" [disabled]="loaderVisible">Connectez-vous</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Connexion en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-3 margeTop">
                                <p href="#" class="sm-text mx-auto mb-3">Vous n'avez pas de compte?<button class="btn btn-white ml-2" (click)="inscrire()" [disabled]="loaderVisible">Créez-en un!</button></p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </form>

    </div>

</ng-template>


<!-- Modal pour la sousctiption automobile -->

<ng-template #contentSouscription let-modal>
    <div class="modal-body login1" *ngIf="login">
        <!-- <div class="row desc container-row justify-content-center" *ngIf="login"> -->
        <div class="col-12 droite">
            <form [formGroup]="simulationVehiculeForm" class="auto">
                <div class="container px-4 py-5 mx-auto">
                    <div class="card">
                        <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                            <span aria-hidden="true">&times;</span>
                        </a>
                        <aw-wizard class="stepper-container bg-whtie pb-5" #wizard [navBarLocation]="'top'" [navBarLayout]="'large-empty'" [disableNavigationBar]="false">


                            <!-- Step 1  Vehicule-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '1' }" stepTitle="Vehicule">
                                <div class="form-container">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                            <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                                                    <option value="" disabled selected>Catégorie de voiture</option>
                                                    <option *ngFor="let v of vehiculeCategories" [value]="v.id">
                                                        {{v?.libelle}}</option>
                                                </select>
                                                <label for="cars" class="form__label">Qualité souscripteur <span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">
                                            <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-balance-scale form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="number" formControlName="poids" id="poidsVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="poidsVehicule" class="form__label">Poids du véhicule<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">
                                            <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                                                <i class="fas fa-gas-pump form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="carsTM" class="form__select" formControlName="typeMoteur">
                                                    <option value="" disabled selected="selected">Type de carburant</option>
                                                    <<option *ngFor="let typeCarbu of carburantList" [value]="typeCarbu?.value">{{typeCarbu?.name}}</option>
                                                </select>
                                                <label for="cars" class="form__label">Type de carburant<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">
                                            <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-balance-scale form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="number" formControlName="nombrePlace" id="placeNb" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                                                <input type="number" formControlName="nombrePlace" id="placeNb" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                                                <label for="place" class="form__label">Nombre de places<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">
                                            <div class="form" ngbPopover="Puissance fiscale" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-bolt form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="number" min="1" formControlName="puissance" id="puissanceVehicule" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="puissanceV" class="form__label">Puissance<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                                            <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                                                <i class="fas fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="immatriculation" id="immatriculationV" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="immatriculationV" class="form__label">Immatriculation du
                                                    véhicule<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select placeholder=" " class="form__select" id="mySelectMark" formControlName="marque" (change)="marque()">
                                                    <option value="" disabled selected="selected"> Marque du vehicule
                                                    </option>
                                                    <option *ngFor="let item of listMarques" value="{{item?.make}}">
                                                        {{item?.make}}</option>
                                                </select>
                                                <label for="mySelect" class="form__label">Marque du vehicule<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule)&& simulationVehiculeForm.get('marque').valid ">
                                            <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-car form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select placeholder=" " class="form__select" id="cars" formControlName="modele">
                                                    <option value="" disabled selected="selected"> Modèle du vehicule
                                                    </option>
                                                    <option *ngFor="let item of listModeles" [value]="item?.model">
                                                        {{item?.model}}</option>
                                                </select>
                                                <label for="cars" class="form__label">Modèle du vehicule<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-calendar form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" id="datedebut" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker #debut="ngbDatepicker" (click)="debut.toggle()"
                                                />
                                                <label for="dateCirculation" class="form__label">Date de mise en
                                                    circulation<span class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-calendar form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" id="dateffet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                                                    (click)="dateEffet.toggle()" />
                                                <label for="effet" class="form__label">Date d'effet du contrat<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                                            <div>
                                                <div class="form" placement="top" ngbPopover="Durée du contrat" triggers="mouseenter:mouseleave">
                                                    <i class="fa fa-hourglass-2 form__icon"></i>
                                                    <div class="form__separator">
                                                    </div>
                                                    <select placeholder=" " class="form__select" id="cars" (change)="checkDuree()" name="contract-duration" formControlName="duree">
                                                        <option value="" disabled selected>Durée du contrat</option>
                                                        <option *ngFor="let d of listDureesFiltrer" [value]="d.duree">
                                                            {{d.displayMe}}</option>
                                                    </select>
                                                    <label for="cars" class="form__label">Durée du contrat<span
                                                            class="text-red"> *</span></label>
                                                </div>
                                                <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('duree').invalid">
                                                    Ce champ est obligatoire
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" (click)="retour()"><i class="fa fa-chevron-left"></i> Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="valider()">Suivant <i class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </aw-wizard-step>

                            <!-- Step 2  Garanties-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '2' }" stepTitle="Garanties">
                                <div class="form-container c-form-container">
                                    <div class="row">
                                        <div class="col-12 text-center p-2 mrhgarantie">
                                            <i class="fa fa-check-circle mr-2 text-success"></i>
                                            <b>Responsabilité civile des conducteurs (Garantie par défaut)</b>
                                        </div>
                                    </div>
                                    <div *ngIf="garanties?.length > 0 " class="row">
                                        <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                                            <div class="value" *ngIf="g.sousGarantie.length==0">
                                                <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                                <label for="{{g.code}}"> {{g?.nom}} </label>
                                                <span class="garantDescription" *ngIf="g.code === 'gtcom'" placement="right" [ngbPopover]="g.description"> ?</span>
                                                <span class="garantDescription" *ngIf="g.code === 'bris' && (complete || collision || plafone)" placement="right"> (Garantie gratuite)</span>
                                                <!-- </span> -->
                                            </div>
                                            <div class="value" *ngIf="g.sousGarantie.length>0">
                                                <label for="" class="label-SG"> {{g?.nom}}</label>
                                                <div *ngFor="let sg of g.sousGarantie" class="sgGarantie">
                                                    <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                                    <label for="{{g.code}}"> {{sg?.nom}}</label>
                                                    <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> <i
                                                            class="fa fa-hand-o-right form__icon_option"></i> <label
                                                            for="{{g.description}}"> {{sg?.description}}</label></span>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                                        Chargemet en cours ...
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete || collision || plafone">
                                            <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-credit-card-alt form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (blur)="setValeurVenale()" (keyup)="onBlurEventneuf($event)" />
                                                <label for="neuf" class="form__label">Valeur à neuf<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                        </div>
                                        <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                            Ce champ est obligatoire
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                            <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                                <label for="venal" class="form__label">Valeur vénale<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                            <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                                La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="popValue">
                                    <ng-template #popValeur>
                                        <div class="card" *ngIf="incendie || collision || vol || plafone || complete">
                                            <div class="card-header">
                                                Veuillez renseigner le(s) champs suivant(s)
                                            </div>
                                            <div class="card-body">
                                                <div *ngIf="complete">
                                                    <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-credit-card-alt form__icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" mask="separator" id="neuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                                        <label for="neuf" class="form__label">Valeur à neuf<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                </div>
                                                <div *ngIf="incendie || collision || vol || plafone">
                                                    <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                                        <i class="fa fa-money form__icon c-icon"></i>
                                                        <div class="form__separator">
                                                        </div>
                                                        <input type="text" mask="separator" id="venal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                                        <label for="venal" class="form__label">Valeur vénale<span
                                                                class="text-red"> *</span></label>
                                                    </div>
                                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                                        Ce champ est obligatoire
                                                    </div>
                                                    <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                                        La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i>
                                            Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" (click)="processSimulation()"><i class="fa fa-spinner fa-pulse"
                                                *ngIf="sendingRequest"></i> Suivant <i
                                                class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </aw-wizard-step>


                            <!-- Step 3  Assureur-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '3' }" stepTitle="Assureur">
                                <div class="">
                                    <div class="container justify-content-center">
                                        <div class="noAssureur" *ngIf="sendingRequestAssureur && listAssureurs.length == 0">
                                            <i class="fa fa-spinner fa-pulse c-pulse-spin"></i><br>
                                            <p class="text-center text-ass">Chargement en cours...</p>
                                        </div>
                                        <div *ngIf="false">Veuillez selectionner les assureurs pour un comparateur de prix.
                                        </div>
                                        <ng-select *ngIf="false" class="select-ass" multiple="true" [(ngModel)]="selectedAss" clearAllText="Effacer tout" notFoundText="Aucun élément trouvé" (change)="selectAssureur()" [dropdownPosition]="'bottom'" [ngModelOptions]="{standalone: true}">
                                            <ng-option *ngFor="let ass of listAssureurs" [value]="ass.id"> <img [src]="ass.logo" class="img-assureur" [alt]="ass.nom"> <span class="text-assureur">{{ass.nom}}</span> </ng-option>
                                        </ng-select>



                                        <div class="justify-content-center">

                                            <!--  liste des assureurs -->
                                            <!-- <div class="row"> -->


                                            <section class="pricing-area ptb-70 pb-70">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let res of listAssureurs">
                                                            <div class="single-pricing-box">
                                                                <div class="pricing-header bg1">
                                                                    <!-- <img class="img-fluid"  [src]="res.img" alt="{{res?.nom}}" height="177px" /> -->
                                                                    <h3>{{res?.displayMe}}</h3>
                                                                </div>

                                                                <div class="c-price">
                                                                    <sub>{{res?.prix |number:'1.0-0':'fr'}} &nbsp;
                                                                          FCFA</sub>
                                                                </div>

                                                                <div class="book-now-btn" (click)="goToNext(res)">
                                                                    <span class="default-btn">Souscrire en ligne</span>
                                                                </div>
                                                                <ul class="pricing-features-list">
                                                                    <li class="tehgr" (click)="openModalEnvoyeDevis(res)">Etre rappelé</li>
                                                                    <li class="tehgr" (click)="processSimulation2(res)">Télécharger</li>
                                                                    <li class="tehgr" (click)="devisParMail(res)">Devis par E-mail</li>
                                                                    <li class="tehgr" (click)="devisParWatshap(res)">Devis par watsapp</li>

                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                            <div class="noAssureur" *ngIf="sendingRequest">
                                                <i class="fa fa-spinner fa-pulse"></i><br>
                                                <p class="text-center">Chargement devis en cours...</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                            <button type="button" class="btn ml-0 btn-warning btn-prev c-btn-prev pull-left c-roundbtn" awPreviousStep (click)="retour()"><i
                                                    class="fa fa-chevron-left"></i> Retour</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="recap-form-container" *ngIf="false" #autoDownload id="autoDownload">
                                    <div class="mesImage">
                                        <div class="imgLogo">
                                            <img [src]="currentAssureur.img" alt="">
                                        </div>
                                    </div>
                                    <div class="info-devis">
                                        <div class="proposition">
                                            <div class="pro-title"> Proposition automobile</div>
                                            <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span>Références du souscripteur1</span>
                                                    <!-- (click)="modal.close('close')" -->
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{numeroSous}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.email}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Téléphone</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.telephone}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.adresse}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Profession</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.profession}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.secteuractivte}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Intermédiare</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{currentAssureur.nom}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span> Références Proposition</span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{refPro}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Assuré(e)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.firstName}} {{user.infos.lastName}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{user.infos.adresse}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm.get('date_effet').value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            <!-- {{dateExpiration | date:'dd/MM/yyyy'}} -->
                                                            {{simulationVehiculeForm.get('dateEcheance')?.value | date:'dd/MM/yyyy'}}
                                                        </div>

                                                        <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div> -->

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Catégorie</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{currentCategorie}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                                <div class="title-div">
                                                    Identification du véhicule
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    N° immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('immatriculation').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Marque</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('marque').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Puissance (CV)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('puissance').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                                    Valeur à neuf
                                                                </div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                                    {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Conducteur Habituel</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    <span class="fa fa-check"></span>
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Energie</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                                    {{simulationVehiculeForm.get('typeMoteur').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Nombre de places</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('nombrePlace').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Charge utile (Kg)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('poids').value}} </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    1ère mise en circulation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Modéle</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('modele').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Puissance réelle (cm3)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                                    Valeur vénale
                                                                </div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                                    {{simulationVehiculeForm.get('valeurVenal').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Date immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Zone</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    Côte d'Ivoire </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="proposition2">
                                            <div class="pro-title"> Garanties souscrites et Primes d’assurance (en FCFA)
                                            </div>
                                        </div>
                                        <div class="row c-row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    Nature des garanties
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                            {{garantie.key}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Primes d’assurance
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                            <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Franchises par sinistre
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                            <span class="c-col-val-neant">Néant</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="description-ass">
                                            Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                                                {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                            <div *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item.key}}</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.key}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-val">
                                                            <div class="val" *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item.value
                                                                    | number:'1.0-0':'fr'}} FCFA</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value |
                                                                    number:'1.0-0':'fr'}}
                                                                    FCFA</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="disposition">
                                            <div class="title-disp">
                                                Dispositions complémentaires
                                            </div>
                                            <div class="desc-disp">
                                                La prise d'effet du contrat est subordonnée au paiement préalable de la prime conformémet au disposition de l'article 13
                                                <div class="">-&nbsp; Le présent contrat est résu par le code des assurances CIMA, les conditions générales jointes, les conditions particulières ainsi que les closes en annexe.</div>
                                                <div class="">-&nbsp; Il est précisé que les conditions particulières (CP) annulent et remplace toute disposition des conditions générales (CG) qui leurs sont contraires.</div>
                                                <div class="">-&nbsp; Sont nulles toutes adjonctions ou modifications matérièlles non revétues des visas de l'assureur et du souscripteur.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer-devis row">
                                        <div class="col-md-10 col-lg-10 col-sm-12 col-xs-12">
                                            <div class="fist-line">
                                                <b class="name-devis">{{currentAssureur.nom}}</b> est une
                                                <b>{{currentAssureur.typeSociete}}</b> avec conseil d'administration au capital de <b>{{convertStringToNumber(currentAssureur.capital) |
                                                    number:"1.0-0":'fr'}}</b>. Elle a été créée en
                                                <b>{{currentAssureur.anneeCreation}}</b>. NINEA
                                                <b>{{currentAssureur.ninea}}</b>-RC:
                                                <b>{{currentAssureur.registreCommerce}}</b> Elle est régie par le code des assurances CIMA. Son siège est situé à : <b>Mermoz Pyrotechnie Lot
                                                    3</b>.
                                            </div>
                                            <div class="second-line">Tel:&nbsp; <b>{{currentAssureur.telephone}}</b> &nbsp;&nbsp;Email: &nbsp;
                                                <b>{{currentAssureur.email}}</b>
                                            </div>
                                        </div>
                                        <div class="col-md-2 col-lg-2 col-sm-12 col-xs-12">
                                            <img class="footer-img" [src]="currentAssureur.img" alt="">
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn" (click)="modal.close('close')">Retour
                                                <i class="fa fa-chevron-left"></i></button>
                                            <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger 
                                                <i class="fa fa-download"></i></button>
                                            <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" (click)="modal.close('subscribe')">
                                              Souscrire <i class="fa fa-angle-double-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </aw-wizard-step>

                            <!-- Step 4  Assuré-->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '4' }" stepTitle="Assuré">
                                <div class="form-container" formGroupName="assure">
                                    <div class="row assurance-for justify-content-right">
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" placement="top" ngbPopover="Assuré" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-intersex form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="cars" formControlName="choix" placeholder=" " class="form__select" name="radioFruit" (change)="setAssure(simulationVehiculeForm.get('assure').get('choix').value)">
                                                    <option selected value="1">Pour moi-même</option>
                                                    <option value="2">Pour un tiers</option>
                                                    <option value="3">Pour le compte de</option>
                                                </select>
                                                <label for="cars" class="form__label">Qualité souscripteur <span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>

                                    </div>
                                    <div class="row no-margin">
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-user form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="prenom" id="prenom" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="prenom" class="form__label">Prénom<span class="text-red">
                                                        *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-user form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="nom" id="nom" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="nom" class="form__label">Nom<span class="text-red">
                                                        *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" ngbPopover="Numéro de téléphone" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-phone form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" prefix="+225 " [mask]="phoneMask" formControlName="numeroTelephone" id="telephone" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="telephone" class="form__label">Numéro téléphone<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-envelope form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="email" id="email" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="email" class="form__label">Email<span class="text-red">
                                                        *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                                                Ce champ est obligatoire ou invalide
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-user form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="profession" id="profession" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="profession" class="form__label">Profession<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                                <i class="fa fa-home form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="adresse" id="adresse" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="adresse" class="form__label">Adresse<span class="text-red">
                                                        *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" ngbPopover="Veuillez renseigner votre secteur d'activité" triggers="mouseenter:mouseleave" popoverTitle="Secteur d'activité">
                                                <i class="fa fa-briefcase form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <input type="text" formControlName="secteuractivite" id="secteur" class="form__input" autocomplete="off" placeholder=" " />
                                                <label for="secteur" class="form__label">Secteur activité<span
                                                        class="text-red"> *</span></label>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                            <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                                                <i class="fa-solid fa-venus form__icon"></i>
                                                <div class="form__separator">
                                                </div>
                                                <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="genre">
                                                    <option value="" selected disabled>Sexe</option>
                                                    <option value="M">Masculin</option>
                                                    <option value="F">Féminin</option>
                                                </select>
                                                <label for="cars" class="form__label">Qualité souscripteur <span
                                                        class="text-red"> *</span></label>
                                            </div>
                                            <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                                                Ce champ est obligatoire
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i>
                                            Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [disabled]="sendingRequest" (click)="assuree()">Suivant</button>
                                    </div>
                                </div>

                            </aw-wizard-step>

                            <!-- Step 5  Paiement-->
                            <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement" *ngIf="false">
                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                                    <div class="mt-4 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                        <div class="badge">
                                            Domicile
                                        </div>
                                        <img width="135px" src="assets/images/domicile.jpg" alt="">
                                    </div>
                                    <div class="mt-4 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                        <img width="135px" src="assets/images/relais.jfif" alt="">
                                        <div class="badge">
                                            Point relais
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                                    <div class="form-group">
                                        <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                                            <i class="fa fa-home form__icon"></i>
                                            <div class="form__separator">
                                            </div>
                                            <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                            <label for="adresseLivraison" class="form__label">Adresse<span
                                                    class="text-red"> *</span></label>
                                        </div>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                                    <div class="form-group">
                                        <div class="input-group relais" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                            <div class="input-group-append">
                                                <i class="fa fa-home text-white"> <span class="text-red">*</span></i>
                                            </div>
                                            <select class="form-control optionRelais" id="mySelect" formControlName="pointRelaisControl">
                                                <option value="" disabled selected="selected">Sélectionner un point de
                                                    relais</option>
                                                <option *ngFor="let item of pointRelais" [value]="item?.id">
                                                    {{item?.displayMe}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center colorChoisir">Choisir un moyen de paiement</h6>
                                    <div class="mt-4 badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                                        <div class="badge">
                                            {{pay?.nom}}
                                        </div>
                                        <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'}" alt="">
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i>
                                            Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>

                            </aw-wizard-step>

                            <!-- Step 5  Paiement -->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '5' }" stepTitle="Paiement">
                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center colorChoisir">Choisir le type de livraison</h6>
                                    <div class="mt-4 p-0 badge-content" (click)="domicile()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Domicile'">
                                        <img class="c-moyenPaiementimg" src="assets/images/domicile.jfif" alt="">
                                        <div class="badge">
                                            Domicile
                                        </div>
                                        <!-- <img width="135px" src="assets/images/domicile.jpg" alt=""> -->
                                    </div>
                                    <div class="mt-4 p-0 badge-content" (click)="relais()" [class.selected]="simulationVehiculeForm.get('livraison').value == 'Relais'">
                                        <!-- <i class="fa fa-check-circle mr-2 text-success"></i> -->
                                        <img class="c-moyenPaiementimg" src="assets/images/relais.jfif" alt="">
                                        <div class="badge">
                                            Point relais
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="pointRelais?.length > 0 ||  pointRelais">
                                    <div class="form mt-5" placement="top" ngbPopover="Point de relais" triggers="mouseenter:mouseleave">
                                        <i class="fas fa-map-marked-alt form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <select id="mySelect" placeholder=" " class="form__select optionRelais" formControlName="pointRelaisControl">
                                            <option value="" selected disabled>Sélectionner un point de relais</option>
                                            <option *ngFor="let item of pointRelais" [value]="item?.id">
                                                {{item?.displayMe}}</option>
                                        </select>
                                        <label for="mySelect" class="form__label">Point de relais<span class="text-red">
                                                *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('pointRelaisControl').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12 mySelectRelais" *ngIf="!(pointRelais?.length > 0 ||  pointRelais)">
                                    <div class="form" ngbPopover="AdresseLivraison" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-home form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" formControlName="adresseLivraison" id="adresseLivraison" class="form__input" autocomplete="off" placeholder=" " />
                                        <label for="adresseLivraison" class="form__label">Adresse de livraison<span
                                                class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('adresseLivraison').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>

                                <div class="row mb-4 justify-content-center">
                                    <h6 class="col-12 text-center c-titlePaiement colorChoisir">Choisir un moyen de paiement</h6>
                                    <div class="mt-5 p-0  badge-content" *ngFor="let pay of paymentMethods" (click)="selectPayment(pay)" [class.selected]="simulationVehiculeForm.get('moyenDePaiement').value == pay.code">
                                        <img [src]="pay.logo" [ngClass]="{'styleImg':pay.code=='FM'} + ' c-moyenPaiementimg'" alt="">
                                        <div class="badge">
                                            {{pay?.nom}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i>
                                            Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" awNextStep>Suivant <i class="fa fa-chevron-right"></i></button>
                                    </div>
                                </div>
                            </aw-wizard-step>
                            <!-- Step 6  Récapitulatif -->

                            <aw-wizard-step [navigationSymbol]="{ symbol: '6' }" stepTitle="Récapitulatif">
                                <div class="recap-form-container">
                                    <div class="mesImage">
                                        <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                            <img src="../../../../../assets/images/PROXIM-ASSUR-logo_blanc.png" alt="">
                                        </div>
                                        <div class="imgLogoR2 col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                            <img [src]="logoImage" alt="">
                                        </div>
                                    </div>
                                    <div class="info-devis">
                                        <div class="proposition">
                                            <div class="pro-title">Devis automobile</div>
                                            <!-- <div class="sub-title">(Cette proposition est valable jusqu'au {{dateExpiration | date:'dd/MM/yyyy'}}) -->
                                            <div class="sub-title">{{numeroSous}}</div>
                                            <!-- </div> -->
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span>Souscripteur</span>
                                                    <!-- (click)="modal.close('close')" -->
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos des avantages" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{numeroSous}} </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm?.get('assure')?.get('nom')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('email')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Téléphone</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('numeroTelephone')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Profession</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('profession')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('secteuractivite')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{currentAssureur?.nom}} </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span>Références Proposition</span>
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos de l'assuré" triggers="mouseenter:mouseleave" (click)="openModalIV()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{refPro}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Emission
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{newDate |date:'dd/MM/yyyy HH:mm:ss'}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                            Assuré(e)</div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{simulationVehiculeForm.get('assure').get('nom').value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}}
                                                        </div>
                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                                                        <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                            <!-- {{dateExpiration | date:'dd/MM/yyyy'}} -->
                                                            {{simulationVehiculeForm.get('dateEcheance')?.value | date:'dd/MM/yyyy'}}
                                                        </div>
                                                        <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée
                                                        </div>
                                                        <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm.get('duree').value }} mois </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row ">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                                                <div class="title-div">
                                                    <span>Caractéristiques du véhicule</span>
                                                    <!-- (click)="modal.close('close')" -->
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les infos du véhicule" triggers="mouseenter:mouseleave" (click)="openModal()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    N° immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('immatriculation').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Marque</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('marque').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Catégorie</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{currentCategorie}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Puissance (CV)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('puissance').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                                    Valeur à neuf
                                                                </div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurNeuf').value">
                                                                    {{simulationVehiculeForm.get('valeurNeuf').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Conducteur Habituel</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    <span class="fa fa-check"></span>
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Energie</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                                                    {{simulationVehiculeForm.get('typeMoteur').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Nombre de places</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('nombrePlace').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Charge utile (Kg)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('poids').value}} </div>
                                                            </div>
                                                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                Catégorie</div>
                                                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                {{currentCategorie}} </div>
                                                        </div>

                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                                            <div class="row">
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    1ère mise en circulation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value |date:'dd/MM/yyyy'}} </div>

                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Modéle</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('modele').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Puissance réelle (cm3)</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                                    Valeur vénale
                                                                </div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurVenal').value">
                                                                    {{simulationVehiculeForm.get('valeurVenal').value}}
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Date immatriculation</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    {{simulationVehiculeForm.get('date_mis_en_circulation').value | date:'dd/MM/yyyy'}} </div>
                                                                <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">
                                                                    Zone</div>
                                                                <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                                                    Côte d'Ivoire </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="proposition2">
                                            <div class="pro-title"> Garanties souscrites et Primes d’assurance (en FCFA)
                                            </div>
                                        </div>
                                        <div class="row c-row">
                                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                                                <div class="title-div">
                                                    <span> Nature des garanties</span>
                                                    <span class="fa fa-edit edit-pro" placement="top" ngbPopover="Cliquer pour modifier les garanties" triggers="mouseenter:mouseleave" (click)="openModalGaran()"></span>
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngFor="let garantie of garantieAss">
                                                            <!-- {{garantie.key}} -->
                                                            {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Primes d’assurance
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-val" *ngFor="let garantie of garantieAss">
                                                            <span> {{garantie.value |number:'1.0-0':'fr'}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 c-col-3">
                                                <div class="title-div">
                                                    Franchises par sinistre
                                                </div>
                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 " *ngFor="let garantie  of garantieAss">
                                                            <span class="c-col-val-neant">Néant</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="description-ass">
                                            Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b> {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                                                <div class="contenu-div">
                                                    <div class="row">
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                                            <div *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item?.key
                                                                    == 'Prime RC' ? 'Responsabilité Civile' :
                                                                    item?.key}} (FCFA)</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC (FCFA)</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                            <div class="val" *ngFor="let item of primeAss">
                                                                <span *ngIf="item.key!='Prime Totale TTC'">{{item.value
                                                                    | number:'1.0-0':'fr'}}</span>
                                                                <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item.value |
                                                                    number:'1.0-0':'fr'}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12">
                                            <div class="title-dispo-footerL col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                Assureur : {{currentAssureur?.nom}}
                                            </div>
                                            <div class="title-dispo-footerR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                                Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }} E-MAIL : infos@abidjanaise-assurances.net
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-5">
                                        <button type="button" class="btn btn-warning btn-prev c-btn-prev pull-right c-roundbtn" awPreviousStep (click)="retour()"><i class="fa fa-chevron-left"></i>
                                            Retour</button>
                                    </div>
                                    <div class="col-5">
                                        <button type="button" class="btn btn-success btn-next c-btn-next pull-left c-roundbtn" [swal]="confirmSubscription2" [disabled]="sendingRequest"> <i
                                                class="fa fa-spinner fa-pulse"
                                                *ngIf="sendingRequest"></i>Terminer</button>
                                    </div>
                                </div>
                            </aw-wizard-step>

                        </aw-wizard>
                    </div>
                </div>
            </form>
            <!-- </div> -->
        </div>
        <!-- </div> -->

    </div>
</ng-template>



<!-- Modal pour pdf de souscription -->
<ng-template #contentResultass let-modal>
    <div class="modal-body  bg-white  autodown" #autoDownload1 id="autoDownload">
        <!-- <div class="mesImage row col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="imgLogo col-md-6 col-lg-6 col-sm-12 col-xs-12">
                <img src="../../../../assets/img/logo.png" alt="">
            </div>
            <div class="imgLogoR col-md-6 col-lg-6 col-sm-12 col-xs-12">
            </div>
        </div> -->
        <div class="mesImage">
            <div class="imgLogo" *ngIf="!assureurOtherShow">
                <img [src]="rest?.assureur?.logo" [alt]="rest?.assureur?.nom">
            </div>
            <div class="imgLogo" *ngIf="assureurOtherShow">
                <img [src]="rest?.assureur?.uuid" [alt]="rest?.assureur?.nom">
            </div>
            <div class="imgLogo">
                <img [src]="logoImage" alt="Logo Abidjanaise">
            </div>
        </div>
        <div class="info-devis container">
            <div class="proposition">
                <div class="pro-title">Devis automobile</div>
                <div class="sub-title">{{numeroSous}}</div>
            </div>
            <div class="row ">
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span>Souscripteur</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{numeroSous}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nom</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.firstName}} {{user?.infos?.lastName}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.email}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.telephone}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.adresse}}
                                <!-- {{simulationVehiculeForm?.get('assure')?.get('adresse')?.value}} -->
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Profession</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.profession}}
                                <!-- {{simulationVehiculeForm?.get('assure')?.get('profession')?.value}} -->
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Activité</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.secteuractivite}}
                                <!-- {{simulationVehiculeForm?.get('assure')?.get('secteuractivite')?.value}} -->
                            </div>
                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assureur</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentAssureur?.nom}} </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-6">
                    <div class="title-div">
                        <span> Références Proposition</span>
                    </div>
                    <div class="contenu-div">
                        <div class="row">

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Numéro</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{refPro}} </div>


                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Assuré(e)</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('assure')?.get('choix')?.value != 3">
                                {{ simulationVehiculeForm?.get('assure')?.get('prenom')?.value}} {{ simulationVehiculeForm?.get('assure')?.get('nom')?.value}}
                                <!-- {{user?.infos?.firstName}} {{user?.infos?.lastName}} -->
                            </div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('assure')?.get('choix')?.value == 3">
                                {{ simulationVehiculeForm?.get('assure')?.get('raisonSociale')?.value}}
                                <!-- {{user?.infos?.firstName}} {{user?.infos?.lastName}} -->
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Adresse</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                <!-- {{user?.infos?.adresse}} -->
                                {{ simulationVehiculeForm?.get('assure')?.get('adresse')?.value}}
                            </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Email</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.email}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Téléphone</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{user?.infos?.telephone}} </div>
                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Effet</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                {{simulationVehiculeForm?.get('date_effet')?.value | date:'dd/MM/yyyy HH:mm:ss'}} </div>

                            <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date d'expiration</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                <!-- {{dateExpiration | date:'dd/MM/yyyy'}} -->
                                {{simulationVehiculeForm.get('dateEcheance')?.value | date:'dd/MM/yyyy'}}
                            </div>

                            <!-- <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Durée</div>
                            <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{ simulationVehiculeForm?.get('duree')?.value }} mois </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- not transport urbain -->
            <div class="row" *ngIf="!assureurOtherShow">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        Caractéristiques du véhicule
                    </div>
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('immatriculation')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('marque')?.value != ''">
                                        {{simulationVehiculeForm?.get('marque')?.value}}
                                    </div>
                                    <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('autreMarque')?.value != ''">
                                        {{simulationVehiculeForm?.get('autreMarque')?.value}}
                                    </div> -->
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('puissance')?.value}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm?.get('valeurNeuf')?.value">Valeur à neuf</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm?.get('valeurNeuf')?.value">
                                        {{simulationVehiculeForm?.get('valeurNeuf')?.value | number:'1.0-0':'fr'}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                        {{simulationVehiculeForm?.get('typeMoteur')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('nombrePlace')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('poids')?.value}} </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                    </div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value |date:'dd/MM/yyyy'}} </div>

                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('modele')?.value!=''">
                                        {{simulationVehiculeForm.get('modele')?.value}}
                                    </div>
                                    <!-- <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('autreModele')?.value!=''">
                                        {{simulationVehiculeForm.get('autreModele')?.value}}
                                    </div> -->
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name" *ngIf="simulationVehiculeForm.get('valeurVenal')?.value">Valeur vénale</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value" *ngIf="simulationVehiculeForm.get('valeurVenal')?.value">
                                        {{simulationVehiculeForm.get('valeurVenal')?.value | number:'1.0-0':'fr'}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value | date:'dd/MM/yyyy'}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> Côte d'Ivoire </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- not transport urbain -->

            <!-- transport urbain -->
            <div class="row" *ngIf="assureurOtherShow">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-12">
                    <div class="title-div">
                        Caractéristiques du véhicule
                    </div>
                    <!-- <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('immatriculation')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                   
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('puissance')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                        {{simulationVehiculeForm?.get('typeMoteur')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('nombrePlace')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('poids')?.value}} </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                    </div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value |date:'dd/MM/yyyy'}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value | date:'dd/MM/yyyy'}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire</div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">N° immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('immatriculation')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Marque</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('autreMarque')?.value}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Catégorie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value"> {{currentCategorie}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Puissance (CV)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('puissance')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Energie</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value c-value">
                                        {{simulationVehiculeForm?.get('typeMoteur')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Nombre de places</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('nombrePlace')?.value}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name"> Charge utile (Kg)</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm?.get('poids')?.value}} </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="row">
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">1ère mise en circulation
                                    </div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value |date:'dd/MM/yyyy'}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Modéle</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('autreModele')?.value}}
                                    </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Date immatriculation</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">
                                        {{simulationVehiculeForm.get('date_mis_en_circulation')?.value | date:'dd/MM/yyyy'}} </div>
                                    <div class="col-md-5 col-lg-5 col-sm-12 col-xs-12 name">Zone</div>
                                    <div class="col-md-7 col-lg-7 col-sm-12 col-xs-12 value">Côte d'Ivoire</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- transport urbain -->
            <div class="row" *ngIf="!assureurOtherShow">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">
                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                <div class="title-div-gar">
                                    Nature des garanties
                                </div>
                                <div *ngFor="let garantie of garantieAss">
                                    {{garantie?.key == 'Prime RC' ? 'Responsabilité Civile' : garantie?.key }}
                                    <!-- <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC FCFA</span> -->
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="title-div-gar1">
                                    Primes d’assurance
                                </div>
                                <div class="val" *ngFor="let garantie of garantieAss">
                                    <span>{{garantie?.value |number:'1.0-0':'fr'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div class="description-ass" *ngIf="!assureurOtherShow">
                Par conséquant, le souscripteur s'engage à payer au comptant à la signature du présent contrat la somme de <b>
                    {{tot | number:'1.0-0':'fr'}} FCFA</b> décomptée comme suit:
            </div>
            <div class="row" *ngIf="!assureurOtherShow">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                <div *ngFor="let item of primeAss">
                                    <span *ngIf="item.key!='Prime Totale TTC' && item.key!='Prime RC'">{{item?.key}}</span>
                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">Prime TTC FCFA</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="val" *ngFor="let item of primeAss">
                                    <span *ngIf="item.key!='Prime Totale TTC' && item.key!='Prime RC'">{{item?.value |
                                        number:'1.0-0':'fr'}}</span>
                                    <span *ngIf="item.key=='Prime Totale TTC'" class="valPrime">{{item?.value |
                                        number:'1.0-0':'fr'}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="assureurOtherShow">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 c-col-122">

                    <div class="contenu-div">
                        <div class="row">
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 c-col-prime">
                                <div>
                                    <span class="valPrime">Prime TTC FCFA</span>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                                <div class="val">
                                    <span class="valPrime">{{rest.primeTTC | number:'1.0-0':'fr'}}</span>
                                    <!-- <span class="valPrime">1027888</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- not transport urbain -->
            <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="!assureurOtherShow">
                <div class="title-dispo-footerL col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    Assureur : {{currentAssureur?.nom}}
                </div>
                <div class="title-dispo-footerR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }}
                    <span class="emailmarg"> E-MAIL : infos@abidjanaise-assurances.net</span>
                </div>
            </div>
            <!-- not transport urbain -->

            <div class="disposition row col-md-12 col-lg-12 col-sm-12 col-xs-12" *ngIf="assureurOtherShow">
                <div class="title-dispo-footerL col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    Assureur : {{rest?.assureur?.nom}}
                </div>
                <div class="title-dispo-footerR col-md-6 col-lg-6 col-sm-12 col-xs-12">
                    Date emission : {{newDate |date:'dd/MM/yyyy HH:mm:ss' }}
                    <span class="emailmarg"> E-MAIL : infos@abidjanaise-assurances.net</span>
                </div>
            </div>

        </div>
    </div>

    <!-- </div> -->
    <!-- <div> -->
    <div>
        <!-- <div> -->
        <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn" (click)="modal.close('close')"><i class="fa fa-chevron-left"></i> Retour</button>
        <!-- </div> -->
        <!-- <div class="col-4 text-center"> -->
        <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureScreen()">Télécharger
                    <i class="fa fa-download"></i></button>
        <!-- </div>
            <div class="col-4"> -->
        <!-- <button type="button" class="btn btn-success btn-next c-btn-next pull-right c-roundbtn" (click)="modal.close('subscribe')">
                    Souscrire <i class="fa fa-angle-double-right"></i>
                </button> -->
        <!-- </div> -->
    </div>
    <!-- </div> -->
    <!-- <div *ngIf="!isSouscription" class="btnSous">
        <button type="button" class="btn btn-primary btn-tel pull-left" (click)="openPDF()">
            <i class="fa fa-download"></i>
        </button>
    </div> -->
</ng-template>



<!-- Modal pour étre rappelé -->
<ng-template #devisAEnvoye let-modal>
    <form [formGroup]="etrerappeleForm">
        <!-- <div class="container px-2 py-1 mx-auto"> -->
        <div class="card card0 c-card">
            <!-- <div class="row beneficiary-section form-container "> -->
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">{{assureurAEnvoye?.displayMe}}</h6>
                        </div>
                        <div class="col-lg-2 text-right1">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Date de rappel" triggers="mouseenter:mouseleave">
                                <i class="fa fa-calendar form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="date" placeholder="" class="form__select" name="date" formControlName="date" name="date" #vehicule>
                            <option value="" disabled selected>Jour de rappel</option>
                            <option *ngFor="let rapel of dateRappel" [value]="rapel.value">{{rapel?.name}}</option>
                          </select>
                                <label for="cars" class="form__label">Jour de rappel<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="etrerappeleForm.get('date').invalid && updateRequest">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Heure de rappel" triggers="mouseenter:mouseleave">
                                <i class="fa-regular fa-clock form__icon c-icon"></i>
                                <div class="form__separator">
                                </div>
                                <ngx-bootstrap-multiselect formControlName="heure" name="heure" class="form__select" [texts]="myTexts" [settings]="mySettings" #heure [options]="heureRappel"></ngx-bootstrap-multiselect>
                                <label for="cars" class="form__label">Heure de rappel<span class="text-red"> *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf=" etrerappeleForm.get('heure').invalid && updateRequest">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="etreRappele()" [disabled]="updateRequest">
                  <i class="fa fa-pencil" *ngIf="!updateRequest"></i> 
                  <i class="fa fa-spinner fa-pulse" *ngIf="updateRequest"></i>
                  Valider
                </button>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <!-- </div> -->
    </form>
</ng-template>


<ng-template #updateAssures let-modal>
    <form [formGroup]="simulationVehiculeForm">
        <!-- <div class="container px-4 py-2 mx-auto"> -->
        <div class="card c-card">
            <div class="row beneficiary-section form-container " formGroupName="assure">
                <!-- <div class="row no-margin"> -->
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Ajouter assuré</h6>
                        </div>
                        <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                  class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Type" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select id="cars" placeholder=" " class="form__select" (change)="setAssure($event)">
            <option selected disabled>Type</option>
            <option value="1">Pour moi-même</option>
            <option value="2">Pour un tiers</option>
          </select>
                        <label for="cars" class="form__label">Type<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Sexe" triggers="mouseenter:mouseleave">
                        <i class="fa fa-intersex form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select id="cars" placeholder=" " class="form__select" formControlName="genre">
            <option value="" selected disabled>Sexe</option>
            <option value="M">Masculin</option>
            <option value="F">Féminin</option>
          </select>
                        <label for="cars" class="form__label">Sexe<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('genre').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="prenomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                        <label for="prenomAssure" class="form__label">Prénom<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('prenom').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                        <i class="fa fa-user form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="nomAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                        <label for="nomAssure" class="form__label">Nom<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('nom').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Numéro téléphone" triggers="mouseenter:mouseleave">
                        <i class="fa fa-phone form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="telephoneAssure" class="form__input" autocomplete="off" placeholder=" " prefix="+221 " [mask]="phoneMask" formControlName="numeroTelephone" />
                        <label for="telephoneAssure" class="form__label">Numéro téléphone<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('numeroTelephone').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                        <i class="fa fa-envelope form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="email" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                        <label for="email" class="form__label">Email<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('email').invalid">
                        Ce champ est obligatoire ou invalide
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Veuillez renseigner date de naissance" triggers="mouseenter:mouseleave" popoverTitle="Date naissance">
                        <i class="fa fa-calendar form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="dateNaissance" class="form__input" autocomplete="off" placeholder=" " [maxDate]="today" [minDate]="{year: 1900, month: 1, day: 1}" (dateSelect)="onDateSelect3($event, 'assure','dateNaissance')" ngbDatepicker #dateAssu="ngbDatepicker"
                            (click)="dateAssu.toggle()" />
                        <label for="dateNaissance" class="form__label">Date de naissance<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('dateNaissance').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Profession" triggers="mouseenter:mouseleave">
                        <i class="fa fa-vcard form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="professionAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="profession" />
                        <label for="professionAssure" class="form__label">Profession<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('profession').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Activité" triggers="mouseenter:mouseleave">
                        <i class="fa fa-briefcase form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="activiteAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="secteuractivite" />
                        <label for="activiteAssure" class="form__label">Secteur activité<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('secteuractivite').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12">
                    <div class="form" ngbPopover="Adresse" triggers="mouseenter:mouseleave">
                        <i class="fa fa-home form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="adresseAssure" class="form__input" autocomplete="off" placeholder=" " formControlName="adresse" />
                        <label for="adresseAssure" class="form__label">Adresse<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('assure').get('adresse').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <button class="btn  btn-large c-btnAjout" (click)="updateDevise('assure')"><i class="fa fa-pencil"></i> Valider</button>
            </div>
        </div>
    </form>
</ng-template>

<ng-template #updateInfoVehicule let-modal>

    <form [formGroup]="simulationVehiculeForm">
        <!-- <div class="container px-4 py-2 mx-auto"> -->
        <div class="card c-card">
            <div class="row beneficiary-section form-container ">
                <!-- <div class="row no-margin"> -->
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Information du véhicule</h6>
                        </div>
                        <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                  class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                    <div class="form" placement="top" ngbPopover="Catégorie du véhicule à assurer" triggers="mouseenter:mouseleave">
                        <i class="fa fa-car form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select id="cars" placeholder=" " class="form__select" name="qualite_souscripteur" formControlName="typeVehicule" (change)="selectVehiculeCategories(vehicule.value)" name="product" #vehicule>
                <option value="" disabled selected>Catégorie de voiture</option>
                <option *ngFor="let v of vehiculeCategories" [value]="v.id">{{v?.libelle}}</option>
              </select>
                        <label for="cars" class="form__label">Qualité souscripteur <span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeVehicule').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule && camion">
                    <div class="form" ngbPopover="Poids du vehicule" triggers="mouseenter:mouseleave">
                        <i class="fa fa-balance-scale form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="number" formControlName="poids" id="poids" class="form__input" autocomplete="off" placeholder=" " />
                        <label for="poids" class="form__label">Poids du véhicule<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('poids').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="categorieVehicule">
                    <div class="form" placement="top" ngbPopover="Type de Carburant" triggers="mouseenter:mouseleave">
                        <i class="fas fa-gas-pump form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select id="carsCarb" class="form__select" formControlName="typeMoteur">
                <option value="" disabled selected="selected">Type de carburant</option>
                            <option *ngFor="let typeCarbu of carburantList" [value]="typeCarbu?.value">{{typeCarbu?.name}}</option>
              </select>
                        <label for="cars" class="form__label">Type de carburant<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('typeMoteur').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule) && !moto">
                    <div class="form" ngbPopover="Nombre de places" triggers="mouseenter:mouseleave">
                        <i class="fa fa-balance-scale form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="number" formControlName="nombrePlace" id="nombrePlace" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && !vp" value="" />
                        <input type="number" formControlName="nombrePlace" id="nombrePlace" class="form__input" autocomplete="off" placeholder=" " *ngIf="categorieVehicule && vp" value="5" />
                        <label for="nombrePlace" class="form__label">Nombre de places<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('nombrePlace').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="categorieVehicule ">
                    <div class="form" ngbPopover="Puissance fiscale" triggers="mouseenter:mouseleave">
                        <i class="fa fa-bolt form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="number" min="1" formControlName="puissance" id="puissance" class="form__input" autocomplete="off" placeholder=" " />
                        <label for="puissance" class="form__label">Puissance<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('puissance').invalid">
                        Ce champ est obligatoire
                    </div>
                    <div class="dateImage">
                        <span class="dateImageText">Voir la puissance de la voiture:</span>
                        <span class="puissanceImageNew"> Nouvelle</span>
                        <span class="barre">|</span>
                        <span class="puissanceImageOld"> Ancienne</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule ">
                    <div class="form" ngbPopover="Immatriculation" triggers="mouseenter:mouseleave">
                        <i class="fas fa-car form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" formControlName="immatriculation" id="immatriculation" class="form__input" autocomplete="off" placeholder=" " />
                        <label for="immatriculation" class="form__label">Immatriculation du véhicule<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('immatriculation').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                    <div class="form" placement="top" ngbPopover="Marque du vehicule" triggers="mouseenter:mouseleave">
                        <i class="fa fa-car form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select class="form__select" id="mySelect" formControlName="marque" (change)="marque()">
                <option value="" disabled selected="selected"> Marque du vehicule</option>
                <option *ngFor="let item of listMarques" value="{{item?.make}}">{{item?.make}}</option>
              </select>
                        <label for="mySelect" class="form__label">Marque du vehicule<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('marque').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="(subVehiculeCategories?.length > 0 ||  categorieVehicule)&& simulationVehiculeForm.get('marque').valid ">
                    <div class="form" placement="top" ngbPopover="Modèle de {{ simulationVehiculeForm.get('marque').value}}!" triggers="mouseenter:mouseleave">
                        <i class="fa fa-car form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <select class="form__select" id="carsModels" formControlName="modele">
                <option value="" disabled selected="selected"> Modèle du vehicule</option>
                <option *ngFor="let item of listModeles" [value]="item?.model">{{item?.model}}</option>
              </select>
                        <label for="cars" class="form__label">Modèle du vehicule<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('modele').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                    <div class="form" placement="top" ngbPopover="Date de mise en circulation" triggers="mouseenter:mouseleave">
                        <i class="fa fa-calendar form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" [value]="dateMisEnCirculation" id="circulation" class="form__input" autocomplete="off" placeholder=" " [minDate]="{day: 1, month: 1, year: 1}" [maxDate]="today" (dateSelect)="onDateSelect($event,'date_mis_en_circulation')" ngbDatepicker
                            #debut="ngbDatepicker" (click)="debut.toggle()" />
                        <label for="circulation" class="form__label">Date de mise en circulation<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_mis_en_circulation').invalid">
                        Ce champ est obligatoire
                    </div>
                    <div class="dateImage">
                        <span class="dateImageText">Voir la date 1ère mise en circulation:</span>
                        <span class="dateImageNew"> Nouvelle</span>
                        <span class="barre">|</span>
                        <span class="dateImageOld"> Ancienne</span>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="subVehiculeCategories?.length > 0 ||  categorieVehicule">
                    <div class="form" placement="top" ngbPopover="Date d'effet" triggers="mouseenter:mouseleave">
                        <i class="fa fa-calendar form__icon"></i>
                        <div class="form__separator">
                        </div>
                        <input type="text" id="dateEffet" class="form__input" autocomplete="off" placeholder=" " [maxDate]="{year: 2900, month: 1, day: 1}" [minDate]="today" (dateSelect)="onDateSelect2($event,'date_effet')" ngbDatepicker #dateEffet="ngbDatepicker" [value]="this.simulationVehiculeForm.get('date_effet').value | date: 'dd/MM/yyyy'"
                            (click)="dateEffet.toggle()" />
                        <label for="dateEffet" class="form__label">Date d'effet du contrat<span class="text-red"> *</span></label>
                    </div>
                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('date_effet').invalid">
                        Ce champ est obligatoire
                    </div>
                </div>

                <div class="row justify-content-center">
                    <!-- <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12"> -->
                    <button class="btn  btn-large c-btnAjout" (click)="updateDevise('assure')">
                                        <i class="fa fa-pencil"></i> Valider
                                </button>
                </div>
                <!-- </div> -->
            </div>
        </div>
        <!-- </div> -->
    </form>
</ng-template>

<!-- garanted -->
<ng-template #updateGarantie let-modal>
    <form [formGroup]="simulationVehiculeForm">
        <!-- <div class="container px-2 py-1 mx-auto"> -->
        <div class="card c-card">
            <div class="row beneficiary-section form-container ">
                <!-- <div class="row no-margin"> -->
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Information véhicule</h6>
                        </div>
                        <div class="col-lg-2 text-right">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                  class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <!--  -->
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div *ngIf="garanties?.length > 0 " class="row">
                        <div class="c-col-lg-6 col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngFor="let g of garanties">
                            <div class="value" *ngIf="g.sousGarantie.length==0">
                                <input class="inputCheck" [disabled]="g.disabled" [checked]="g.selected" (change)="addGarantie(g,g.sousGarantie)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}">
                                <label for="{{g.code}}"> {{g.nom}} </label>
                                <span class="garantDescription" *ngIf="g.code === 'gtcom'" placement="right" [ngbPopover]="g.description"> ?</span>
                            </div>
                            <div class="value" *ngIf="g.sousGarantie.length>0">
                                <label for="" class="label-SG"> {{g.nom}}</label>
                                <div *ngFor="let sg of g.sousGarantie" class="sgGarantie">
                                    <!-- <input class="inputCheck" [checked]="!g.selected" *ngIf="!g.selected" (click)="addGarantie(g, sg)" type="checkbox" [value]="g" name="{{g.code}}" id="{{g.code}}"> -->
                                    <input class="inputCheck" [checked]="sg.selected" (change)="addGarantie(sg,sg,g.sousGarantie)" type="radio" [value]="sg" name="{{sg.code}}" id="{{sg.code}}">
                                    <label for="{{g.code}}"> {{sg.nom}}</label>
                                    <span class="garantDescription" *ngIf="g.code === 'gpt'" placement="right" [ngbPopover]="sg.description"> ?</span>
                                    <!-- <div class="descriptionOption"></div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="complete">
                                    <div class="form" placement="top" ngbPopover="La valeur à neuf" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-money form__icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" mask="separator" id="valeurNeuf" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurNeuf" (keyup)="onBlurEventneuf($event)" />
                                        <label for="valeurNeuf" class="form__label">Valeur à neuf<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurNeuf').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6 col-xs-12 col-sm-12" *ngIf="incendie || collision || vol || plafone">
                                    <div class="form" placement="top" ngbPopover="La valeur venal" triggers="mouseenter:mouseleave">
                                        <i class="fa fa-credit-card-alt form__icon c-icon"></i>
                                        <div class="form__separator">
                                        </div>
                                        <input type="text" mask="separator" id="valeurVenal" class="form__input" autocomplete="off" placeholder=" " formControlName="valeurVenal" (keyup)="onBlurEvent($event)" />
                                        <label for="valeurVenal" class="form__label">Valeur vénale<span class="text-red"> *</span></label>
                                    </div>
                                    <div class="error min-max text-red" *ngIf="invalid && simulationVehiculeForm.get('valeurVenal').invalid">
                                        Ce champ est obligatoire
                                    </div>
                                    <div class="error min-max text-red" *ngIf="simulationVehiculeForm.get('valeurVenal').value && (valeurNeuf< valeurVenal)">
                                        La valeur vénale doit être inférieur ou égale à la valeur à neuf...!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="noDataGaranties" *ngIf="garanties?.length == 0 ">
                        Chargemet en cours ...
                    </div>
                </div>
                <!--  -->
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="updateDevise('assure')" [disabled]="updateRequest">
              <i class="fa fa-pencil" *ngIf="!updateRequest"></i> 
              <i class="fa fa-spinner fa-pulse" *ngIf="updateRequest"></i>
              Valider
            </button>
                </div>
                <!-- </div> -->
            </div>
        </div>
        <!-- </div> -->
    </form>
</ng-template>



<!-- details sous garanties -->

<!-- Modal pour étre last-->
<ng-template #etreRappeller let-modal>
    <form [formGroup]="rappellerForm">
        <!-- <div class="container px-2 py-1 mx-auto"> -->
        <div class="card card2 c-card">
            <!-- <div class="row beneficiary-section form-container "> -->
            <div class="row no-margin">
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 c-headModal">
                    <div class="row no-margin">
                        <div class="col-10 p-0">
                            <h6 class="text-left text-white c-cardTitle">Me faire rappeler</h6>
                        </div>
                        <div class="col-lg-2 text-right1">
                            <a aria-label="Close" (click)="modal.dismiss('close')" class="text-right"><i
                      class="fa fa-times text-white times-ass c-times-ass"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 mt-3">
                    <div class="row">

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Nom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="nom" />
                                <label for="emai" class="form__label">Nom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('nom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Prénom" triggers="mouseenter:mouseleave">
                                <i class="fa fa-user form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="prenom" class="form__input" autocomplete="off" placeholder=" " formControlName="prenom" />
                                <label for="prenom" class="form__label">Prénom<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('prenom').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Email" triggers="mouseenter:mouseleave">
                                <i class="fa fa-envelope form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="emai" class="form__input" autocomplete="off" placeholder=" " formControlName="email" />
                                <label for="emai" class="form__label">Email<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('email').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" ngbPopover="Téléphone" triggers="mouseenter:mouseleave">
                                <i class="fa fa-phone form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <input type="text" id="telephone" class="form__input" autocomplete="off" placeholder="Téléphone" formControlName="telephone" />
                                <label for="telephone" class="form__label">Téléphone<span
                                        class="text-red"> *</span></label>
                            </div>
                            <div class="error  text-red" *ngIf="invalid && rappellerForm.get('telephone').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12">
                            <div class="form" placement="top" ngbPopover="Disponibilité" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="disponibilite" formControlName="disponibilite" name="product" #disponibilite (change)="checkDisponiblite()">
                                    <option value="" disabled selected>Disponibilité</option>
                                    <option *ngFor="let dispo of disponibiteList" [value]="dispo.value">{{dispo?.name}}
                                    </option>
                                </select>
                                <label for="cars" class="form__label">Disponibilité<span class="text-red">
                                        *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && rappellerForm.get('disponibilite').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-xs-12 col-sm-12" *ngIf="rappellerForm.get('disponibilite').value =='Soir' || rappellerForm.get('disponibilite').value =='Matin'">
                            <div class="form" placement="top" ngbPopover="Quelle heure" triggers="mouseenter:mouseleave">
                                <i class="fa fa-car form__icon"></i>
                                <div class="form__separator">
                                </div>
                                <select id="cars" placeholder=" " class="form__select" name="heureDisponibilite" formControlName="heureDisponibilite" #heureDisponibilite>
                                    <option value="" disabled selected>Quelle heure</option>
                                    <option *ngFor="let heure of heureDisponibiliteListTemoin" [value]="heure.value">{{heure?.name}}
                                    </option>
                                </select>
                                <label for="cars" class="form__label">Quelle heure<span class="text-red">
                                        *</span></label>
                            </div>
                            <div class="error min-max text-red" *ngIf="invalid && rappellerForm.get('heureDisponibilite').invalid">
                                Ce champ est obligatoire
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 text-center">
                    <button class="btn btn-large c-btnAjout" (click)="meRappeller()" [disabled]="sendingRequestRappel">
                  <i class="fa fa-pencil" *ngIf="!sendingRequestRappel"></i> 
                  <i class="fa fa-spinner fa-pulse" *ngIf="sendingRequestRappel"></i>
                  Valider
                </button>
                </div>
            </div>
        </div>
    </form>
</ng-template>

<!--end Modal pour étre rappelé last -->

<!--start inscription -->
<ng-template #contentResultsign let-modal>
    <div class="modal-body login">
        <form [formGroup]="inscriptionForm">
            <div class="container px-4 py-2 mx-auto">
                <div class="card card0">
                    <a class="close text-primary text-right loginclose" aria-label="Close" (click)="modal.dismiss('close')">
                        <span aria-hidden="true">&times;</span>
                    </a>
                    <div class="d-flex flex-lg-row flex-column-reverse">
                        <div class="card card1">
                            <div class="row justify-content-center my-auto">
                                <div class="col-md-10 col-10 mt-2">
                                    <!-- <div class="row justify-content-center px-3 mb-3"> <img id="logo" src="../../../../../assets/img/logo.png"> </div> -->
                                    <h6 class="msg-info text-center">Veuillez remplir ces champs svp!</h6>
                                    <div class="form-group"> <label class="form-control-label text-muted">Nom</label>
                                        <input type="text" id="nom" placeholder="" class="form-control" formControlName="nom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('nom')?.errors?.required && (inscriptionForm?.get('nom')?.touched || inscriptionForm.get('nom')?.dirty)">
                                            Le champs nom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Prénom
                                        </label> <input type="text" id="prenom" placeholder="" class="form-control" formControlName="prenom">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('prenom')?.errors?.required && (inscriptionForm.get('prenom')?.touched || inscriptionForm.get('prenom')?.dirty)">
                                            Le champs prénom est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Numéro
                                            téléphone</label> <input type="text" id="telephone" placeholder="" class="form-control" formControlName="telephone" prefix="+225 " [mask]="phoneMask">
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('telephone')?.errors?.required && (inscriptionForm.get('telephone')?.touched || inscriptionForm.get('telephone')?.dirty)">
                                            Le champs téléphone est requis</div>
                                    </div>
                                    <div class="form-group"> <label class="form-control-label text-muted">Adresse
                                        mail</label> <input type="email" id="email" name="email" placeholder="" class="form-control" formControlName="email">
                                        <!-- <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.required && (inscriptionForm.get('email')?.touched || inscriptionForm.get('email')?.dirty)">
                                        Le champs email est requis</div> -->
                                        <div class="text-red ml-3" *ngIf="inscriptionForm.get('email')?.errors?.pattern">Le champs email est incorrect
                                        </div>
                                    </div>
                                    <div class="row justify-content-center my-3 px-3"> <button class="btn-block btn-color" (click)="inscrireutil()" [disabled]="loaderVisible">S'inscrire</button> </div>
                                    <div class="row justify-content-center my-3 px-3" *ngIf="loaderVisible">
                                        <p class="text-center w-100"><i class=" fa fa-spinner fa-pulse text-primary"></i></p>
                                        <p class="text-center ">
                                            Inscription en cours
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="bottom text-center mb-1">
                                <p href="#" class="sm-text mx-auto mb-3">Vous avez déjà un compte?<button class="btn btn-white ml-2" (click)="souscrire()" [disabled]="loaderVisible">Connectez-vous!</button></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-template>

<!--end inscription  -->


<!--start etude comparative -->
<ng-template #etudeComparative let-modal>
    <div class="modal-body  bg-white  autodown" #etudeComparatif id="etudeComparative">
        <div class="row">
            <div class="imgLogo col-md-6 col-lg-5 col-sm-12 col-xs-12">
                <img [src]="logoImage" alt="" class="with_img_logo">
            </div>
            <div class="col-md-6 col-lg-7 col-sm-12 col-xs-12">
                <div class="etude_title">ETUDE COMPARATIVE DE PRIME AUTOMOBILE</div>
                <div class="c_container">
                    <span class="c-header">{{user.infos.firstName}} {{user.infos.lastName}}</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4 col-sm-12 col-xs-12">
                <div class="c_detail">
                    <span>Détails Cotation Automobile</span>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12 col-xs-12 offset-lg-4">
                <div class="row">
                    <div class="col-lg-6">
                        <span class="nameE">Emission :</span>
                    </div>
                    <div class="col-lg-6">
                        {{simulationVehiculeForm.get('date_effet').value | date:'dd/MM/yyyy'}}
                    </div>
                    <div class="col-lg-6">
                        <span class="nameE">Echeance :</span>
                    </div>
                    <div class="col-lg-6">
                        {{simulationVehiculeForm.get('dateEcheance').value | date:'dd/MM/yyyy'}}
                    </div>
                </div>
            </div>
        </div>

        <div class="row table_1">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th class="th_size text-center" style="width:10%">Immatriculation</th>
                            <th class="th_size text-center" style="width:8%">Genre</th>
                            <th class="th_size text-center" style="width:8%">Marque</th>
                            <th class="th_size text-center" style="width:8%">Puissance</th>
                            <th class="th_size text-center" style="width:11%">Valeur Neuve</th>
                            <th class="th_size text-center" style="width:11%">Valeur Venale</th>
                            <th class="th_size colorG text-center" *ngFor="let item of listAssureursComp">{{item?.nom | uppercase}}</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="td_size text-center">{{simulationVehiculeForm?.get('immatriculation')?.value}}</td>
                            <td class="td_size text-center">{{simulationVehiculeForm.get('modele')?.value}}</td>
                            <td class="td_size text-center">{{simulationVehiculeForm?.get('marque')?.value}}</td>
                            <td class="td_size text-center">{{simulationVehiculeForm?.get('puissance')?.value}} CV</td>
                            <td class="td_size text-center">{{simulationVehiculeForm?.get('valeurNeuf')?.value | number:'1.0-0':'fr'}}</td>
                            <td class="td_size text-center">{{simulationVehiculeForm.get('valeurVenal')?.value | number:'1.0-0':'fr'}}</td>
                            <td class="td_size text-center" *ngFor="let item of primeNetComp">{{item?.value | number:'1.0-0':'fr'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row table_1">
            <div class="col-md-12 col-lg-4 col-sm-12 col-xs-12"></div>
            <div class="col-md-12 col-lg-8 col-sm-12 col-xs-12" style="padding-left: 15px;">
                <table class="table table-bordered">
                    <tr *ngIf="tcListComp.length != 0">
                        <td class="td_size text-center">Tierce Collision :</td>
                        <td class="td_size text-center" *ngFor="let tc of tcListComp">{{tc?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr *ngIf="bgListComp.length != 0">
                        <td class="td_size text-center">Bris de glace :</td>
                        <td class="td_size text-center" *ngFor="let br of bgListComp">{{br?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr *ngIf="avanceRecourComp.length != 0">
                        <td class="td_size text-center">Avance et recours :</td>
                        <td class="td_size text-center" *ngFor="let br of avanceRecourComp">{{br?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr *ngIf="defRecourComp.length != 0">
                        <td class="td_size text-center">Défense et recours :</td>
                        <td class="td_size text-center" *ngFor="let br of defRecourComp">{{br?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr *ngIf="inciListComp.length != 0">
                        <td class="td_size text-center">Incendie :</td>
                        <td class="td_size text-center" *ngFor="let inc of inciListComp">{{inc?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr *ngIf="volListComp.length != 0">
                        <td class="td_size text-center">Vol :</td>
                        <td class="td_size text-center" *ngFor="let vol of volListComp">{{vol?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr *ngIf="reducListComp.length != 0">
                        <td class="td_size text-center">Réduction :</td>
                        <td class="td_size text-center" *ngFor="let pr of reducListComp">{{pr?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr>
                        <td class="td_size text-center">Prime Nette :</td>
                        <td class="td_size text-center" *ngFor="let pr of primeNetComp">{{pr?.value | number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr>
                        <td class="td_size text-center">Coût de police :</td>
                        <td class="td_size text-center" *ngFor="let cout of coutPoliceComp">{{cout?.value |number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr>
                        <td class="td_size text-center">Taxe :</td>
                        <td class="td_size text-center" *ngFor="let ta of taxeComp">{{ta?.value |number:'1.0-0':'fr'}}</td>

                    </tr>
                    <tr>
                        <td class="td_size text-center">FGA :</td>
                        <td class="td_size text-center" *ngFor="let fga of fgaValComp">{{fga?.value |number:'1.0-0':'fr'}}</td>
                    </tr>
                    <tr>
                        <td class="td_size text-center fontBold">Prime TTC :</td>
                        <td class="td_size text-center fontBold" *ngFor="let ttc of primeTTCComp">{{ttc?.value |number:'1.0-0':'fr'}}</td>
                    </tr>
                </table>
            </div>
        </div>

        <br>
        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <span class="issu">A l'issue de l'appel d'offre que nous avons initié, suite à votre sollicitation les compagnies <span class="footerV">{{assurValue | uppercase}}</span> les compagnies présentes les memes garanties et assistance automobile
                </span>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                <p class="issu"> <span class="conclu">Conclusion :</span> Nous recommandons,<span class="footerV">{{bestAssureur | uppercase}} </span>au regard de notre synthèse des primes, des tarif et de la qualité des prestations.</p>
            </div>
        </div>

    </div>
    <div>
        <button type="button" class="btn btn-warning btn-prev c-btn-prev  pull-left c-roundbtn" (click)="modal.close('close')"><i class="fa fa-chevron-left"></i> Retour</button>
        <button type="button" class="btn btn-primary btn-tel c-roundbtn" (click)="captureEtude()">Télécharger
                <i class="fa fa-download"></i>
        </button>
    </div>
</ng-template>



<!-- end etude comparative -->


<swal #confirmSubscription2 title="Confirmation" text="Voulez-vous vraiment procéder à la souscription ?" icon="question" [reverseButtons]="true" [showCancelButton]="true" [focusCancel]="false" [confirmButtonText]="'OUI, BIEN SÛR'" [cancelButtonText]="'NON, ANNULER'"
    (confirm)="saveData()">
</swal>
<ng-template #mypop>
    1.Pour moi-même : mon nom est sur la carte à grise
    <br>2.Pour un tiers :pour une tierce personne
    <br>3.Pour le compte de :c'est ma voiture mais mon nom n'est pas sur la carte à grise
</ng-template>